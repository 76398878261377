import React, {Component} from "react";
import './Page.css';

//Components
import {getAsset} from "../utils/prepareAssets";
import PdfMenuBar from "./PdfMenuBar/PdfMenuBar";
import DrawArea from "./DrawArea/DrawArea";
import {readAsArrayBuffer} from "../utils/asyncReader";
import FileDialog from "../../../FileDialog/FileDialog";

class Page extends Component {

  state = {
    currentPage:1,
    pageCount:0,
    pageRendering:false,
    pageNumPending:null,
    document:null,
    ctx:null,
    viewport:null,
    attachments:[],

    // Drawing
    drawingActive:false,
    isDrawing:false,
    paths:[],
    minX:null,
    maxX:null,
    minY:null,
    maxY:null,
    path:"",
    color:"#FFFFFF",
    size:12,
    rotation:0,

    //Dialogs
    showAttachPdfDialog:false
  }

  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.page = React.createRef();
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const pdfjsLib = await getAsset('pdfjsLib');
    let pdfDoc;
    try {
      pdfDoc = await pdfjsLib.getDocument(this.props.pdfFileURL).promise.then((document) => {
        let attachments = [];
        for(let i = 0; i < document.numPages; i++) {
          attachments.push({drawings:[]});
        }
        this.setState({attachments:attachments,pageCount:document.numPages,document:document,ctx:this.canvas.current.getContext("2d")}, () => {
          this.renderPage(this.state.currentPage);
        });
      });
    } catch (e) {
      console.log('PDF konnte nicht geladen werden');
      throw e;
    }
  }

  renderPage = (pageNumber) => {
    this.setState({pageRendering:true});
    this.state.document.getPage(pageNumber).then((page) => {
      let viewport = page.getViewport({
        scale:1,
        rotation:this.state.rotation,
        offsetX:0,
        offsetY:0,
        dontFlip:0
      });
      this.setState({viewport:viewport});
      let renderContext = {
        canvasContext: this.state.ctx,
        viewport: viewport
      }
      let renderTask = page.render(renderContext);
      renderTask.promise.then(() => {
        this.setState({pageRendering:false});
        if(this.state.pageNumPending) {
          this.renderPage(this.state.pageNumPending);
          this.setState({pageNumPending:null});
        }
      })
    })
  }

  queueRenderPage = (num) => {
    if(this.state.pageRendering) {
      this.setState({pageNumPending:num});
    }else{
      this.renderPage(num);
    }
  }

  onPrevPage = () => {
    if(this.state.currentPage <= 1) {
      return;
    }else{
      let currentPage = this.state.currentPage;
      currentPage--;
      this.setState({currentPage:currentPage});
      this.queueRenderPage(currentPage);
    }
  }

  onNextPage = () => {
    if(this.state.currentPage >= this.state.pageCount) {
      return;
    }else{
      let currentPage = this.state.currentPage;
      currentPage++;
      this.setState({currentPage:currentPage});
      this.queueRenderPage(currentPage);
    }
  }

  hexToRgba = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  setRotation = (rotationValue) => {
    let rotation = this.state.rotation;
    rotation = rotation + rotationValue;
    this.setState({rotation:rotation});
    this.initialize();
  }

  downloadPdf = async () => {
    let pdfFile = this.props.pdfFile;
    const PDFLib = await getAsset('PDFLib');
    const download = await getAsset('download');
    let pdfDoc;

    try {
      pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdfFile));
    } catch (e) {
      console.log('PDF konnte nicht geladen werden');
      throw e;
    }

    const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {
      let drawings = this.state.attachments[pageIndex].drawings;
      // 'y' starts from bottom in PDFLib, use this to calculate y
      const pageHeight = page.getHeight();
      const embedProcesses = drawings.map(async (drawing) => {
        const {pushGraphicsState,setLineCap,popGraphicsState,setLineJoin,LineCapStyle,LineJoinStyle,rgb} = PDFLib;
        return () => {
          page.pushOperators(pushGraphicsState(),setLineCap(LineCapStyle.Round),setLineJoin(LineJoinStyle.Round));
          let color = this.hexToRgba(drawing.stroke);
          page.drawSvgPath(drawing.path, {
            borderColor: rgb(parseFloat((color.r / 255).toFixed(1)),parseFloat((color.g / 255).toFixed(1)),parseFloat((color.b / 255).toFixed(1))),
            borderWidth: parseInt(drawing.strokeWidth),
            y:pageHeight
          })
          page.pushOperators(popGraphicsState());
        };
      });
      // embed objects in order
      const drawProcesses = await Promise.all(embedProcesses);
      drawProcesses.forEach((p) => p());
    });
    await Promise.all(pagesProcesses);
    try {
      const pdfBytes = await pdfDoc.save();
      download(pdfBytes, this.props.pdfFile.name, 'application/pdf');
    } catch (e) {
      console.log('PDF konnte nicht gespeichert werden');
      throw e;
    }
  }

  setIsDrawing = (state) => {
    this.setState({isDrawing:state});
  }

  setMinX = (value) => {
    this.setState({minX:value});
  }

  setMaxX = (value) => {
    this.setState({maxX:value});
  }

  setMinY = (value) => {
    this.setState({minY:value});
  }

  setMaxY = (value) => {
    this.setState({maxY:value});
  }

  setPath = (path) => {
    this.setState({path:path});
  }

  setAttachments = (attachments) => {
    this.setState({attachments: attachments});
  }

  toggleDrawingActive = () => {
    this.setState({drawingActive:!this.state.drawingActive});
  }

  colorOnChangeHandler = (color) => {
    this.setState({color:color});
  }

  sizeOnChangeHandler = (e) => {
    this.setState({size:e.target.value});
  }

  // Dialogs

  showAttachPdfDialog = () => {
    this.setState({showAttachPdfDialog:true});
  }

  closeAttachPdfDialog = () => {
    this.setState({showAttachPdfDialog:false});
  }

  render() {
    return (
        <div ref={this.page} className='page'>

          <FileDialog
              open={this.state.showAttachPdfDialog}
              close={this.closeAttachPdfDialog}
              showMessage={this.props.showMessage}
              uploadFile={this.props.attachPdf}
              type={1}
          />

          <div className='page-content'>
            <canvas ref={this.canvas}
                    width={this.state.viewport && this.state.viewport.viewBox ? this.state.viewport.viewBox[2] : 0}
                    height={this.state.viewport && this.state.viewport.viewBox ? this.state.viewport.viewBox[3] : 0}/>
            <DrawArea
                page={this.page}
                isDrawing={this.state.isDrawing}
                paths={this.state.paths}
                minX={this.state.minX}
                maxX={this.state.maxX}
                minY={this.state.minY}
                maxY={this.state.maxX}
                path={this.state.path}
                setIsDrawing={this.setIsDrawing}
                setMinX={this.setMinX}
                setMaxX={this.setMaxX}
                setMinY={this.setMinY}
                setMaxY={this.setMaxY}
                setPath={this.setPath}
                setAttachments={this.setAttachments}
                currentPage={this.state.currentPage}
                attachments={this.state.attachments}
                addPath={this.addPath}
                drawingActive={this.state.drawingActive}
                color={this.state.color}
                size={this.state.size}
                viewport={this.state.viewport}
            />
            <PdfMenuBar
                onPrevPage={this.onPrevPage}
                onNextPage={this.onNextPage}
                currentPage={this.state.currentPage}
                pageCount={this.state.pageCount}
                downloadPdf={this.downloadPdf}
                updatePdf={this.props.updatePdf}
                showAttachPdfDialog={this.showAttachPdfDialog}
                drawingActive={this.state.drawingActive}
                toggleDrawingActive={this.toggleDrawingActive}
                colorOnChangeHandler={this.colorOnChangeHandler}
                sizeOnChangeHandler={this.sizeOnChangeHandler}
                size={this.state.size}
                color={this.state.color}
                setRotation={this.setRotation}
            />
          </div>
        </div>
    )
  }
}

export default Page;