import React, {Component} from 'react';
import './UserDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import MenuItem from "@mui/material/MenuItem";

class UserDialog extends Component {

    state = {
        user:this.props.user
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props !== prevProps) {
            this.setState({user:this.props.user});
        }
    }

    addUserOnClickHandler = () => {
        if(this.state.user.username !== '' && this.state.user.password !== '' && this.state.user.passwordCheck && this.state.user.assignedLocation !== null) {
            if(this.state.user.password === this.state.user.passwordCheck) {
                this.props.addNewUser(this.state.user)
                this.props.close();
            }else{
                this.props.showMessage(2,"Die Passwörter stimmen nicht über ein");
            }
        }else{
            this.props.showMessage(2,'Bitte alle Felder ausfüllen');
        }
    }

    //Change Handler
    usernameOnChangeHandler = (e) => {
        let user = this.state.user;
        user.username = e.target.value;
        this.setState({user:user});
    }

    passwordOnChangeHandler = (e) => {
        let user = this.state.user;
        user.password = e.target.value;
        this.setState({user:user});
    }

    passwordCheckOnChangeHandler = (e) => {
        let user = this.state.user;
        user.passwordCheck = e.target.value;
        this.setState({user:user});
    }

    assignedLocationOnChangeHandler = (e) => {
        let user = this.state.user;
        user.assignedLocation = {
            id:e.target.value
        }
        this.setState({user:user});
    }

    render() {
        return (
            <Dialog scroll='body' type={this.props.type} open={this.props.open} onClose={this.props.close} className='user-dialog'>
                <div className='surrounder'>
                    <DialogTitle className='title'>Benutzer anlegen</DialogTitle>
                    <TextField size="small" value={this.state.user.username} onChange={this.usernameOnChangeHandler} className='field' label='Benutzername'/>
                    <TextField size="small" value={this.state.user.password} onChange={this.passwordOnChangeHandler} className='field' label='Passwort' type='password'/>
                    <TextField size="small" value={this.state.user.passwordCheck} onChange={this.passwordCheckOnChangeHandler} className='field' label='Passwort wiederholen' type='password'/>
                    <TextField size="small" value={this.state.user.assignedLocation ? this.state.user.assignedLocation.id : ''} onChange={this.assignedLocationOnChangeHandler} select className='field' label='Zugewiesener Standort'>
                        {this.props.locations.map((location,index) => (
                            <MenuItem key={index} value={location.id}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ToolButton onClick={this.addUserOnClickHandler} className='button'>Anlegen</ToolButton>
                </div>
            </Dialog>
        );
    }
}

export default UserDialog;