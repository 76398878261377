import React, { useState, useEffect } from 'react';
import './BatchItem.css';

// Components
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import Paper from '@mui/material/Paper';
import HelperFunctions from "../HelperFunctions";

const BatchItem = (props) => {

    const _handleDoubleClick = () => {
        if(props.showBatchDialog) {
            props.showBatchDialog();
        }
    }

    return (
        <Paper onDoubleClick={_handleDoubleClick} elevation={3} className='batch-item'>
            <DeleteIcon
                onClick={() => props.deleteBatch(props.batch)}
                className='icon delete-icon'
            />
            <ArticleIcon
                onClick={() => props.showBatchDialog(props.batch)}
                className='icon edit-icon'
            />

            <ul className="information-holder">
                <li>{props.batch && props.batch.nr ? HelperFunctions.formatString(props.batch.nr, 15) : "-"}</li>
                <li>{props.batch && props.batch.expirationDate ? HelperFunctions.formatDate(props.batch.expirationDate) : "-"}</li>
                <li>{props.batch && props.batch.material ? HelperFunctions.formatString(props.batch.material, 15) : "-"}</li>
                <li>{props.batch && props.batch.batchInventories !== null && props.batch && props.batch.batchInventories[0] && props.batch && props.batch.batchInventories[0].inventoryCountFree !== null ? props.batch.batchInventories[0].inventoryCountFree : "-"}</li>
            </ul>
        </Paper>
    );
};

export default BatchItem;