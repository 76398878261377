import React, {Component} from "react";
import './CreateFileDialog.css';

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";

class CreateFileDialog extends Component {

    state = {
        file:{}
    }

    fileOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 1048576) {
            this.setState({file:file});
        }else{
            this.props.showMessage(2,"Die Datei darf maximal 1MB groß sein");
        }
    }

    addFileOnClickHandler = () => {
        if(this.state.file.name) {
            this.props.addFile(this.state.file,this.props.parent);
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.onClose} className='create-file-dialog'>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle>Datei erstellen</DialogTitle>
                <DialogContent>
                    <Button variant="contained" component="label" className='field button' onChange={this.fileOnChangeHandler}>
                        Datei auswählen
                        <input type="file" hidden accept="application/pdf"/>
                    </Button>
                    <p>{this.state.file ? this.state.file.name : ''}</p>
                    <DialogActions>
                        <ToolButton onClick={this.addFileOnClickHandler} className='button'>Speichern</ToolButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
}

export default CreateFileDialog;