import React, {Component} from 'react';
import './MasterDataItem.css';

//Components
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';

class MasterDataItem extends Component {
    render() {
        return (
            <Paper elevation={3} className='master-data-item'>
                {this.props.type !== 2 ? <DeleteIcon onClick={this.props.deleteItem} className='icon delete-icon'/> : null}
                <EditIcon onClick={() => this.props.showItemDialog(false,this.props.item)} className='icon edit-icon'/>
                <p className='item-nr'>{this.props.item.nr ? this.props.item.nr : null}</p>
                <h3 className='item-name'>{this.props.item.name ? this.props.item.name : 'Name unbekannt'}</h3>
            </Paper>
        )
    }
}

export default MasterDataItem;