import React, { useState, useEffect } from 'react';
import './AutomatedCommunicationItem.css';

// Components
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {Tooltip} from "react-tooltip";

const AutomatedCommunicationItem = (props) => {

    const executeAutomatedCommunicationOnClickHandler = (e) => {
        e.stopPropagation();
        props.showExecuteAutomatedCommunicationDialog(props.automatedCommunication);
    }

    const showDeleteAutomatedCommunicationOnClickHandler = (e) => {
        e.stopPropagation();
        props.showDeleteAutomatedCommunicationDialog(props.automatedCommunication);
    }

    return (
        <Paper className="automated-communication-item" onClick={() => props.showAutomatedCommunicationDialog(props.automatedCommunication)} elevation={3}>
            <Tooltip id="automated-communication-item-tooltip"/>
            <div className="actions-holder">
                <CheckIcon
                    onClick={executeAutomatedCommunicationOnClickHandler}
                    className='icon check-icon' data-tooltip-id="automated-communication-item-tooltip" data-tooltip-place="top"
                    data-tooltip-content="Ausführen"
                />
                <CloseIcon
                    onClick={showDeleteAutomatedCommunicationOnClickHandler}
                    className='icon close-icon' data-tooltip-id="automated-communication-item-tooltip" data-tooltip-place="top"
                    data-tooltip-content="Löschen"
                />
            </div>
            <p>
                {props.automatedCommunication.kind === "MINIMUM_STOCK_SHORTFALL" ? " Inventar: Mindestbestands-Unterschreitung" : null}
                {props.automatedCommunication.kind === "CUSTOMER_OFFER_REMINDER" ? " Kundenbestellung: Angebots-Erinnerung" : null}
                {props.automatedCommunication.kind === "SUPPLIER_ORDER_CONFIRMATION_REMINDER" ? " Lieferantenbestellung: Auftragsbestätungs-Erinnerung" : null}
                {props.automatedCommunication.kind === "SUPPLIER_OFFER_REMINDER" ? " Lieferantenbestellung: Angebots-Erinnerung" : null} an
                {props.automatedCommunication && props.automatedCommunication.assignedCustomer ? " " + props.automatedCommunication.assignedCustomer.name : null}
                {props.automatedCommunication && props.automatedCommunication.assignedSupplier ? " " + props.automatedCommunication.assignedSupplier.name : null}
            </p>
        </Paper>
    );
};

export default AutomatedCommunicationItem;