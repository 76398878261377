import React, {Component} from "react";
import './ConnectedOrdersDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import ToolButton from "../../../../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ListItem from "../../../../../../../../../reusable/ListItem/ListItem";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

class ConnectedOrdersDialog extends Component {

    render() {
        return (
            <Dialog scroll='body' maxWidth={"lg"} open={this.props.open} onClose={this.props.close} className='connected-orders-dialog'>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>Verbundene Bestellungen</DialogTitle>

                    <div>
                        <h3 className="small-header">Bestellung verbinden</h3>
                        <TextField text='Suchwort' value={this.props.connectedOrdersSearchWord}
                                   onChange={this.props.connectedOrdersSearchWordOnChangeHandler}
                                   className='search-input' label='Suchwort' size='small'/>
                        {this.props.kind === 0 ? <TextField value={this.props.connectedOrdersSearchType}
                                                            onChange={this.props.connectedOrdersSearchTypeOnChangeHandler}
                                                            size='small' select
                                                            className='search-select' label='Suche'>
                            <MenuItem key={0} value={'free-search'}>Freie Suche</MenuItem>
                            <MenuItem key={1} value={'order-nr'}>Bestellnummer</MenuItem>
                            <MenuItem key={2} value={'invoice-nr'}>Rechnungsnummer</MenuItem>
                            <MenuItem key={3} value={'article-nr'}>Artikelnummer</MenuItem>
                            <MenuItem key={4} value={'article-name'}>Artikelname</MenuItem>
                            <MenuItem key={5} value={'customer-nr'}>Kundennummer</MenuItem>
                            <MenuItem key={6} value={'customer-name'}>Kundenname</MenuItem>
                            <MenuItem key={7} value={'batch-nr'}>Chargennummer</MenuItem>
                            <MenuItem key={8} value={'batch-material'}>Werkstoff</MenuItem>
                        </TextField> : null}
                        {this.props.kind === 1 ? <TextField value={this.props.connectedOrdersSearchType}
                                                            onChange={this.props.connectedOrdersSearchTypeOnChangeHandler}
                                                            size='small' select
                                                            className='search-select' label='Suche'>
                            <MenuItem key={0} value={'free-search'}>Freie Suche</MenuItem>
                            <MenuItem key={1} value={'order-nr'}>Bestellnummer</MenuItem>
                            <MenuItem key={2} value={'invoice-nr'}>Rechnungsnummer</MenuItem>
                            <MenuItem key={3} value={'article-nr'}>Artikelnummer</MenuItem>
                            <MenuItem key={4} value={'article-name'}>Artikelname</MenuItem>
                            <MenuItem key={5} value={'supplier-nr'}>Lieferantennummer</MenuItem>
                            <MenuItem key={6} value={'supplier-name'}>Lieferantenname</MenuItem>
                            <MenuItem key={7} value={'batch-nr'}>Chargennummer</MenuItem>
                            <MenuItem key={8} value={'batch-material'}>Werkstoff</MenuItem>
                        </TextField> : null}
                        <ToolButton main className='search-button' onClick={this.props.connectedOrdersSearchOnClickHandler}>
                            Suche
                        </ToolButton>
                        <div className='item-list'>
                            {this.props.connectedOrders ? this.props.connectedOrders.map((item, index) => (
                                <ListItem
                                    key={index}
                                    user={this.props.user}
                                    item={item}
                                    connectedOrder={true}
                                    kind={this.props.kind === 0 ? 1 : 0}
                                    formatDate={this.props.formatDate}
                                    alreadyAdded={false}
                                    addConnectedOrder={() => this.props.addConnectedOrder(item)}
                                />
                            )) : null}
                            {this.props.connectedLoading ? <Box className="loading-holder">
                                <CircularProgress className='load-icon'/>
                            </Box> : null}
                        </div>
                    </div>

                    <h3 className="small-header">Verbundene Bestellungen</h3>
                    <div className='item-list'>
                        {this.props.kind === 0 && this.props.order && this.props.order.supplierOrders ? this.props.order.supplierOrders.map((item, index) => (
                            <ListItem
                                key={index}
                                user={this.props.user}
                                item={item}
                                connectedOrder={true}
                                kind={1}
                                formatDate={this.props.formatDate}
                                alreadyAdded={true}
                                removeConnectedOrder={() => this.props.removeConnectedOrder(item)}
                            />
                        )) : null}
                        {this.props.kind === 1 && this.props.order && this.props.order.customerOrders ? this.props.order.customerOrders.map((item, index) => (
                            <ListItem
                                key={index}
                                user={this.props.user}
                                item={item}
                                connectedOrder={true}
                                kind={0}
                                formatDate={this.props.formatDate}
                                alreadyAdded={true}
                                removeConnectedOrder={() => this.props.removeConnectedOrder(item)}
                            />
                        )) : null}
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default ConnectedOrdersDialog;