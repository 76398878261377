import React, { useState, useEffect } from 'react';
import './BatchItemSmall.css';

// Components
import Paper from '@mui/material/Paper';
import HelperFunctions from "../HelperFunctions";
import EditIcon from "@mui/icons-material/Edit";

const BatchItemSmall = (props) => {

    return (
        <Paper elevation={3} className='batch-item-small'>
            <div className="header">
                <p className="batch-nr">Nr: {props.batch && props.batch.nr ? HelperFunctions.formatString(props.batch.nr, 15) : "-"}</p>
            </div>
            <ul className="information-holder">
                {props.batch.batchInventories ? props.batch.batchInventories.map((batchInventory, index) => (
                    <li className="whole-li" key={index}>
                        <p className="location-name">{batchInventory && batchInventory.inventory && batchInventory.inventory.location && batchInventory.inventory.location.name ? batchInventory.inventory.location.name : null}</p>
                        <div className="inventory-info">
                            <span>
                                Vorhanden: {batchInventory.edit ? (
                                <input className="input" type="number" value={batchInventory.inventoryCountFree}
                                       onChange={(e) => props.inventoryCountFreeOnChangeHandler(e, props.index, index)} />
                            ) : batchInventory.inventoryCountFree}
                            </span>
                            <EditIcon
                                onClick={() => props.toggleEdit(props.index,index)}
                                className='icon edit-icon'
                            />
                        </div>
                    </li>
                )) : null}
            </ul>
        </Paper>
    );
};

export default BatchItemSmall;