import React, {Component} from "react";
import './NavigationItems.css';

//Components
import {NavLink} from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import MenuBookIcon from '@mui/icons-material/MenuBook';

class NavigationItems extends Component {
    render() {
        return (
            <menu className='navigation-items'>
                <NavLink className='nav-item' activeClassName='active' exact to='/accounting-manager'><h2><DashboardIcon className='icon'/> Übersicht</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/accounting'><h2><MenuBookIcon className='icon'/> Buchhaltung</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/master-data'><h2><StorageIcon className='icon'/> Stammdaten</h2></NavLink>
                {this.props.user ? this.props.user.masterUser ? <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/administration'><h2><SupervisorAccountIcon className='icon'/> Administration</h2></NavLink> : null : null}
                <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/settings'><h2><SettingsIcon className='icon'/> Einstellungen</h2></NavLink>
            </menu>
        )
    }
}

export default NavigationItems;