import React, {Component} from "react";
import './AccountItem.css';

//Components
import Paper from '@mui/material/Paper';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccountDialog from "./AccountDialog/AccountDialog";
import DeleteIcon from "@mui/icons-material/Delete";

class AccountItem extends Component {

    state = {
        showAccountDialog:false
    }

    formatString = (string, maxLength) => {
        let formattedString = string;
        if(string.length > maxLength) {
            formattedString = string.substring(0,maxLength) + '...';
        }
        return formattedString;
    }

    //Dialog actions

    showAccountDialog = () => {
        this.setState({showAccountDialog:true});
    }

    closeAccountDialog = () => {
        this.setState({showAccountDialog:false});
    }

    render() {
        return (
            <Paper elevation={3} className='account-item'>

                <AccountDialog
                    showMessage={this.props.showMessage}
                    open={this.state.showAccountDialog}
                    close={this.closeAccountDialog}
                    account={this.props.item}
                    addEntryToList={this.props.addEntryToList}
                    indexArray={this.props.indexArray}
                    removeEntryFromList={this.props.removeEntryFromList}
                />

                <div className='top-field'>
                    <p title={this.props.item.name} className='field'>{this.props.item.name} ({this.props.item.nr})</p>
                    <MenuBookIcon onClick={this.showAccountDialog} className='icon book-icon'/>
                    {this.props.edit ?
                        <div>
                            <DeleteIcon onClick={() => this.props.showDeleteAccountDialog(this.props.item,this.props.indexArray)} className='icon delete-icon'/>
                        </div>
                        : null}
                </div>
            </Paper>
        )
    }
}

export default AccountItem;