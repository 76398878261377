import React, {Component} from "react";
import './DocumentToolbar.css';

//Components
import dm_logo from '../../../../../images/dm_logo.svg';
import NavigationItems from './NavigationItems/NavigationItems';
import ArrowBack from "@mui/icons-material/ArrowBack";
import {NavLink} from "react-router-dom";

class DocumentToolbar extends Component {

    state = {
        isMenuShown:false
    }

    toggleMenu = () => {
        this.setState({isMenuShown:!this.state.isMenuShown});
    }

    render() {

        let style;

        if(this.state.isMenuShown) {
            style = {
                width:'256px'
            }
        }else{
            style = {
                width:'0'
            }
        }

        return (
            <div className='document-toolbar'>
                <div className='top-panel'>
                    <i onClick={this.toggleMenu} className='fas fa-bars'/>
                </div>
                <div style={this.state.isMenuShown ? style : null} className='side-menu'>
                    <div className='logo-holder'>
                        <img src={dm_logo} alt="Document Manager 4.0 Logo"/>
                    </div>
                    <NavigationItems
                        className='navigation-items'
                        user={this.props.user}
                        company={this.props.company}
                    />
                    <NavLink exact to='/'>
                        <div className="icon-holder">
                            <ArrowBack className='icon arrow-icon animate'/>
                        </div>
                    </NavLink>
                </div>
            </div>
        )
    }
}


export default DocumentToolbar;