import React, {Component} from "react";
import './File.css';

//Components
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Tooltip } from 'react-tooltip'

class File extends Component {

    handleClick = (e) => {
        if(e.detail === 2) {
            this.props.showPDFDialog(this.props.item);
        }
    };

    render() {
        return (
            <div onContextMenu={(e) => this.props.onContextMenu(e,this.props.item)} className='file'>
                <Tooltip id="file-tooltip"/>
                <div onClick={this.handleClick} className='file-symbol'>
                    <PictureAsPdfIcon className='icon pdf-icon'/>
                </div>
                <p data-tooltip-id="file-tooltip" data-tooltip-place="top" data-tooltip-content={this.props.item.name.length > 12 ? this.props.item.name : null} className='file-name'>{this.props.formatString(this.props.item.name,12)}</p>
            </div>
        )
    }
}

export default File;