import React, {Component} from 'react';
import './MasterDataContent.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import ToolFab from "../../../../../../../reusable/ToolFab/ToolFab";
import AddIcon from '@mui/icons-material/Add';
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import GetAppIcon from '@mui/icons-material/GetApp';
import PublishIcon from '@mui/icons-material/Publish';
import InfoIcon from '@mui/icons-material/Info';
import MasterDataItem from "./MasterDataItem/MasterDataItem";
import ItemDialog from "./ItemDialog/ItemDialog";
import TextField from "@mui/material/TextField";
import YesCancelDialog from "../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import ImportDataDialog from "./ImportDataDialog/ImportDataDialog";
import MasterDataInfoDialog from "./MasterDataInfoDialog/MasterDataInfoDialog";
import {CSVLink} from "react-csv";
import { Tooltip } from 'react-tooltip'
import _ from "lodash";

class MasterDataContent extends Component {

    state = {
        shownItems: [],
        searchWord:'',
        searchType:'name',
        selectedItem:null,
        action:null,
        item: {
            nr:'',
            name:'',
            vat:this.props.company ? this.props.company.defaultVat : '',
            account:null,
            accountGroup:null,

            purchaseNetPrice:0,
            purchaseGrossPrice:0,
            purchasePriceDiscountCount:0,
            purchasePriceDiscountKind:0,
            purchasePriceDiscountPercentage:0,
            purchasePriceDiscountUnitPrice:0,

            salesNetPrice:0,
            salesGrossPrice:0,
            salesPriceDiscountCount:0,
            salesPriceDiscountKind:0,
            salesPriceDiscountPercentage:0,
            salesPriceDiscountUnitPrice:0,

            warehouse:'',
            shelfSeries:'',
            shelf:'',
            storageSpace:'',
            count:1,
            weight:0,
            attributeValues: [],
            streetName:'',
            streetNr:'',
            postalCode:'',
            city:'',
            country:''
        },
        isNewItem:true,
        formattedItems:[],

        //Dialogs
        showItemDialog:false,
        showDeleteItemDialog:false,
        showImportDataDialog:false,
        showMasterDataInfoDialog:false
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props !== prevProps) {
            this.searchOnClick();
        }
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownItems = [];
            this.props.items.forEach(element => {
                if(element[this.state.searchType] && element[this.state.searchType].toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                    shownItems.push(element);
                }
            });
            this.setState({shownItems:shownItems});
        }else{
            this.props.showMessage(2,'Wählen Sie eine Suche aus');
        }
    }

    formatData = () => {
        let formattedItems = [];
        this.state.shownItems.forEach(element => {
            let item = {
                name:element.name
            };
            if(element.nr !== undefined) {
                item.nr = element.nr;
            }
            if(element.purchaseNetPrice !== undefined) {
                item.purchaseNetPrice = element.purchaseNetPrice;
            }
            if(element.purchaseGrossPrice !== undefined) {
                item.purchaseGrossPrice = element.purchaseGrossPrice;
            }
            if(element.purchasePriceDiscountCount !== undefined) {
                item.purchasePriceDiscountCount = element.purchasePriceDiscountCount;
            }
            if(element.purchasePriceDiscountKind !== undefined) {
                item.purchasePriceDiscountKind = element.purchasePriceDiscountKind;
            }
            if(element.purchasePriceDiscountPercentage !== undefined) {
                item.purchasePriceDiscountPercentage = element.purchasePriceDiscountPercentage;
            }
            if(element.purchasePriceDiscountUnitPrice !== undefined) {
                item.purchasePriceDiscountUnitPrice = element.purchasePriceDiscountUnitPrice;
            }
            if(element.salesNetPrice !== undefined) {
                item.salesNetPrice = element.salesNetPrice;
            }
            if(element.salesGrossPrice !== undefined) {
                item.salesGrossPrice = element.salesGrossPrice;
            }
            if(element.salesPriceDiscountCount !== undefined) {
                item.salesPriceDiscountCount = element.salesPriceDiscountCount;
            }
            if(element.salesPriceDiscountKind !== undefined) {
                item.salesPriceDiscountKind = element.salesPriceDiscountKind;
            }
            if(element.salesPriceDiscountPercentage !== undefined) {
                item.salesPriceDiscountPercentage = element.salesPriceDiscountPercentage;
            }
            if(element.salesPriceDiscountUnitPrice !== undefined) {
                item.salesPriceDiscountUnitPrice = element.salesPriceDiscountUnitPrice;
            }
            if(element.count !== undefined) {
                item.count = element.count;
            }
            if(element.weight !== undefined) {
                item.weight = element.weight;
            }
            if(element.warehouse !== undefined) {
                item.warehouse = element.warehouse;
            }
            if(element.shelfSeries !== undefined) {
                item.shelfSeries = element.shelfSeries;
            }
            if(element.shelf !== undefined) {
                item.shelf = element.shelf;
            }
            if(element.storageSpace !== undefined) {
                item.storageSpace = element.storageSpace;
            }
            if(element.type !== undefined) {
                switch (element.type) {
                    case 0:
                        item.type = 'boolean'
                        break;
                    case 1:
                        item.type = 'number'
                        break;
                    case 2:
                        item.type = 'string'
                        break;
                    default:
                        item.type = 'unknown'
                        break;
                }
            }
            formattedItems.push(item);
        })
        this.setState({formattedItems:formattedItems});
    }

    //Dialog actions
    showItemDialog = (isNewItem,item) => {
        let copiedItem = _.cloneDeep(item);
        this.setState({isNewItem:isNewItem,item:copiedItem,showItemDialog:true});
    }

    closeItemDialog = () => {
        this.setState({showItemDialog:false});
    }

    showDeleteItemDialog = (item) => {
        this.setState({item:item,showDeleteItemDialog:true});
    }

    closeDeleteItemDialog = () => {
        this.setState({showDeleteItemDialog:false});
    }

    showImportDataDialog = () => {
        this.setState({showImportDataDialog:true});
    }

    closeImportDataDialog = () => {
        this.setState({showImportDataDialog:false});
    }

    showMasterDataInfoDialog = () => {
        this.setState({showMasterDataInfoDialog:true});
    }

    closeMasterDataInfoDialog = () => {
        this.setState({showMasterDataInfoDialog:false});
    }

    render() {

        let blankItem = {
            nr:'',
            name:'',
            articleGroup:null,

            defaultPaymentTarget:this.props.company && this.props.company.defaultPaymentTarget ? this.props.company.defaultPaymentTarget : 14,

            purchaseNetPrice:0,
            purchaseGrossPrice:0,
            purchasePriceDiscountCount:0,
            purchasePriceDiscountKind:0,
            purchasePriceDiscountPercentage:0,
            purchasePriceDiscountUnitPrice:0,

            salesNetPrice:0,
            salesGrossPrice:0,
            salesPriceDiscountCount:0,
            salesPriceDiscountKind:0,
            salesPriceDiscountPercentage:0,
            salesPriceDiscountUnitPrice:0,

            warehouse:'',
            shelfSeries:'',
            shelf:'',
            storageSpace:'',
            count:1,
            weight:0,

            attributeValues: [],
            streetName:'',
            streetNr:'',
            postalCode:'',
            city:'',
            country:''
        }

        let searchTypes = [];
        let title = null;
        let itemName = null;

        switch (this.props.type) {
            case 0:
                searchTypes = [
                    {
                        value:'nr',
                        label:'Artikelnummer'
                    },
                    {
                        value:'name',
                        label:'Artikelname'
                    }
                ];
                title = 'Artikel';
                itemName = 'Artikel';
                break;
            case 1:
                searchTypes = [
                    {
                        value:'name',
                        label:'Artikelgruppenname'
                    }
                ];
                title = 'Artikelgruppen';
                itemName = 'Artikelgruppe';
                break;
            case 2:
                searchTypes = [
                    {
                        value:'name',
                        label:'Standortname'
                    }
                ];
                title = 'Standorte';
                itemName = 'Standort';
                break;
            case 3:
                searchTypes = [
                    {
                        value:'nr',
                        label:'Kundennummer'
                    },
                    {
                        value:'name',
                        label:'Kundenname'
                    }
                ];
                title = 'Kunden';
                itemName = 'Kunde';
                break;
            case 4:
                searchTypes = [
                    {
                        value:'nr',
                        label:'Lieferantennummer'
                    },
                    {
                        value:'name',
                        label:'Lieferantenname'
                    }
                ];
                title = 'Lieferanten';
                itemName = 'Lieferant';
                break;
            case 5:
                searchTypes = [
                    {
                        value:'name',
                        label:'Attributname'
                    }
                ];
                title = 'Attribute';
                itemName = 'Attribut';
                break;
            case 6:
                searchTypes = [
                    {
                        value:'name',
                        label:'Titel'
                    }
                ];
                title = 'Nachrichtenvorlagen';
                itemName = 'Nachrichtenvorlage';
                break;
            default:
                break;
        }

        return (
            <div className='master-data-content'>
                <Tooltip id="master-data-content-tooltip"/>

                <InfoIcon onClick={this.showMasterDataInfoDialog} data-tooltip-id="master-data-content-tooltip" data-tooltip-place="top" data-tooltip-content='Stammdaten Info' className='icon info-icon'/>

                <ItemDialog
                    open={this.state.showItemDialog}
                    close={this.closeItemDialog}
                    type={this.props.type}
                    addNewItem={this.props.addNewItem}
                    updateItem={this.props.updateItem}
                    addItemToShownList={this.props.addItemToShownList}
                    showMessage={this.props.showMessage}
                    item={this.state.item}
                    isNewItem={this.state.isNewItem}
                    attributeValue={this.props.attributeValue}
                    attributes={this.props.attributes}
                    countries={this.props.countries}
                    company={this.props.company}
                    accounts={this.props.accounts}
                    articleGroups={this.props.articleGroups}
                    suppliers={this.props.suppliers}

                    //Files
                    saveFile={this.props.saveFile}
                    displayFile={this.props.displayFile}
                    loadFile={this.props.loadFile}
                />

                <MasterDataInfoDialog
                    open={this.state.showMasterDataInfoDialog}
                    close={this.closeMasterDataInfoDialog}
                />

                <YesCancelDialog
                    open={this.state.showDeleteItemDialog}
                    close={this.closeDeleteItemDialog}
                    header='Löschen'
                    text={this.props.type === 5 ? 'Wollen Sie das ' + itemName + ' wirklich löschen?' : this.props.type === 6 ? 'Wollen Sie die ' + itemName + ' wirklich löschen?' : 'Wollen Sie den ' + itemName + ' wirklich löschen?'}
                    onClick={() => this.props.deleteItem(this.state.item)}
                />

                <ImportDataDialog
                    open={this.state.showImportDataDialog}
                    close={this.closeImportDataDialog}
                    addMultipleItems={this.props.addMultipleItems}
                />

                <div className='header-bar'>
                    <h1>{title}</h1>
                    <TextField size="small" text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort'/>
                    <TextField size="small" value={this.state.searchType} onChange={this.searchTypeOnChange} select className='search-select' label='Suche'>
                        {searchTypes.map((searchType,index) => (
                            <MenuItem key={index} value={searchType.value}>
                                {searchType.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                    <CSVLink filename={'wm_masterdata_' + Date.now() + '.csv'} data={this.state.formattedItems}><GetAppIcon data-tooltip-id="master-data-content-tooltip" data-tooltip-place="top" data-tooltip-content='Ausgewählte Daten als .csv-Datei herunterladen' onClick={this.formatData} className='icon download-icon'/></CSVLink>
                </div>
                <div className='item-list'>
                    {this.state.shownItems.map((item,index) => (
                        <MasterDataItem
                            key={index}
                            item={item}
                            showItemDialog={this.showItemDialog}
                            deleteItem={() => this.showDeleteItemDialog(item)}
                            type={this.props.type}
                        />
                    ))}
                </div>
                <ToolFab onClick={this.showImportDataDialog} className='upload-fab' aria-label="add">
                    <PublishIcon />
                </ToolFab>

                <ToolFab className='add-fab' onClick={() => this.showItemDialog(true,blankItem)} aria-label="add">
                    <AddIcon />
                </ToolFab>
            </div>
        )
    }
}

export default MasterDataContent;