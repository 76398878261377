import React, {Component} from "react";
import './RenameFileDialog.css';

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from '@mui/icons-material/Close';
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import InputField from "../../../../../../../reusable/InputField/InputField";

class RenameFileDialog extends Component {

    state = {
        newName:''
    }

    nameOnChangeHandler = (e) => {
        this.setState({newName:e.target.value});
    }

    renameFileOnClickHandler = () => {
        if(this.state.newName) {
            this.props.renameFile(this.props.item,this.state.newName + ".pdf");
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.onClose} className='rename-file-dialog'>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle>Datei umbennenen</DialogTitle>
                <DialogContent>
                    <InputField onChange={this.nameOnChangeHandler}/><p className='pdf-extension'>.pdf</p>
                    <DialogActions>
                        <ToolButton onClick={this.renameFileOnClickHandler} className='button'>Speichern</ToolButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
}

export default RenameFileDialog;