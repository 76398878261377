import React from "react";
import './Communication.css';

//Components
import {Route} from "react-router-dom";
import AutomatedCommunication from "./AutomatedCommunication/AutomatedCommunication";

const Communication = (props) => {
    return (
        <div className='communication'>
            <Route exact path='/*/communication/'>
                <AutomatedCommunication
                    showMessage={props.showMessage}
                    logout={props.logout}
                    setLoading={props.setLoading}
                />
            </Route>
        </div>
    );
}

export default Communication;