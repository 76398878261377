import React, { useState, useEffect, useRef } from 'react';
import './Batches.css';

//Components
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import { Tooltip } from 'react-tooltip'
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import BatchDialog from "./BatchDialog/BatchDialog";
import ToolFab from "../../../../../../../reusable/ToolFab/ToolFab";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import api from "../../../../../../../api";
import OrderItem from "../../../../CommonComponents/Actions/OrderManagement/Orders/OrderItem/OrderItem";
import BatchItem from "../../../../../../../reusable/BatchItem/BatchItem";
import SecureYesCancelDialog from "../../../../../../../reusable/SecureYesCancelDialog/SecureYesCancelDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

const Batches = (props) => {

    const [searchType,setSearchType] = useState("free-search");
    const [searchWord,setSearchWord] = useState(null);
    const [includeCompletedBatches,setIncludeCompletedBatches] = useState(false);
    const [sort,setSort] = useState(null);
    const [batch,setBatch] = useState(null);
    const [batches,setBatches] = useState(null);
    const [isNewitem,setIsNewItem] = useState(null);

    const [batchDialog,setBatchDialog] = useState(false);
    const [deleteBatchDialog,setDeleteBatchDialog] = useState(false);

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchOnClick();
        }
    }

    const searchOnClick = () => {
        if(searchWord) {
            props.setLoading(true);
            let url;
            if(includeCompletedBatches) {
                url = "/batches/search/free-search/all/" + searchWord;
            }else{
                url = "/batches/search/free-search/uncompleted/" + searchWord;
            }
            api.get(url)
                .then(response => {
                    props.setLoading(false);
                    if(response.data.length === 0) {
                        props.showMessage(1,"Es wurden keine Chargen gefunden");
                    }
                    setBatches(response.data);
                })
                .catch(error => {
                    props.setLoading(false);
                    console.log(error);
                    if(error.response && error.response.data) {
                        props.showMessage(2,error.response.data);
                    } else if(!error.response && error.request) {
                        props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    } else {
                        props.showMessage(2,"Etwas ist schiefgelaufen");
                        props.logout();
                    }
                });
        }else{
            props.showMessage(2,"Es muss ein Suchwort eingegeben werden");
        }
    }

    const deleteBatch = (batch) => {
        props.setLoading(true);
        const url = "/batches/" + batch.id;
        api.delete(url)
            .then(response => {
                props.setLoading(false);
                removeBatchFromList(batch);
                props.showMessage(0,"Die Charge wurde erfolgreich gelöscht");
            })
            .catch(error => {
                props.setLoading(false);
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
            });
    }

    const loadUrgentBatches = () => {
        props.setLoading(true);
        let url = "/batches/search/urgent";
        api.get(url)
            .then(response => {
                props.setLoading(false);
                if(response.data.length === 0) {
                    props.showMessage(1,"Es wurden keine Chargen gefunden");
                }else{
                    setBatches(response.data);
                }
            })
            .catch(error => {
                props.setLoading(false);
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
            });
    }

    // Batches

    const addBatchToList = (batch) => {
        const updatedBatches = [ ...batches ];
        updatedBatches.push(batch);
        setBatches(updatedBatches);
        props.addBatchToList(batch);
    }

    const updateBatchInList = (batch) => {
        const updatedBatches = [ ...batches ];
        for(let i = 0; i < batches.length; i++) {
            if(updatedBatches[i].id === batch.id) {
                updatedBatches[i] = batch;
                props.updateBatchInList(batch);
                break;
            }
        }
        setBatches(updatedBatches);
    }

    const removeBatchFromList = (batch) => {
        const updatedBatches = [ ...batches ];
        for(let i = 0; i < updatedBatches.length; i++) {
            if (updatedBatches[i].id === batch.id) {
                updatedBatches.splice(i,1)
                setBatches(updatedBatches);
                props.removeBatchFromList(batch);
                return;
            }
        }
    }

    const sortedBatches = () => {
        return batches.sort((a, b) => {
            switch (sort) {
                case "nr":
                    if (a.nr === null || a.nr === undefined) {
                        return 1;
                    }
                    if (b.nr === null || b.nr === undefined) {
                        return -1;
                    }
                    return a.nr.localeCompare(b.nr);
                case "expirationDate":
                    return ((b.expirationDate ? new Date(b.expirationDate) : 0) - (a.expirationDate ? new Date(a.expirationDate) : 0));
                case "material":
                    return (b.material || 0) - (a.material || 0);
                case "inventoryCountFree":
                    if (!a.batchInventories || a.batchInventories[0] === null || a.batchInventories[0].inventoryCountFree === null) {
                        return 1;
                    }
                    if (!b.batchInventories || b.batchInventories[0] === null || b.batchInventories[0].inventoryCountFree === null) {
                        return -1;
                    }
                    return a.batchInventories[0].inventoryCountFree - b.batchInventories[0].inventoryCountFree;
                default:
                    return 0;
            }
        })
    }

    const blankBatch = {
        inventoryCountFree: 1,
        batchInventories:[
            {
                location:props.user.assignedLocation,
                inventoryCountFree: 0
            }
        ]
    }

    const showBatchDialog = (batch) => {
        setBatch(batch);
        setBatchDialog(true);
    }

    const closeBatchDialog = () => {
        setBatchDialog(false);
        setBatch(null);
    }

    const showDeleteBatchDialog = (batch) => {
        setBatch(batch);
        setDeleteBatchDialog(true);
    }

    const closeDeleteBatchDialog = () => {
        setDeleteBatchDialog(false);
        setBatch(null);
    }

    return (
        <div className='batches'>

            <Tooltip id="batches-tooltip"/>

            <SecureYesCancelDialog
                open={deleteBatchDialog}
                close={closeDeleteBatchDialog}
                header='Löschen'
                text='Wollen Sie die Charge wirklich löschen?'
                onClick={() => deleteBatch(batch)}
                showMessage={props.showMessage}
            />

            {batch && batchDialog ? <BatchDialog
                open={batchDialog}
                close={closeBatchDialog}
                showMessage={props.showMessage}
                logout={props.logout}
                setLoading={props.setLoading}
                batches={props.batches}
                batch={batch}
                addBatchToList={addBatchToList}
                updateBatchInList={updateBatchInList}
                removeBatchFromList={removeBatchFromList}
                user={props.user}
                articles={props.articles}
            /> : null}

            <div className='header-bar'>
                <WarningAmberIcon className="icon warning-icon" onClick={() => loadUrgentBatches()}
                                  data-tooltip-id="batches-tooltip" data-tooltip-place="top"
                                  data-tooltip-content="Ablaufende Chargen anzeigen"/>
                <h1>Chargenverwaltung</h1>
                <TextField text='Suchwort' value={searchWord} onChange={(e) => setSearchWord(e.target.value)} onKeyDown={_handleKeyDown}
                           className='field search-input' label='Suchwort' size='small'/>
                <TextField value={searchType} onChange={(e) => setSearchType(e.target.value)} size='small' select onKeyDown={_handleKeyDown}
                           className='field search-select' label='Suche'>
                    <MenuItem key={0} value={'free-search'}>Freie Suche</MenuItem>
                </TextField>

                <ToolButton main className='search-button' onClick={searchOnClick}>
                    Suche
                </ToolButton>

                <FormGroup>
                    <FormControlLabel className="field checkbox-field" control={<Checkbox className="checkbox" checked={includeCompletedBatches} onChange={() => setIncludeCompletedBatches(!includeCompletedBatches)} />} label="auch abgeschlossene Chargen anzeigen" />
                </FormGroup>

                <ul className="sort-options">
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("nr")}
                               style={sort === "nr" ? {
                                   backgroundColor: '#2293BF',
                                   color: 'white'
                               } : null}>Chargennr.</Paper>
                    </li>
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("expirationDate")}
                               style={sort === "expirationDate" ? {
                                   backgroundColor: '#2293BF',
                                   color: 'white'
                               } : null}>Min.-Halt.-Dat.</Paper>
                    </li>
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("material")}
                               style={sort === "material" ? {
                                   backgroundColor: '#2293BF',
                                   color: 'white'
                               } : null}>Material</Paper>
                    </li>
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("inventoryCountFree")}
                               style={sort === "inventoryCountFree" ? {
                                   backgroundColor: '#2293BF',
                                   color: 'white'
                               } : null}>Vorhanden</Paper>
                    </li>
                </ul>
            </div>

            <div className="item-list">
                {batches ? sortedBatches().map((batch) => (
                    <BatchItem
                        key={batch.id}
                        batch={batch}
                        showBatchDialog={showBatchDialog}
                        deleteBatch={showDeleteBatchDialog}
                        setLoading={props.setLoading}
                        logout={props.logout}
                        showMessage={props.showMessage}
                    />)) : null}
            </div>

            {false ? <ToolFab onClick={() => showBatchDialog(blankBatch)} className='add-fab' aria-label="generate">
                <AddIcon/>
            </ToolFab> : null}
        </div>
    )
}

export default Batches;