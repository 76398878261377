import React, {Component} from "react";
import './PositionDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import helperFunctions from "../../../../../../../../reusable/HelperFunctions";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import { Tooltip } from 'react-tooltip'

class PositionDialog extends Component {

    state = {
        position:this.props.position
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({position:this.props.position});
        }
    }

    stateOnChangeHandler = (e) => {
        let position = this.state.position;
        position.state = e.target.value;
        this.setState({position:position});
    }

    articleOnChangeHandler = (e) => {
        let position = this.state.position;
        position.article = e;
        this.setState({position:position});
    }

    articleNrOnChangeHandler = (e) => {
        let position = this.state.position;
        position.article.nr = e.target.value;
        this.setState({position:position});
    }

    noteOnChangeHandler = (e) => {
        let position = this.state.position;
        position.note = e.target.value;
        this.setState({position:position});
    }

    savePositionOnClickHandler = () => {
        if(this.state.position.article && this.state.position.article.name) {
            this.props.savePosition(this.state.position,this.props.index);
        }else{
            this.props.showMessage(2,"Fehlender oder falsche Werte");
        }
    }

    render() {

        const filter = createFilterOptions();

        return (
            <Dialog scroll='body' maxWidth={"md"} open={this.props.open} onClose={this.props.close} className='position-dialog'>
                <Tooltip id="position-dialog-tooltip"/>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>Positionsübersicht</DialogTitle>
                    <DialogContent>
                        <ul>
                            <li>Komm.-Nr.: {this.state.position.commissionNr ? this.state.position.commissionNr : "-"}</li>
                            <li>Best.-Nr.: {this.state.position.orderNr ? this.state.position.orderNr : "-"}</li>
                            {this.props.kind === 0 && this.state.position.assignedCustomer ?
                                <li>Kunde: {this.state.position.assignedCustomer.name}</li> : this.props.kind === 1 && this.state.position.assignedSupplier ?
                                    <li>Lieferant: {this.state.positionthis.state.position.assignedSupplier.name}</li> : null}
                            <li>Best.-Dat.: {this.props.orderDate ? helperFunctions.formatDate(this.props.orderDate) : "-"}</li>
                            <li>Gegenstand.: {this.props.position.item ? this.props.position.item : "-"}</li>
                            <li>
                                {this.props.kind === 0 ?
                                    <TextField disabled={this.state.position.locked} size="small"
                                               value={this.state.position.state} onChange={this.stateOnChangeHandler}
                                               select className='position-field state-select' label='Status'>
                                        <MenuItem key={0} value="NO_STATE">Kein Status</MenuItem>
                                        <MenuItem key={1} value="OFFER_CREATION">Angebotserstellung</MenuItem>
                                        <MenuItem key={2} value="NOT_YET_APPROVED">Noch nicht freigegeben</MenuItem>
                                        <MenuItem key={3} value="WORK_IN_PROGRESS">In Bearbeitung</MenuItem>
                                        <MenuItem key={4} value="COMPLETED">Abgeschlossen</MenuItem>
                                    </TextField> : null}
                                {this.props.kind === 1 ?
                                    <TextField disabled={this.state.position.locked} size="small"
                                               value={this.state.position.state} onChange={this.stateOnChangeHandler}
                                               select className='position-field state-select' label='Status'>
                                        <MenuItem key={0} value="NO_STATE">Kein Status</MenuItem>
                                        <MenuItem key={1} value="REQUESTED">Angefragt</MenuItem>
                                        <MenuItem key={2} value="CONFIRMED">Bestätigt</MenuItem>
                                        <MenuItem key={3} value="REJECTED">Abgelehnt</MenuItem>
                                        <MenuItem key={4} value="GOODS_RECEIVED">Ware erhalten</MenuItem>
                                    </TextField> : null}
                            </li>
                            <li>
                                <div>
                                    <Autocomplete
                                        disabled={true}
                                        size="small"
                                        value={this.state.position.article && this.state.position.article.name ? this.state.position.article.name : ''}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            const {inputValue} = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => inputValue === option.name);
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    inputValue,
                                                    name: `Erstellen "${inputValue}"`,
                                                });
                                            }

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="articleNames"
                                        className='position-field'
                                        options={this.props.articles}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.name;
                                        }}
                                        renderOption={(props, option) => <li
                                            key={option.name} {...props}>{option.name}</li>}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField size="small" {...params} label="Artikelname"
                                                       value={this.state.position.article && this.state.position.article.name ? this.state.position.article.name : ''}/>
                                        )}
                                    />
                                </div>
                            </li>
                            <li>
                                <TextField disabled={this.state.position.locked} size="small"
                                           value={this.state.position ? this.state.position.note : ''} multiline
                                           maxRows={5}
                                           label='Bemerkung (maximal 255 Zeichen)' inputProps={{maxLength: 255}}
                                           variant="outlined"
                                           placeholder="Erscheint nicht auf Dokumenten" className='textarea'
                                           onChange={this.noteOnChangeHandler}/>
                            </li>
                        </ul>
                    </DialogContent>
                    <DialogActions>
                        <ToolButton className='button' onClick={this.savePositionOnClickHandler}>
                            Speichern
                        </ToolButton>
                    </DialogActions>
                </div>
            </Dialog>
        )
    }
}

export default PositionDialog;