import React, {Component} from "react";
import './ReportDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import ArticleReport from "./ArticleReport/ArticleReport";

class ReportDialog extends Component {
    render() {
        return (
            <Dialog scroll='body' maxWidth={"xl"} open={this.props.open} onClose={this.props.close} className='report-dialog'>
                {this.props.reportResult ?
                    <div className='surrounder'>
                        <DialogTitle>Ergebnis</DialogTitle>
                        <DialogContent>
                            {this.props.reportResult ? this.props.reportResult.map(articleReport => (
                                <ArticleReport
                                    articleReport={articleReport}

                                />
                            )) : null}
                        </DialogContent>
                    </div> :
                    <div className='surrounder'>
                        <DialogTitle>Ergebnis</DialogTitle>
                        <DialogContent>
                            <CircularProgress />
                        </DialogContent>
                    </div>
                }
            </Dialog>
        )
    }
}

export default ReportDialog;