import React, { useState, useEffect, useRef } from 'react';
import './StockTaking.css';

//Components
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import { Tooltip } from 'react-tooltip'
import api from "../../../../../../../api";
import StockTakingInventory from "./StockTakingInventory/StockTakingInventory";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import StockTakingInventoryDialog from "./StockTakingInventoryDialog/StockTakingInventoryDialog";
import YesCancelDialog from "../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import GridOnIcon from '@mui/icons-material/GridOn';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const StockTaking = (props) => {

    const [activeStockTaking, setActiveStockTaking] = useState(null);
    const [stockTakingInventoryDialog,setStockTakingInventoryDialog] = useState(false);
    const [activeStockTakingInventory,setActiveStockTakingInventory] = useState(null);
    const [stockTakingInventories,setStockTakingInventories] = useState([]);
    const [stopStockTakingDialog,setStopStockTakingDialog] = useState(false);

    useEffect(() => {
        loadCurrentStockTakingWithoutInventories();
    }, []);

    const loadCurrentStockTakingWithoutInventories = () => {
        props.setLoading(true);
        api.get("/stock-taking/without-inventories")
            .then(response => {
                setActiveStockTaking(response.data);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const loadCurrentStockTaking = () => {
        props.setLoading(true);
        api.get("/stock-taking")
            .then(response => {
                setActiveStockTaking(response.data.stockTaking);
                setStockTakingInventories(response.data.inventories)
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const stockTakingOnClickHandler = () => {
        if(activeStockTaking) {
            if(activeStockTaking.status === "active") {
                pauseStockTaking();
            }else if(activeStockTaking.status === "paused") {
                continueStockTaking();
            }
        }else{
            startStockTaking();
        }
    }

    const stopStockTakingOnClickHandler = () => {
        showStopStockTakingDialog();
    }

    const startStockTaking = () => {
        props.setLoading(true);
        api.post("/stock-taking/start")
            .then(response => {
                setActiveStockTaking(response.data);
                props.setLoading(false);
                props.showMessage(0,"Inventur gestartet");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const continueStockTaking = () => {
        props.setLoading(true);
        api.post("/stock-taking/continue")
            .then(response => {
                setActiveStockTaking(response.data);
                props.setLoading(false);
                props.showMessage(0,"Inventur fortgesetzt");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const pauseStockTaking = () => {
        props.setLoading(true);
        api.post("/stock-taking/pause")
            .then(response => {
                setActiveStockTaking(response.data);
                props.setLoading(false);
                props.showMessage(0,"Inventur pausiert");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const stopStockTaking = () => {
        props.setLoading(true);
        api.post("/stock-taking/stop")
            .then(response => {
                setActiveStockTaking(null);
                setStockTakingInventories([]);
                setActiveStockTakingInventory(null);
                props.setLoading(false);
                props.showMessage(0,"Inventur abgeschlossen");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const generateExcelSheet = () => {
        props.setLoading(true);
        api.get("/stock-taking/excel", { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'Inventur.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const showStockTakingInventoryDialog = (stockTakingInventory) => {
        setActiveStockTakingInventory(stockTakingInventory);
        setStockTakingInventoryDialog(true);
    }

    const closeStockTakingInventoryDialog = () => {
        setStockTakingInventoryDialog(false);
        setActiveStockTakingInventory(null);
    }

    const addStockTakingInventoryToList = (stockTakingInventory) => {
        let updatedStockTakingInventories = [...stockTakingInventories];
        updatedStockTakingInventories.push(stockTakingInventory);
        setStockTakingInventories(updatedStockTakingInventories);
    }

    const updateStockTakingInventoryInList = (stockTakingInventory) => {
        let updatedStockTakingInventories = [...stockTakingInventories];
        for(let i = 0; i < updatedStockTakingInventories.length; i++) {
            if(updatedStockTakingInventories[i].id === stockTakingInventory.id) {
                updatedStockTakingInventories[i] = stockTakingInventory;
                break;
            }
        }
        setStockTakingInventories(updatedStockTakingInventories);
    }

    const removeStockTakingInventoryFromList = (stockTakingInventory) => {
        let updatedStockTakingInventories = [...stockTakingInventories];
        for(let i = 0; i < updatedStockTakingInventories.length; i++) {
            if(updatedStockTakingInventories[i].id === stockTakingInventory.id) {
                updatedStockTakingInventories.splice(i,1);
                break;
            }
        }
        setStockTakingInventories(updatedStockTakingInventories);
    }

    const showStopStockTakingDialog = () => {
        setStopStockTakingDialog(true);
    }

    const closeStopStockTakingDialog = () => {
        setStopStockTakingDialog(false);
    }

    return (
        <div className='stock-taking'>

            <Tooltip id="stock-taking-tooltip"/>

            <YesCancelDialog
                open={stopStockTakingDialog}
                close={closeStopStockTakingDialog}
                header='Inventur abschließen'
                text='Wollen Sie die Inventur wirklich abschließen? Stellen Sie sicher, dass Sie vorher alle relevanten Inventurdaten exportiert (Grid-Symbol oben links) haben.'
                onClick={() => stopStockTaking(activeStockTaking)}
            />

            {stockTakingInventoryDialog ? <StockTakingInventoryDialog
                open={stockTakingInventoryDialog}
                close={closeStockTakingInventoryDialog}
                showMessage={props.showMessage}
                setLoading={props.setLoading}
                logout={props.logout}
                stockTakingInventory={activeStockTakingInventory}
                articles={props.articles}
                batches={props.batches}
                addStockTakingInventoryToList={addStockTakingInventoryToList}
                updateStockTakingInventoryInList={updateStockTakingInventoryInList}
                removeStockTakingInventoryFromList={removeStockTakingInventoryFromList}
                addBatchToList={props.addBatchToList}
                updateBatchInList={props.updateBatchInList}
                removeBatchFromList={props.removeBatchFromList}
                setActiveStockTakingInventory={setActiveStockTakingInventory}
                getArticleInformation={props.getArticleInformation}
                displayBatchInformation={props.displayBatchInformation}
                user={props.user}
            /> : null}

            <h1>Inventur</h1>
            {activeStockTaking ? <div className="content-div">
                <div className="stock-taking-actions">
                    <GridOnIcon className="icon excel-icon action" onClick={generateExcelSheet} data-tooltip-id="stock-taking-tooltip"
                                data-tooltip-place="top" data-tooltip-content="Excel generieren"/>
                    <AutorenewIcon className="icon load-icon action" onClick={loadCurrentStockTaking} data-tooltip-id="stock-taking-tooltip"
                                data-tooltip-place="top" data-tooltip-content="Alle Bestandsbuchungen laden"/>
                </div>
                <div className="stock-taking-inventories">
                    <Paper elevation={activeStockTaking.status === "active" ? 3 : 1} style={activeStockTaking.status !== "active" ? {background:"lightgrey",cursor:"not-allowed"} : null} className="add-stock-taking-inventory" onClick={activeStockTaking.status === "active" ? () => showStockTakingInventoryDialog(null) : null}>
                        <AddIcon className="icon add-icon"/>
                    </Paper>
                    <p className="info-text">Um alle Verbuchungen der akutellen Inventur zu sehen, drücken Sie das Ladesymbol. Dies kann jedoch bei vielen Verbuchungen etwas länger dauern.</p>
                    <div className="list-holder">
                        {[...stockTakingInventories].reverse().map((stockTakingInventory) => (
                            <StockTakingInventory
                                key={stockTakingInventory.id}
                                stockTakingInventory={stockTakingInventory}
                                showStockTakingInventoryDialog={showStockTakingInventoryDialog}
                            />
                        ))}
                    </div>
                </div>
            </div> : <div>
                <p className="stock-taking-info">Derzeit läuft keine Inventur</p>
            </div>}

            <ToolButton onClick={stockTakingOnClickHandler} main className='button stock-taking-button'>{activeStockTaking ? activeStockTaking.status === "active" ? "Inventur pausieren" : "Inventur fortsetzen" : "Inventur starten"}</ToolButton>
            {activeStockTaking && activeStockTaking.status === "paused" ? <ToolButton onClick={stopStockTakingOnClickHandler} className='button book-stock-taking-button'>Inventur abschließen</ToolButton> : null}
        </div>
    )
}

export default StockTaking;