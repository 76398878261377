import React, {Component} from "react";
import './FileSystemContextMenu.css';

//Components

class FileSystemContextMenu extends Component {
    render() {
        return (
            <ul className='file-system-context-menu' style={{left:this.props.anchorPoint.x,top:this.props.anchorPoint.y}}>
                <li onClick={this.props.createDirectoryOnClickHandler}><p>Neuen Ordner erstellen</p></li>
                <li onClick={this.props.createFileOnClickHandler}><p>Neue Datei erstellen</p></li>
            </ul>
        )
    }
}

export default FileSystemContextMenu;