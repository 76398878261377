import React, {Component} from "react";
import './ArticleInformation.css';

//Component
import ToolTextPair from "../../../../../reusable/TextField/ToolTextPair";
import HelperFunctions from "../../../../../reusable/HelperFunctions";

class ArticleInformation extends Component {

    render() {

        let articleInformation = null;
        let batchInformation = null;
        if(this.props.articleInformation !== null && this.props.articleInformation.inventoryCount !== null) {
            articleInformation = <div className="fields-holder">

                <ToolTextPair
                    className='big-field'
                    text='Artikelnummer'
                    value={this.props.articleInformation.article.nr ? this.props.articleInformation.article.nr : "-"}
                />

                <ToolTextPair
                    className='big-field'
                    text='Artikelname'
                    value={this.props.articleInformation.article.name ? this.props.articleInformation.article.name : "-"}
                />

                <ToolTextPair
                    className='field'
                    text='Lagerbestand'
                    value={this.props.articleInformation.inventoryCount}
                    style={this.props.articleInformation.inventoryCount >= 0 ? {color:"lightgreen"} : {color:"lightcoral"}}
                />

                <ToolTextPair
                    className='field'
                    text='Mindestbestand'
                    value={this.props.articleInformation.article.minimumStock ? this.props.articleInformation.article.minimumStock : "-"}
                    style={this.props.articleInformation.article.minimumStock ? this.props.articleInformation.inventoryCount >= this.props.articleInformation.article.minimumStock ? {color:"lightgreen"} : {color:"lightcoral"} : null}
                />

                <ToolTextPair
                    className='field'
                    text='Reserviert'
                    value={this.props.articleInformation.reservationCount !== null ? this.props.articleInformation.reservationCount : "-"}
                />

                <ToolTextPair
                    className='field'
                    text='Frei'
                    value={this.props.articleInformation.inventoryCount - this.props.articleInformation.reservationCount}
                    style={(this.props.articleInformation.inventoryCount - this.props.articleInformation.reservationCount) >= 0 ? {color:"lightgreen"} : {color:"lightcoral"}}
                />

                <div>
                    <ul>
                        {this.props.articleInformation.article.attributeValues.map(attributeValue => (
                            <li key={attributeValue.id}><ToolTextPair
                                className='field'
                                text={attributeValue.attribute.name}
                                value={attributeValue.attribute.type === 0 ? attributeValue.booleanValue.toString() :
                                    attributeValue.attribute.type === 1 ? attributeValue.numberValue :
                                        attributeValue.attribute.type === 2 ? attributeValue.stringValue :
                                            'unbekannter Datentyp'}
                            /></li>
                        ))}
                    </ul>
                </div>
            </div>
        }
        if(this.props.batchInformation !== null) {
            batchInformation = <div className="fields-holder">

                <ToolTextPair
                    className='big-field'
                    text='Chargennummer'
                    value={this.props.batchInformation.nr ? this.props.batchInformation.nr : "-"}
                />

                <ToolTextPair
                    className='big-field'
                    text='Mindesthaltbarkeitsdatum'
                    value={this.props.batchInformation.expirationDate ? HelperFunctions.formatDate(this.props.batchInformation.expirationDate) : "-"}
                />

                {this.props.batchInformation && this.props.batchInformation.batchInventories ? this.props.batchInformation.batchInventories.map(batchInventory => (
                    batchInventory.inventory && batchInventory.inventory.location && this.props.user && this.props.user.assignedLocation && batchInventory.inventory.location.id === this.props.user.assignedLocation.id ?
                        <ToolTextPair
                            className='field'
                            text='Lagerbestand'
                            value={batchInventory.inventoryCountFree}
                        /> : null
                )) : null}

                <ToolTextPair
                    className='field'
                    text='Chargengesamtanzahl'
                    value={this.props.batchInformation.inventoryCountTotal}
                />
            </div>
        }

        return (
            <div className='article-information'>
                {articleInformation}
                {batchInformation}
            </div>
        )
    }
}

export default ArticleInformation;