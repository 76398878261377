import React, {Component} from "react";
import './ArticleReport.css';

//Components
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

class ArticleReport extends Component {

    state = {
        folded:true
    }

    toggleContent = () => {
        this.setState({folded:!this.state.folded});
    }

    formatString = (string, maxLength) => {
        let formattedString = string;
        if(string.length > maxLength) {
            formattedString = string.substring(0,maxLength) + '...';
        }
        return formattedString;
    }

    render() {
        let detailedInformation = this.state.folded ?
            null :
            <div className='detailed-information'>
                <table>
                    <tr>
                        <th>Anzahl</th>
                        <th>Von</th>
                        <th>Nach</th>
                        <th>Kunde</th>
                        <th>Lieferant</th>
                        <th>Datum</th>
                    </tr>
                    {this.props.articleReport.transactions.map(transaction => (
                        <tr>
                            <td>{transaction.inventoryCount}</td>
                            <td>{transaction.fromLocation.name}</td>
                            <td>{transaction.toLocation ? transaction.toLocation.name : '-'}</td>
                            <td>{transaction.assignedCustomer ? transaction.assignedCustomer.name : '-'}</td>
                            <td>{transaction.assignedSupplier? transaction.assignedSupplier.name : '-'}</td>
                            <td>{transaction.date}</td>
                        </tr>
                    ))}
                </table>
            </div>

        return (
            <Paper elevation={3} className='article-report'>
                <div className='top-field'>
                    {this.state.folded ?
                        <KeyboardArrowRightIcon onClick={this.toggleContent} className='arrow-icon'/> :
                        <KeyboardArrowDownIcon onClick={this.toggleContent} className='arrow-icon' />}
                    <ul>
                        <li>
                            <p title={this.props.articleReport.article.name + ' (' + this.props.articleReport.article.nr + ')'} className='field'>{this.formatString(this.props.articleReport.article.name,15)}</p>
                        </li>
                        <li>
                            <p className='field'>{'+' + this.props.articleReport.inventoryCountIn} (-{(this.props.articleReport.sumCosts).toFixed(2)} €)</p>
                        </li>
                        <li>
                            <p className='field'>{this.props.articleReport.inventoryCountOut} (+{(this.props.articleReport.sumProfit).toFixed(2)} €)</p>
                        </li>
                    </ul>
                </div>
                {detailedInformation}
            </Paper>
        )
    }
}

export default ArticleReport;