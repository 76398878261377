import React, {Component} from "react";
import './AccountGroupAccountDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

class AccountGroupAccountDialog extends Component {

    state = {
        accountGroupAccount:this.props.accountGroupAccount,
        isAccountGroup:this.props.forcedAccountGroup
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({accountGroupAccount:this.props.accountGroupAccount,isAccountGroup:this.props.forcedAccountGroup});
        }
    }

    nrOnChangeHandler = (e) => {
        let accountGroupAccount = this.state.accountGroupAccount;
        accountGroupAccount.nr = e.target.value;
        this.setState({accountGroupAccount:accountGroupAccount});
    }

    nameOnChangeHandler = (e) => {
        let accountGroupAccount = this.state.accountGroupAccount;
        accountGroupAccount.name = e.target.value;
        this.setState({accountGroupAccount:accountGroupAccount});
    }

    toggleAccountGroup = () => {
        this.setState({isAccountGroup:!this.state.isAccountGroup});
    }

    addAccountGroupAccountOnClickHandler = () => {
        if(this.state.accountGroupAccount.name !== '' && (this.state.isAccountGroup || this.state.accountGroupAccount.nr !== '')) {
            this.addAccountGroupAccount();
        }else{
            this.props.showMessage(2,'Fehlende oder falsche Werte');
        }
    }

    addAccountGroupAccount = () => {
        if(this.state.isAccountGroup) {
            this.props.addAccountGroup(this.state.accountGroupAccount,this.props.indexArray);
        }else{
            this.props.addAccount(this.state.accountGroupAccount,this.props.indexArray);
        }
    }

    render() {

        let accountGroupText = '';

        if(this.state.isAccountGroup) {
            accountGroupText = 'Kontengruppe'
        }else{
            accountGroupText = 'Konto'
        }

        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.onClose} className='account-group-account-dialog'>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>{this.state.isAccountGroup ? 'Kontengruppe erstellen' : 'Konto erstellen'}</DialogTitle>
                    <DialogContent>
                        <FormControlLabel
                            className='switch'
                            control={
                                <Switch
                                    checked={this.state.isAccountGroup}
                                    onChange={this.toggleAccountGroup}
                                    name="accountGroup"
                                    color="primary"
                                    disabled={this.props.forcedAccountGroup || this.props.forcedAccount}
                                />
                            }
                            label={accountGroupText}
                        />
                        <DialogContentText className='paragraph'>
                            {this.state.isAccountGroup ? null : <TextField value={this.state.accountGroupAccount ? this.state.accountGroupAccount.nr : ''} label='Nummer' className='field' onChange={this.nrOnChangeHandler}/>}
                            <TextField value={this.state.accountGroupAccount ? this.state.accountGroupAccount.name : ''} label='Name' className='field' onChange={this.nameOnChangeHandler}/>
                            <ToolButton disabled={!this.props.isSaveButtonActive} onClick={this.addAccountGroupAccountOnClickHandler} className='button'>Speichern</ToolButton>
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        )
    }
}

export default AccountGroupAccountDialog;