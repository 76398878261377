import React, {Component} from 'react';
import './AdministrationSettings.css';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import api from "../../../../../../../api";

//Components

class AdministrationSettings extends Component {

    state = {
        companySettings:this.props.companySettings
    }

    executionManagementOnChangeHandler = () => {
        let companySettings = this.state.companySettings;
        companySettings.executionManagement = !companySettings.executionManagement;
        this.setState({companySettings:companySettings});
    }

    offerManagementOnChangeHandler = () => {
        let companySettings = this.state.companySettings;
        companySettings.offerManagement = !companySettings.offerManagement;
        this.setState({companySettings:companySettings});
    }

    saveCompanySettingsOnClickHandler = () => {
        this.saveCompanySettings();
    }

    saveCompanySettings = () => {
        const url = '/company/settings';
        api.put(url,this.state.companySettings)
            .then(response => {
                this.setState({companySettings:response.data});
                this.props.showMessage(0,'Unternehmenseinstellungen erfolgreich gespeichert');
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    render() {
        return (
            <div className='administration-settings'>
                <h1>Unternehmenseinstellungen</h1>
                <h2>Bestellwesen</h2>
                <FormGroup>
                    <FormControlLabel className="field checkbox-field" control={<Checkbox className="checkbox" checked={this.state.companySettings ? this.state.companySettings.executionManagement : null} onChange={this.executionManagementOnChangeHandler} />} label="Durchführungsverwaltung" />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel className="field checkbox-field" control={<Checkbox className="checkbox" checked={this.state.companySettings ? this.state.companySettings.offerManagement : null} onChange={this.offerManagementOnChangeHandler} />} label="Angebotsverwaltung" />
                </FormGroup>
                <ToolButton onClick={this.saveCompanySettingsOnClickHandler} main className='save-button'>Speichern</ToolButton>
            </div>
        )
    }
}

export default AdministrationSettings;