import React, { useState, useEffect, useRef } from 'react';
import './BatchDialog.css';

//Components
import { Tooltip } from 'react-tooltip'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import api from "../../../../../../../../api";
import CloseIcon from "@mui/icons-material/Close";
import TransactionItem from "../../../../../../../../reusable/TransactionItem/TransactionItem";
import TransactionDialog from "../../../../../../../../reusable/TransactionDialog/TransactionDialog";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import YesCancelDialog from "../../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import HelperFunctions from "../../../../../../../../reusable/HelperFunctions";

const BatchDialog = (props) => {

    const [batch,setBatch] = useState(null);
    const [article,setArticle] = useState(null);
    const [transactions,setTransactions] = useState(null);
    const [activeTransaction,setActiveTransaction] = useState(null);
    const [transactionDialog,setTransactionDialog] = useState(false);
    const [isSaved,setIsSaved] = useState(false);
    const [unsavedChangesWarningDialog,setUnsavedChangesWarningDialog] = useState(false);

    useEffect(() => {
        setBatch(props.batch);
        setIsSaved(props.batch.id);
        if(props.batch && props.batch.id) {
            loadArticleByBatch(props.batch);
        }
    }, []);

    const filter = createFilterOptions();

    const loadArticleByBatch = (batch) => {
        api.get("/articles/search/batch/" + batch.id)
            .then(response => {
                if(response.data) {
                    setArticle(response.data)
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.setLoading(false);
                }
                props.setLoading(false);
            });
    }

    const nrOnChangeHandler = (e) => {
        let updatedBatch = { ...batch };
        updatedBatch.nr = e.target.value;

        if(e.target.value && e.target.value.startsWith("(01)") && e.target.value.includes("(17)") && e.target.value.includes("(10)")) {
            // UDI detected
            let information = HelperFunctions.getInformationByUDI(e.target.value,props.articles,props.batches);
            if(information) {
                setArticle(information.article);
                updatedBatch = information.batch;
            }
        }

        setBatch(updatedBatch);
        setIsSaved(false);
    }

    const udiOnChangeHandler = (e) => {
        const updatedBatch = { ...batch };
        updatedBatch.udi = e.target.value;
        setBatch(updatedBatch);
        setIsSaved(false);
    }

    const expirationDateOnChangeHandler = (e) => {
        const updatedBatch = { ...batch };
        updatedBatch.expirationDate = e.target.value;
        setBatch(updatedBatch);
        setIsSaved(false);
    }

    const materialOnChangeHandler = (e) => {
        const updatedBatch = { ...batch };
        updatedBatch.material = e.target.value;
        setBatch(updatedBatch);
        setIsSaved(false);
    }

    const saveBatchOnClickHandler = (batch) => {
        if(validateBatch(batch)) {
            if(batch.id) {
                updateBatch(batch);
            }else{
                addBatchToStockTakingInventory(batch);
            }
        }
    }

    const loadTransactionsOnClickHandler = (batch) => {
        props.setLoading(true);
        const url = "/transactions/batch/20";
        api.post(url,batch)
            .then(response => {
                props.setLoading(false);
                setTransactions(response.data);
                if(response.data.length === 0) {
                    props.showMessage(1,"Es wurden keine Transaktionen gefunden");
                }
            })
            .catch(error => {
                props.setLoading(false);
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
            });
    }

    const validateBatch = (batch) => {
        if(!batch.nr) {
            props.showMessage(2,"Jeder Charge muss eine Nummer hinterlegt sein");
            return false;
        }
        if(batch.batchInventories === null || batch.batchInventories.length === 0) {
            props.showMessage(2,"Jeder Charge muss mindestens ein vorhandener Bestand hinterlegt werden");
            return false;
        }
        let sum = 0;
        for(let i = 0; i < batch.batchInventories.length; i++) {
            if(batch.batchInventories[i].inventoryCountFree === null) {
                props.showMessage(2,"Jeder Charge muss ein Lagerbestand hinterlegt sein");
                return false;
            }
            sum = sum + batch.batchInventories[i].inventoryCountFree;
        }
        return true;
    }

    const addBatchToStockTakingInventory = (batch) => {
        props.addBatchToList(batch);
        props.close();
        setIsSaved(true);
    }

    const updateBatch = (batch) => {
        props.setLoading(true);
        const url = "/batches";
        api.put(url,batch)
            .then(response => {
                props.setLoading(false);
                props.updateBatchInList(response.data);
                props.showMessage(0,"Die Änderungen wurden erfolgreich gespeichert");
                setIsSaved(true);
                close();
            })
            .catch(error => {
                props.setLoading(false);
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
            });
    }

    const batchInventoryInventoryCountFreeOnChangeHandler = (e,index) => {
        let updatedBatch = { ...batch };
        updatedBatch.batchInventories[index].inventoryCountFree = e.target.value;
        setBatch(updatedBatch);
        setIsSaved(false);
    }

    const toggleEditBatchInventory = (index) => {
        let updatedBatch = { ...batch };
        updatedBatch.batchInventories[index].edit = !updatedBatch.batchInventories[index].edit;
        setBatch(updatedBatch);
    }

    const showTransactionDialog = (transaction) => {
        setActiveTransaction(transaction);
        setTransactionDialog(true);
    }

    const closeTransactionDialog = () => {
        setTransactionDialog(false);
        setActiveTransaction(null);
    }

    const closeOnClickHandler = () => {
        if(!isSaved) {
            showUnsavedChangesWarningDialog();
        }else{
            close();
        }
    }

    const showUnsavedChangesWarningDialog = () => {
        setUnsavedChangesWarningDialog(true);
    }

    const closeUnsavedChangesWarningDialog = () => {
        setUnsavedChangesWarningDialog(false);
    }

    const close = () => {
        setBatch(null);
        props.close();
    }

    return (
        <Dialog scroll='body' maxWidth={"sm"} open={props.open} onClose={closeOnClickHandler} className='batches-dialog'>
            <Tooltip id="batches-dialog-tooltip"/>

            <YesCancelDialog
                open={unsavedChangesWarningDialog}
                close={closeUnsavedChangesWarningDialog}
                header='Verwerfen'
                text='Ungespeicherte Änderungen gehen verloren. Wollen Sie fortfahren?'
                onClick={close}
            />

            <CloseIcon onClick={closeOnClickHandler} className="icon close-icon"/>
            <DialogTitle>
                Charge {article ? "(" + article.name + ")" : null}
            </DialogTitle>

            {activeTransaction ? <TransactionDialog
                open={transactionDialog}
                close={closeTransactionDialog}
                transaction={activeTransaction}
            /> : null}

            <DialogContent>
                <div>
                    <TextField size="small"
                               value={batch ? batch.nr : ''}
                               InputLabelProps={{shrink: true}}
                               label='Chargennummer' inputProps={{maxLength: 45}} variant="outlined"
                               className='field'
                               onChange={nrOnChangeHandler}/>
                    <TextField size="small"
                               value={batch ? batch.udi : ''}
                               InputLabelProps={{shrink: true}}
                               label='UDI' inputProps={{maxLength: 255}} variant="outlined"
                               className='field'
                               onChange={udiOnChangeHandler}/>
                    <TextField size="small"
                               type="date"
                               value={batch ? batch.expirationDate : ''}
                               InputLabelProps={{shrink: true}}
                               label='Mindesthaltbarkeitsdatum' variant="outlined"
                               className='field'
                               onChange={expirationDateOnChangeHandler}/>
                    <TextField size="small"
                               value={batch ? batch.material : ''}
                               InputLabelProps={{shrink: true}}
                               label='Material' inputProps={{maxLength: 45}} variant="outlined"
                               className='field'
                               onChange={materialOnChangeHandler}/>
                </div>
                <div>
                    {batch && batch.batchInventories ? batch.batchInventories.map((batchInventory,index) => (
                        <Paper className="batch-inventory-item" key={batchInventory.id}>
                            <EditIcon onClick={() => toggleEditBatchInventory(index)} className="icon edit-icon"/>
                            {batchInventory.edit ? <p>{batchInventory.inventory && batchInventory.inventory.location ? batchInventory.inventory.location.name + ": " : props.user.assignedLocation.name} <input className="small-input-field" value={batchInventory.inventoryCountFree} onChange={(e) => batchInventoryInventoryCountFreeOnChangeHandler(e,index)}/> vorhanden</p> :
                                <p>{batchInventory.inventory && batchInventory.inventory.location ? batchInventory.inventory.location.name + ": " + batchInventory.inventoryCountFree + " vorhanden" : props.user.assignedLocation.name + ": " + batchInventory.inventoryCountFree + " vorhanden"}</p>}
                        </Paper>
                    )) : null}
                </div>
                {transactions ? <div>
                    <h3>Transaktionen</h3>
                    <div className="transactions-holder">
                        {transactions.map(transaction => (
                            <TransactionItem
                                key={transaction.id}
                                transaction={transaction}
                                showTransactionDialog={showTransactionDialog}
                                small={true}
                            />
                        ))}
                    </div>
                </div> : null}
            </DialogContent>

            <DialogActions>
                <ToolButton className='button load-button' onClick={() => loadTransactionsOnClickHandler(batch)}>Transaktionen laden (letzten 20)</ToolButton>
                <ToolButton className='button save-button' main onClick={() => saveBatchOnClickHandler(batch)}>{batch && batch.id ? "Speichern" : "Hinzufügen"}</ToolButton>
            </DialogActions>
        </Dialog>
    )
}

export default BatchDialog;