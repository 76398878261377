import React, {Component} from "react";
import './PartsListDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import DocumentPosition from "../../../../../../../../reusable/DocumentPosition/DocumentPosition";
import api from "../../../../../../../../api";
import CloseIcon from "@mui/icons-material/Close";
import BusinessIcon from '@mui/icons-material/Business';
import { Tooltip } from 'react-tooltip'

class PartsListDialog extends Component {

    state = {
        partsList: this.props.partsList
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({partsList:this.props.partsList});
        }
    }

    bookPartsListTransactionsOnClickHandler = () => {
        let checked = true;
        if(this.state.partsList.components.length > 0
            && this.state.partsList.results.length > 0
            && this.state.partsList.name !== '') {
            for(let i = 0; i < this.state.partsList.components.length; i++) {
                if(this.state.partsList.components[i].article.nr === ''
                    || this.state.partsList.components.inventoryCount === ''
                ) {
                    checked = false;
                }
            }
            for(let i = 0; i < this.state.partsList.results.length; i++) {
                if(this.state.partsList.results[i].article.nr === ''
                    || this.state.partsList.results.inventoryCount === ''
                ) {
                    checked = false;
                }
            }
        }else{
            checked = false;
        }
        if(checked) {
            this.props.bookPartsListTransactions(this.state.partsList);
        }else{
            this.props.showMessage(2,'Bitte füllen Sie alle Felder aus');
        }
    }

    //Components

    addComponentPosition = () => {
        let partsList = this.state.partsList;
        partsList.components.push({
            article:{
                nr:'',
                name:''
            },
            inventoryCount:''
        });
        this.props.setIsSaved(false);
        this.setState({partsList:partsList});
    }

    //Results

    addResultPosition = () => {
        let partsList = this.state.partsList;
        partsList.results.push({
            article:{
                nr:'',
                name:''
            },
            inventoryCount:''
        });
        this.props.setIsSaved(false);
        this.setState({partsList:partsList});
    }

    checkResultsArticleByArticleNr = (nr,index) => {
        for (let i = 0; i < this.props.articles.length; i++) {
            if (this.props.articles[i].nr === nr) {
                const url = '/articles/' + nr;
                api.get(url)
                    .then(response => {
                        let partsList = this.state.partsList;
                        partsList.results[index].article = response.data;
                        this.setState({partsList:partsList});
                    })
                    .catch(error => {
                    });
                return;
            }else{
                let partsList = this.state.partsList;
                partsList.results[index].article = {
                    article:{
                        nr:''
                    },
                    inventoryCount:''
                };
            }
        }
    }

    resultPositionCountOnChange = (e, index) => {
        let partsList = this.state.partsList;
        partsList.results[index].inventoryCount = e.target.value;
        this.props.setIsSaved(false);
        this.setState({partsList: partsList});
    };

    clearFields = () => {
        let partsList = {
            name:'',
            components:[
                {
                    article:{
                        nr:'',
                        name:''
                    },
                    inventoryCount:''
                }
            ],
            results:[
                {
                    article:{
                        nr:'',
                        name:''
                    },
                    inventoryCount:''
                }
            ]
        }
        this.setState({partsList:partsList});
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='parts-list-dialog'>
                <Tooltip id="parts-list-dialog-tooltip"/>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>Stückliste</DialogTitle>
                    {this.props.partsList && this.props.partsList.id ? <div>
                            <BusinessIcon onClick={this.bookPartsListTransactionsOnClickHandler} data-tooltip-id="parts-list-dialog-tooltip" data-tooltip-place="top" data-tooltip-content='Transaktionen verbuchen' className='icon'/>
                        </div>
                        :
                        <div>
                            <BusinessIcon data-tooltip-id="parts-list-dialog-tooltip" data-tooltip-place="top" data-tooltip-content='Sie müssen speichern, um die Transaktionen verbuchen zu können' className='inactive icon'/>
                        </div>}
                    <TextField size="small" value={this.state.partsList ? this.state.partsList.name : ''} label='Name' className='parts-list-name' onChange={this.props.nameOnChange}/>
                    <div className='components-results-surrounder'>
                        <div className='components'>
                            <p>Komponenten</p>
                            {this.props.partsList ? this.props.partsList.components.map((component,index) => (
                                <DocumentPosition
                                    key={index}
                                    index={index}
                                    type={3}
                                    documentPosition={component}
                                    articleOnChange={this.props.componentOnChange}
                                    inventoryCountOnChange={this.props.componentInventoryCountOnChange}
                                    removeOrderPosition={this.props.removeComponent}
                                    articles={this.props.articles}
                                />
                            )) : null}
                            <ToolButton className='add-attribute-button' onClick={this.addComponentPosition}>+</ToolButton>
                        </div>
                        <div className='results'>
                            <p>Ergebnisse</p>
                            {this.props.partsList ? this.props.partsList.results.map((result,index) => (
                                <DocumentPosition
                                    key={index}
                                    index={index}
                                    type={3}
                                    documentPosition={result}
                                    articleOnChange={this.props.resultOnChange}
                                    inventoryCountOnChange={this.props.resultInventoryCountOnChange}
                                    removeOrderPosition={this.removeResult}
                                    articles={this.props.articles}
                                />
                            )) : null}
                            <ToolButton className='add-attribute-button' onClick={this.addResultPosition}>+</ToolButton>
                        </div>
                    </div>
                    <ToolButton main className='button' onClick={this.props.savePartsListOnClickHandler}>Speichern</ToolButton>
                </div>
            </Dialog>
        )
    }
}

export default PartsListDialog;