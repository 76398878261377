import React, {Component} from 'react';
import './UserItem.css';
import Paper from '@mui/material/Paper';

//Components
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

class UserItem extends Component {

    state = {
        folded:true,
        masterPassword:'',
        password:'',
        checkPassword:'',
        assignedLocation:null
    }

    //On Change Handler

    toggleContent = () => {
        this.setState({folded:!this.state.folded});
    }

    masterPasswordOnChangeHandler = (e) => {
        this.setState({masterPassword:e.target.value})
    }

    passwordOnChangeHandler = (e) => {
        this.setState({password:e.target.value});
    }

    checkPasswordOnChangeHandler = (e) => {
        this.setState({checkPassword:e.target.value});
    }

    assignedLocationOnChangeHandler = (e) => {
        let assignedLocation = {
            id:e.target.value
        }
        this.setState({assignedLocation:assignedLocation});
    }

    //AccountingActions

    changePasswordOnClickHandler = () => {
        if(this.state.masterPassword !== '' && this.state.password !== '' && this.state.checkPassword !== '') {
            if(this.state.password === this.state.checkPassword) {
                let user = this.props.user;
                user.password = this.state.password;
                this.props.changePassword(this.state.masterPassword,user);
            }else{
                this.props.showMessage(2,'Das wiederholte Passwort entspricht nicht dem neuen Passwort');
            }
        }else{
            this.props.showMessage(2,'Bitte alle Felder ausfüllen');
        }
    }

    changeAssignedLocationOnClickHandler = () => {
        if(this.state.assignedLocation !== null) {
            this.props.changeAssignedLocation(this.props.user,this.state.assignedLocation);
        }else{
            this.props.showMessage(2,'Bitte alle Felder ausfüllen');
        }
    }

    render() {

        let userContent = this.state.folded ?
            null :
            <div className='user-content'>

                <TextField size="small" className='field' placeholder='Master Passwort' value={this.state.masterPassword} type='password' onChange={this.masterPasswordOnChangeHandler} />
                <TextField size="small" className='field' placeholder='Neues Passwort' value={this.state.password} type='password' onChange={this.passwordOnChangeHandler} />
                <TextField size="small" className='field' placeholder='Passwort wiederholen' value={this.state.checkPassword} type='password' onChange={this.checkPasswordOnChangeHandler} />
                <SaveIcon onClick={this.changePasswordOnClickHandler} className='save-icon field' />

                <hr/>

                <TextField size="small" className='field' value={this.props.user.assignedLocation ? this.props.user.assignedLocation.id : ''} select helperText='Standort auswählen' onChange={this.assignedLocationOnChangeHandler}>
                    {this.props.locations.map(location => (
                            <MenuItem key={location.id} value={location.id}>
                                {location.name}
                            </MenuItem>
                        )
                    )}
                </TextField>
                <SaveIcon onClick={this.changeAssignedLocationOnClickHandler} className='save-icon field' />
            </div>

        return (
            <Paper elevation={3} className='user-item'>
                <div className='user-header'>
                    {this.state.folded ?
                        <KeyboardArrowRightIcon onClick={this.toggleContent} className='arrow-icon'/> :
                        <KeyboardArrowDownIcon onClick={this.toggleContent} className='arrow-icon' />}
                    <h2 className='username'>{this.props.user.username}</h2>
                    {this.props.user.masterUser ? <DeleteIcon className='delete-icon-disabled' /> : <DeleteIcon onClick={this.props.deleteUser} className='delete-icon' />}
                </div>
                {userContent}
            </Paper>
        )
    }
}

export default UserItem;