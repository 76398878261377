import React, {Component} from 'react';
import './Accounting.css';

//Components
import AccountGroupItem from "./AccountGroupItem/AccountGroupItem";
import { Tooltip } from 'react-tooltip'
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import InfoIcon from "@mui/icons-material/Info";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccountingInfoDialog from "./AccountingInfoDialog/AccountingInfoDialog";
import AccountGroupAccountDialog from "./AccountGroupAccountDialog/AccountGroupAccountDialog";
import YesCancelDialog from "../../../../../../reusable/YesCancelDialog/YesCancelDialog";

class Accounting extends Component {

    state = {
        edit:false,
        activeAccountGroupAccount:null,
        isNewItem:true,
        forcedAccountGroup:false,
        forcedAccount:false,
        accountPlan:this.props.accountPlan,
        indexArray:[],
        deleteIndex:null,
        formattedItems:[],

        //Dialog actions

        showDeleteAccountGroupDialog:false,
        showDeleteAccountDialog:false,
        showAccountGroupAccountDialog:false,
        showAccountingInfoDialog:false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({accountPlan:this.props.accountPlan});
        }
    }

    toggleEdit = () => {
        if(this.state.edit) {
            this.props.saveAccountPlan(this.state.accountPlan);
        }
        this.setState({edit:!this.state.edit});
    }

    addAccountGroup = (newAccountGroup,indexArray) => {
        let accountPlan = this.state.accountPlan;
        let currentAccountGroup = accountPlan;
        for(let i = 0; i < indexArray.length; i++) {
            let index = indexArray[i];
            currentAccountGroup = currentAccountGroup.accountGroups[index];
        }
        currentAccountGroup.accountGroups.push(newAccountGroup);
        this.closeAccountGroupAccountDialog();
    }

    addAccount = (newAccount,indexArray) => {
        let accountPlan = this.state.accountPlan;
        let currentAccountGroup = accountPlan;
        for(let i = 0; i < indexArray.length; i++) {
            let index = indexArray[i];
            currentAccountGroup = currentAccountGroup.accountGroups[index];
        }
        currentAccountGroup.accounts.push(newAccount);
        this.closeAccountGroupAccountDialog();
    }

    deleteAccountGroup = () => {
        let accountPlan = this.state.accountPlan;
        let currentAccountGroup = accountPlan;
        let indexArray = this.state.indexArray;
        for(let i = 0; i < indexArray.length; i++) {
            let index = indexArray[i];
            if(i === indexArray.length - 1) {
                for(let j = 0; j < currentAccountGroup.accountGroups.length; j++) {
                    if(currentAccountGroup.accountGroups[j].id === this.state.activeAccountGroupAccount.id) {
                        currentAccountGroup.accountGroups.splice(j,1);
                        break;
                    }
                }
            }else{
                currentAccountGroup = currentAccountGroup.accountGroups[index];
            }
        }
        this.setState({accountPlan:accountPlan});
        this.closeDeleteAccountDialog();
    }

    deleteAccount = () => {
        let accountPlan = this.state.accountPlan;
        let currentAccountGroup = accountPlan;
        let indexArray = this.state.indexArray;
        for(let i = 0; i < indexArray.length; i++) {
            let index = indexArray[i];
            if(i === indexArray.length - 1) {
                for(let j = 0; j < currentAccountGroup.accounts.length; j++) {
                    if(currentAccountGroup.accounts[j].id === this.state.activeAccountGroupAccount.id) {
                        currentAccountGroup.accounts.splice(j,1);
                        break;
                    }
                }
            }else{
                currentAccountGroup = currentAccountGroup.accountGroups[index];
            }
        }
        this.setState({accountPlan:accountPlan});
        this.closeDeleteAccountDialog();
    }

    removeEntryFromList = (entryId,account) => {
        account.entries.splice()
        for(let i = 0; i < account.entries.length; i++) {
            if(entryId === account.entries[i].id) {
                account.entries.splice(i,1);
            }
        }
    }

    //Dialog actions

    showAccountingInfoDialog = () => {
        this.setState({showAccountingInfoDialog:true});
    }

    closeAccountingInfoDialog = () => {
        this.setState({showAccountingInfoDialog:false});
    }

    showAccountGroupAccountDialog = (accountGroupAccount,isNewItem,forcedAccountGroup,forcedAccount,indexArray) => {
        this.setState({isSaveButtonActive:true,activeAccountGroupAccount:accountGroupAccount,isNewItem:isNewItem,forcedAccountGroup:forcedAccountGroup,forcedAccount:forcedAccount,indexArray:indexArray,showAccountGroupAccountDialog:true});
    }

    closeAccountGroupAccountDialog = () => {
        this.setState({showAccountGroupAccountDialog:false});
    }

    showDeleteAccountGroupDialog = (activeAccountGroup,indexArray) => {
        this.setState({activeAccountGroupAccount:activeAccountGroup,indexArray:indexArray,showDeleteAccountGroupDialog:true});
    }

    closeDeleteAccountGroupDialog = () => {
        this.setState({showDeleteAccountGroupDialog:false});
    }

    showDeleteAccountDialog = (activeAccount,indexArray) => {
        this.setState({activeAccountGroupAccount:activeAccount,indexArray:indexArray,showDeleteAccountDialog:true});
    }

    closeDeleteAccountDialog = () => {
        this.setState({showDeleteAccountDialog:false});
    }

    render() {

        let blankAccountGroupAccount = {
            name:'',
            accountGroups:[],
            accounts:[],
            entries:[]
        }

        let editText = null;
        if(this.state.edit) {
            editText = "Änderungen speichern"
        }else{
            editText = "Bearbeitung aktivieren"
        }

        return (
            <div className='accounting'>
                <Tooltip id="accounting-tooltip"/>
                <InfoIcon onClick={this.showAccountingInfoDialog} data-tooltip-id="accounting-tooltip" data-tooltip-place="top" data-tooltip-content='Buchhaltung Info' className='icon info-icon'/>

                <AccountingInfoDialog
                    open={this.state.showAccountingInfoDialog}
                    close={this.closeAccountingInfoDialog}
                    showMessage={this.props.showMessage}
                />

                <AccountGroupAccountDialog
                    open={this.state.showAccountGroupAccountDialog}
                    close={this.closeAccountGroupAccountDialog}
                    showMessage={this.props.showMessage}
                    accountGroupAccount={this.state.activeAccountGroupAccount}
                    indexArray={this.state.indexArray}
                    addAccountGroup={this.addAccountGroup}
                    addAccount={this.addAccount}
                    forcedAccountGroup={this.state.forcedAccountGroup}
                    forcedAccount={this.state.forcedAccount}
                    isSaveButtonActive={this.state.isSaveButtonActive}
                />

                <YesCancelDialog
                    open={this.state.showDeleteAccountGroupDialog}
                    close={this.closeDeleteAccountGroupDialog}
                    indexArray={this.state.indexArray}
                    activeAccountGroupAccount={this.state.activeAccountGroupAccount}
                    showMessage={this.props.showMessage}
                    header={'Löschen'}
                    text={'Wollen Sie die Kontengruppe wirklich löschen?'}
                    onClick={this.deleteAccountGroup}
                />

                <YesCancelDialog
                    open={this.state.showDeleteAccountDialog}
                    close={this.closeDeleteAccountDialog}
                    indexArray={this.state.indexArray}
                    activeAccountGroupAccount={this.state.activeAccountGroupAccount}
                    showMessage={this.props.showMessage}
                    header={'Löschen'}
                    text={'Wollen Sie das Konto wirklich löschen?'}
                    onClick={this.deleteAccount}
                />

                <h1>Buchhaltung</h1>

                <FormControlLabel
                    className='switch'
                    control={
                        <Switch
                            checked={this.state.edit}
                            onChange={this.toggleEdit}
                            name="edit"
                            color="primary"
                        />
                    }
                    label={editText}
                />
                <div className='account-tree'>
                    {this.state.accountPlan.accountGroups ? this.state.accountPlan.accountGroups.map((item,index) => (
                            <AccountGroupItem
                                key={index}
                                showMessage={this.props.showMessage}
                                item={item}
                                index={index}
                                indexArray={[index]}
                                edit={this.state.edit}
                                showAccountGroupAccountDialog={this.showAccountGroupAccountDialog}
                                showDeleteAccountGroupDialog={this.showDeleteAccountGroupDialog}
                                showDeleteAccountDialog={this.showDeleteAccountDialog}
                                blankAccountGroupAccount={blankAccountGroupAccount}
                                addEntryToList={this.addEntryToList}
                                removeEntryFromList={this.removeEntryFromList}
                                moveAccountGroupUp={this.moveAccountGroupUp}
                                moveAccountGroupDown={this.moveAccountGroupDown}
                            />
                        )) : null}
                    {this.state.edit ? <ToolButton onClick={() => this.showAccountGroupAccountDialog(blankAccountGroupAccount,true,true,false,[])} className='add-group-button'>+</ToolButton> : null}
                </div>
            </div>
        )
    }
}

export default Accounting;