import React, { useState, useEffect, useRef } from 'react';
import './AutomatedCommunicationSettingsDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import { Tooltip } from 'react-tooltip'
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import api from "../../../../../../../../api";
import Paper from "@mui/material/Paper";
import ToolFab from "../../../../../../../../reusable/ToolFab/ToolFab";
import AddIcon from "@mui/icons-material/Add";
import AutomatedCommunicationSettingDialog
    from "../AutomatedCommunicationSettingDialog/AutomatedCommunicationSettingDialog";
import Switch from "@mui/material/Switch";

const AutomatedCommunicationSettingsDialog = (props) => {

    const [automatedCommunicationSetting,setAutomatedCommunicationSetting] = useState(null);
    const [automatedCommunicationSettingDialog,setAutomatedCommunicationSettingDialog] = useState(false);
    const [automatedCommunicationSettings,setAutomatedCommunicationSettings] = useState(null);

    useEffect(() => {
        loadAutomatedCommunicationSettings();
    }, []);

    const loadAutomatedCommunicationSettings = () => {
        props.setLoading(true);
        api.get("/automated-communications/settings")
            .then(response => {
                setAutomatedCommunicationSettings(response.data);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const blankAutomatedCommunication = {
        days:14,
        active:false
    }

    const addAutomatedCommunicationSettingToList = (automatedCommunicationSetting) => {
        let updatedAutomatedCommunicationSettings = [ ...automatedCommunicationSettings ];
        updatedAutomatedCommunicationSettings.push(automatedCommunicationSetting);
        setAutomatedCommunicationSettings(updatedAutomatedCommunicationSettings);
    }

    const updateAutomatedCommunicationSettingInList = (automatedCommunicationSetting) => {
        let updatedAutomatedCommunicationSettings = [ ...automatedCommunicationSettings ];
        for(let i = 0; i < updatedAutomatedCommunicationSettings.length; i++) {
            if(updatedAutomatedCommunicationSettings[i].id === automatedCommunicationSetting.id) {
                updatedAutomatedCommunicationSettings[i] = automatedCommunicationSetting;
                break;
            }
        }
        setAutomatedCommunicationSettings(updatedAutomatedCommunicationSettings);
    }

    const removeAutomatedCommunicationSettingFromList = (automatedCommunicationSetting) => {
        let updatedAutomatedCommunicationSettings = [ ...automatedCommunicationSettings ];
        for(let i = 0; i < updatedAutomatedCommunicationSettings.length; i++) {
            if (updatedAutomatedCommunicationSettings[i].id === updatedAutomatedCommunicationSettings.id) {
                updatedAutomatedCommunicationSettings.splice(i,1)
                setAutomatedCommunicationSettings(updatedAutomatedCommunicationSettings);
                return;
            }
        }
    }

    const toggleActive = (e,index) => {
        e.stopPropagation();
        props.setLoading(true);
        let updatedAutomatedCommunicationSetting = automatedCommunicationSettings[index];
        updatedAutomatedCommunicationSetting.active = !updatedAutomatedCommunicationSetting.active;
        api.put("/automated-communications/settings",updatedAutomatedCommunicationSetting)
            .then(response => {
                updateAutomatedCommunicationSettingInList(response.data);
                props.setLoading(false);
                if(response.data.active) {
                    props.showMessage(0,"Die automatisierte Aktion wurde aktiviert und wird ab sofort ausgeführt.");
                }else{
                    props.showMessage(0,"Die automatisierte Aktion wurde deaktiviert und wird ab sofort nicht mehr ausgeführt.");
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const showAutomatedCommunicationSettingDialog = (automatedCommunicationSetting) => {
        setAutomatedCommunicationSetting(automatedCommunicationSetting);
        setAutomatedCommunicationSettingDialog(true);
    }

    const closeAutomatedCommunicationSettingDialog = () => {
        setAutomatedCommunicationSettingDialog(false);
        setAutomatedCommunicationSetting(null);
    }

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={props.close} className='automated-communication-settings-dialog'>

            <Tooltip id="automated-communication-settings-dialog-tooltip"/>

            {automatedCommunicationSettingDialog ? <AutomatedCommunicationSettingDialog
                open={automatedCommunicationSettingDialog}
                close={closeAutomatedCommunicationSettingDialog}
                showMessage={props.showMessage}
                setLoading={props.setLoading}
                logout={props.logout}
                automatedCommunicationSetting={automatedCommunicationSetting}
                addAutomatedCommunicationSettingToList={addAutomatedCommunicationSettingToList}
                updateAutomatedCommunicationSettingInList={updateAutomatedCommunicationSettingInList}
                removeAutomatedCommunicationSettingFromList={removeAutomatedCommunicationSettingFromList}
            /> : null}

            <div className='surrounder'>
                <DialogTitle>Automatisierte Aktionen</DialogTitle>
                <DialogContent>
                    {automatedCommunicationSettings ? automatedCommunicationSettings.map((automatedCommunicationSetting,index) => (
                        <Paper elevation={3} className="automated-communication-setting" onClick={() => showAutomatedCommunicationSettingDialog(automatedCommunicationSetting)}>
                            {automatedCommunicationSetting.active ? <p className="active-label" style={{color:'lightgreen'}}><b>Aktiv</b></p> :
                                <p className="active-label" style={{color:'lightcoral'}}><b>Inaktiv</b></p>}
                            <p>
                            {automatedCommunicationSetting.kind === "MINIMUM_STOCK_SHORTFALL" ? " Inventar: Mindestbestands-Unterschreitung" : null}
                                {automatedCommunicationSetting.kind === "CUSTOMER_OFFER_REMINDER" ? " Kundenbestellung: Angebots-Erinnerung" : null}
                                {automatedCommunicationSetting.kind === "SUPPLIER_ORDER_CONFIRMATION_REMINDER" ? " Lieferantenbestellung: Auftragsbestätungs-Erinnerung" : null}
                                {automatedCommunicationSetting.kind === "SUPPLIER_OFFER_REMINDER" ? " Lieferantenbestellung: Angebots-Erinnerung" : null} nach {automatedCommunicationSetting.days} Tagen
                            </p>
                        </Paper>
                    )) : null}
                </DialogContent>
                <DialogActions>
                    <ToolFab className='add-fab' data-tooltip-id="automated-communication-tooltip" data-tooltip-place="top"
                             data-tooltip-content="Neuen Auslöser anlegen"
                             onClick={() => showAutomatedCommunicationSettingDialog(blankAutomatedCommunication)}>
                        <AddIcon/>
                    </ToolFab>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default AutomatedCommunicationSettingsDialog;