import React, {Component} from 'react';
import './LoadingScreen.css';

//Components
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

class LoadingScreen extends Component {

    render() {
        return (
            <Box className="loading-screen">
                <CircularProgress className='load-icon'/>
            </Box>
        )
    }
}

export default LoadingScreen;