import React, {Component} from "react";
import './PDFDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import Page from "./PDFActionsComponent/Page/Page";
import CircularProgress from '@mui/material/CircularProgress';
import {prepareAssets} from "./PDFActionsComponent/utils/prepareAssets";
import CloseIcon from '@mui/icons-material/Close';

prepareAssets();

class PDFDialog extends Component {
    render() {
        return (
            <Dialog id="test-id" scroll="body" maxWidth={"xl"} open={this.props.open} onClose={this.props.close} className='pdf-dialog'>
                <div className='surrounder'>
                    {this.props.pdfFileURL && this.props.pdfFile ?
                        <Page
                            pdfFile={this.props.pdfFile}
                            pdfFileURL={this.props.pdfFileURL}
                            showMessage={this.props.showMessage}
                            attachPdf={this.props.attachPdf}
                            updatePdf={this.props.updatePdf}
                        />
                        : <div className='loading-div'>
                            <div className='loading-holder-div'>
                                <CircularProgress/>
                            </div>
                        </div>}
                    <CloseIcon className="icon close-icon" onClick={this.props.close}/>
                </div>
            </Dialog>
        )
    }
}

export default PDFDialog;