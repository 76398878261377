import React, { useState, useEffect, useRef } from 'react';
import './ExportFilesDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import api from "../../../../../../../../api";

const ExportFilesDialog = (props) => {

    const [exportFilesRequest, setExportFilesRequest] = useState(null);

    const fileStorageLocationKindOnChangeHandler = (e) => {
        const updatedExportFilesRequest = { ...exportFilesRequest, fileStorageLocationKind: e.target.value };
        setExportFilesRequest(updatedExportFilesRequest);
    }

    const fromDateOnChangeHandler = (e) => {
        const updatedExportFilesRequest = { ...exportFilesRequest, fromDate: e.target.value };
        setExportFilesRequest(updatedExportFilesRequest);
    }

    const toDateOnChangeHandler = (e) => {
        const updatedExportFilesRequest = { ...exportFilesRequest, toDate: e.target.value };
        setExportFilesRequest(updatedExportFilesRequest);
    }

    const exportFilesOnClickHandler = () => {
        props.setLoading(true);
        let url = "/file-storage/export";
        api.post(url,exportFilesRequest,{responseType:'blob'})
            .then(response => {
                props.setLoading(false);

                // Create a URL for the blob
                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);

                // Create a link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'files.zip'); // Set the download attribute with a file name
                document.body.appendChild(link);

                // Programmatically trigger a click on the link to start the download
                link.click();

                // Clean up and revoke the object URL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                if(error.response && error.response.data && error.response.data.message) {
                    props.showMessage(2,error.response.data.message);
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
                props.setLoading(false);
            });
    }

    const close = () => {
        setExportFilesRequest(null);
        props.close();
    }

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={close} className='export-files-dialog'>
            <div className='surrounder'>
                <CloseIcon onClick={props.close} className='icon close-icon'/>
                <DialogTitle>Dokumente exportieren</DialogTitle>
                <TextField size="small" value={exportFilesRequest ? exportFilesRequest.fromDate : ''} onChange={fromDateOnChangeHandler} className='field' label='von' type='date' InputLabelProps={{shrink: true}}/>
                <TextField size="small" value={exportFilesRequest ? exportFilesRequest.toDate : ''} onChange={toDateOnChangeHandler} className='field' label='bis' type='date' InputLabelProps={{shrink: true}}/>
                <TextField size="small" value={exportFilesRequest ? exportFilesRequest.fileStorageLocationKind : ''} onChange={fileStorageLocationKindOnChangeHandler} select className='field' label='Dokumentenart' InputLabelProps={{shrink: true}}>
                    <MenuItem key={0} value="OFFER">Angebot</MenuItem>
                    <MenuItem key={1} value="ORDER_CONFIRMATION">Auftragsbestätigung</MenuItem>
                    <MenuItem key={2} value="DELIVERY_BILL">Lieferschein</MenuItem>
                    <MenuItem key={3} value="INVOICE">Rechnung</MenuItem>
                    <MenuItem key={4} value="CANCELLATION_INVOICE">Stornorechnung</MenuItem>
                </TextField>
                <DialogActions>
                    <ToolButton main className='button' onClick={exportFilesOnClickHandler}>
                        Exportieren
                    </ToolButton>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default ExportFilesDialog;