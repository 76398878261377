import React, {useEffect, useState} from "react";
import './AutomatedCommunication.css';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ToolFab from "../../../../../../../reusable/ToolFab/ToolFab";
import {Tooltip} from "react-tooltip";
import AutomatedCommunicationDialog from "./AutomatedCommunicationDialog/AutomatedCommunicationDialog";
import AddIcon from "@mui/icons-material/Add";
import AutomatedCommunicationItem from "./AutomatedCommunicatinItem/AutomatedCommunicationItem";
import api from "../../../../../../../api";
import YesCancelDialog from "../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";

//Components
import SettingsIcon from '@mui/icons-material/Settings';
import AutomatedCommunicationSettingsDialog
    from "./AutomatedCommunicationSettingsDialog/AutomatedCommunicationSettingsDialog";
import PDFDialog from "../../../../../../../reusable/PDFEditorDialog/PDFDialog";

const AutomatedCommunication = (props) => {

    const [automatedCommunications,setAutomatedCommunications] = useState(null);
    const [activeAutomatedCommunication,setActiveAutomatedCommunication] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfFileURL, setPdfFileURL] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const [pdfDialog, setPDFDialog] = useState(false);
    const [automatedCommunicationDialog,setAutomatedCommunicationDialog] = useState(false);
    const [executeAutomatedCommunicationDialog,setExecuteAutomatedCommunicationDialog] = useState(false);
    const [deleteAutomatedCommunicationDialog,setDeleteAutomatedCommunicationDialog] = useState(false);
    const [settingsDialog,setSettingsDialog] = useState(false);

    useEffect(() => {
        loadAutomatedCommunications();
    }, []);

    const removeAutomatedCommunicationFromList = (automatedCommunication) => {
        let updatedAutomatedCommunications = [ ...automatedCommunications ];
        for(let i = 0; i < updatedAutomatedCommunications.length; i++) {
            if (updatedAutomatedCommunications[i].id === automatedCommunication.id) {
                updatedAutomatedCommunications.splice(i,1);
                setAutomatedCommunications(updatedAutomatedCommunications);
                return;
            }
        }
    }

    const loadAutomatedCommunications = () => {
        props.setLoading(true);
        let url = "/automated-communications";
        api.get(url)
            .then(response => {
                setAutomatedCommunications(response.data);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const executeAutomatedCommunication = (automatedCommunication) => {
        props.setLoading(true);
        let url = "/automated-communications/execute";
        api.post(url,automatedCommunication)
            .then(response => {
                removeAutomatedCommunicationFromList(automatedCommunication);
                props.showMessage(0,"Die Automatisierte Kommunikation wurde erfolgreich gelöscht.");
                props.setLoading(false);
                closeAutomatedCommunicationDialog();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const deleteAutomatedCommunication = (automatedCommunication) => {
        let url = "/automated-communications/" + automatedCommunication.id;
        api.delete(url)
            .then(response => {
                removeAutomatedCommunicationFromList(automatedCommunication);
                props.showMessage(0,"Die Automatisierte Kommunikation wurde erfolgreich gelöscht.");
                props.setLoading(false);
                closeAutomatedCommunicationDialog();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const test = () => {
        let url = "/automated-communications/debug";
        api.post(url)
            .then(response => {

            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const blankAutomatedCommunication = {

    }

    const showAutomatedCommunicationDialog = (automatedCommunication) => {
        setActiveAutomatedCommunication(automatedCommunication);
        setAutomatedCommunicationDialog(true);
    }

    const closeAutomatedCommunicationDialog = () => {
        setAutomatedCommunicationDialog(false);
        setActiveAutomatedCommunication(null);
    }

    const showDeleteAutomatedCommunicationDialog = (automatedCommunication) => {
        setActiveAutomatedCommunication(automatedCommunication);
        setDeleteAutomatedCommunicationDialog(true);
    }

    const closeDeleteAutomatedCommunicationDialog = () => {
        setDeleteAutomatedCommunicationDialog(false);
    }

    const showExecuteAutomatedCommunicationDialog = (automatedCommunication) => {
        setActiveAutomatedCommunication(automatedCommunication);
        setExecuteAutomatedCommunicationDialog(true);
    }

    const closeExecuteAutomatedCommunicationDialog = () => {
        setExecuteAutomatedCommunicationDialog(false);
    }

    const showSettingsDialog = () => {
        setSettingsDialog(true);
    }

    const closeSettingsDialog = () => {
        setSettingsDialog(false);
    }

    const showPDFDialog = (fileStorageLocation) => {
        props.setLoading(true);
        const url = '/file-storage';
        api.post(url,fileStorageLocation,{responseType:'blob'})
            .then(response => {
                const pdfFile = new Blob(
                    [response.data],
                    {type: response.data.type});
                pdfFile.name = fileStorageLocation.name;
                const pdfFileURL = URL.createObjectURL(pdfFile);
                setPdfFile(pdfFile);
                setPdfFileURL(pdfFileURL);
                setPDFDialog(true);
                props.setLoading(false);
            })
            .catch(error => {
                props.showMessage(2,"Etwas ist schiefgelaufen");
                console.log(error);
                props.setLoading(false);
            });
    }

    const closePDFDialog = () => {
        setFileURL(null);
        setPDFDialog(false);
    }

    return (
        <div className='automated-communication'>
            <Tooltip id="automated-communication-tooltip"/>

            <PDFDialog
                open={pdfDialog}
                close={closePDFDialog}
                showMessage={props.showMessage}
                pdfFile={pdfFile}
                pdfFileURL={pdfFileURL}
            />

            <YesCancelDialog
                open={executeAutomatedCommunicationDialog}
                close={closeExecuteAutomatedCommunicationDialog}
                header='Automatisierte Kommunikation ausführen'
                text='Wollen Sie die automatisierte Kommunikation ausführen? Sie wird danach gelöscht.'
                onClick={() => executeAutomatedCommunication(activeAutomatedCommunication)}
            />

            <YesCancelDialog
                open={deleteAutomatedCommunicationDialog}
                close={closeDeleteAutomatedCommunicationDialog}
                header='Automatisierte Kommunikation löschen'
                text='Wollen Sie die automatisierte Kommunikation wirklich löschen?'
                onClick={() => deleteAutomatedCommunication(activeAutomatedCommunication)}
            />

            {settingsDialog ? <AutomatedCommunicationSettingsDialog
                open={settingsDialog}
                close={closeSettingsDialog}
                logout={props.logout}
                showMessage={props.showMessage}
                setLoading={props.setLoading}
            /> : null}

            {automatedCommunicationDialog && activeAutomatedCommunication ? <AutomatedCommunicationDialog
                open={automatedCommunicationDialog}
                close={closeAutomatedCommunicationDialog}
                showMessage={props.showMessage}
                logout={props.logout}
                automatedCommunication={activeAutomatedCommunication}
                setLoading={props.setLoading}
                automatedCommunications={props.automatedCommunications}
                addAutomatedCommunicationToList={props.addAutomatedCommunicationToList}
                updateAutomatedCommunicationInList={props.updateAutomatedCommunicationInList}
                removeAutomatedCommunicationFromList={props.removeAutomatedCommunicationFromList}
                showExecuteAutomatedCommunicationDialog={showExecuteAutomatedCommunicationDialog}
                showDeleteAutomatedCommunicationDialog={showDeleteAutomatedCommunicationDialog}
                showPDFDialog={showPDFDialog}
            /> : null}

            <SettingsIcon className="icon settings-icon" onClick={showSettingsDialog}/>

            <h1>Automatisierte Kommunikation</h1>
            <p className="info-text">
                Die Panthera Software Solutions GmbH übernimmt keine Gewähr für Erinnerungen sowie versandte oder nicht versandte E-Mails an Geschäftspartner.<br/>
                Damit eine automatisierte E-Mail erstellt werden kann, muss dem betroffenen Kunden bzw. Lieferanten eine E-Mail hinterlegt sein.
            </p>

            <div className="automated-communication-list">
                {automatedCommunications ? automatedCommunications.map((automatedCommunication, index) => (
                    <AutomatedCommunicationItem
                        key={automatedCommunication.id}
                        index={index}
                        showMessage={props.showMessage}
                        logout={props.logout}
                        setLoading={props.setLoading}
                        automatedCommunication={automatedCommunication}
                        showAutomatedCommunicationDialog={showAutomatedCommunicationDialog}
                        showExecuteAutomatedCommunicationDialog={showExecuteAutomatedCommunicationDialog}
                        showDeleteAutomatedCommunicationDialog={showDeleteAutomatedCommunicationDialog}
                        executeAutomatedCommunication={executeAutomatedCommunication}
                    />
                )) : null}
            </div>
        </div>
    );
}

export default AutomatedCommunication;