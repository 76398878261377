import React, {Component} from "react";
import './AccountDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AddEntryDialog from "./AddEntryDialog/AddEntryDialog";
import ToolFab from "../../../../../../../../reusable/ToolFab/ToolFab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../../../../../../api";
import CloseIcon from "@mui/icons-material/Close";
import YesCancelDialog from "../../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import GetAppIcon from "@mui/icons-material/GetApp";
import {CSVLink} from "react-csv";
import { Tooltip } from 'react-tooltip'

class AccountDialog extends Component {

    state = {
        activeEntry:null,

        //Dialogs
        showAddEntryDialog:false,
        showDeleteEntryDialog:false
    }

    nrOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.nr = e.target.value;
        this.setState({entry:entry});
    }

    invoiceNrOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.invoiceNr = e.target.value;
        this.setState({entry:entry});
    }

    invoiceDateOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.invoiceDate = e.target.value;
        this.setState({entry:entry});
    }

    valueOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.value = e.target.value;
        this.setState({entry:entry});
    }

    dateOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.date = e.target.value;
        this.setState({entry:entry});
    }

    addEntry = (account,entry) => {
        const url = '/accounts/' + account.id;
        api.put(url,entry)
            .then(response => {
                account.entries.push(response.data);
                this.props.showMessage(0,'Änderungen gespeichert');
                this.closeAddEntryDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    removeEntry = () => {
        let entry = this.state.activeEntry;
        const url = '/accounts/remove-entry/' + entry.id;
        api.delete(url,entry)
            .then(response => {
                for(let i = 0; i < this.props.account.entries.length; i++) {
                    if(response.data === this.props.account.entries[i].id) {
                        this.props.account.entries.splice(i,1);
                    }
                }
                this.props.showMessage(0,'Änderungen gespeichert');
                this.closeAddEntryDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions

    showAddEntryDialog = (entry) => {
        this.setState({activeEntry:entry,showAddEntryDialog:true});
    }

    closeAddEntryDialog = () => {
        this.setState({showAddEntryDialog:false});
    }

    showDeleteEntryDialog = (entry) => {
        this.setState({activeEntry:entry,showDeleteEntryDialog:true});
    }

    closeDeleteEntryDialog = () => {
        this.setState({showDeleteEntryDialog:false});
    }

    render() {

        let blankEntry = {
            nr:'',
            invoiceNr:'',
            invoiceDate:'',
            value:'',
            date:''
        }

        return (
            <Dialog scroll='body' maxWidth='md' open={this.props.open} onClose={this.props.close} className='account-dialog'>
                <Tooltip id="account-dialog-tooltip"/>
                <AddEntryDialog
                    open={this.state.showAddEntryDialog}
                    close={this.closeAddEntryDialog}
                    showMessage={this.props.showMessage}
                    addEntry={this.addEntry}
                    entry={this.state.activeEntry}
                    account={this.props.account}
                />

                <YesCancelDialog
                    open={this.state.showDeleteEntryDialog}
                    close={this.closeDeleteEntryDialog}
                    showMessage={this.props.showMessage}
                    onClick={this.removeEntry}
                    header={'Löschen'}
                    text={'Wollen Sie die Buchung wirklich löschen?'}
                />

                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>{this.props.account.name}</DialogTitle>
                    <DialogContent>

                        <ToolFab className='add-fab' onClick={() => this.showAddEntryDialog(blankEntry)} aria-label="add">
                            <AddIcon />
                        </ToolFab>

                        <CSVLink filename={'wm_accounting_' + this.props.account.name + ' (' + this.props.account.nr + ')_' + Date.now() + '.csv'} data={this.props.account.entries}><GetAppIcon data-tooltip-id="account-dialog-tooltip" data-tooltip-place="top" data-tooltip-content='Kontenplan als .csv-Datei herunterladen' className='icon download-icon'/></CSVLink>

                        <table className='entries'>
                            <tr>
                                <th>Beleg Nr.</th>
                                <th>Rechnungs Nr.</th>
                                <th>Rechnungs Datum</th>
                                <th>Betrag</th>
                                <th>Datum</th>
                                <th></th>
                            </tr>
                            {this.props.account.entries.reverse().map(entry => (
                                <tr>
                                    <td>{entry ? entry.nr ? entry.nr : '-' : '-'}</td>
                                    <td>{entry ? entry.invoiceNr ? entry.invoiceNr : '-' : '-'}</td>
                                    <td>{entry ? entry.invoiceDate ? entry.invoiceDate : '-' : '-'}</td>
                                    <td>{entry ? entry.value ? entry.value + ' €' : '-' : '-'}</td>
                                    <td>{entry ? entry.date ? entry.date : '-' : '-'}</td>
                                    <td><DeleteIcon onClick={() => this.showDeleteEntryDialog(entry)} className='icon delete-icon'/></td>
                                </tr>
                            ))}
                        </table>
                    </DialogContent>
                </div>
            </Dialog>
        )
    }
}

export default AccountDialog;