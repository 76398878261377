import React, { useState, useEffect, useRef } from 'react';
import './MessageTemplatesDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import { Tooltip } from 'react-tooltip'
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../../reusable/ToolButton/ToolButton";

const MessageTemplatesDialog = (props) => {

    const [activeMessageTemplate,setActiveMessageTemplate] = useState(null);

    const activeMessageTemplateOnChangeHandler = (e) => {
        setActiveMessageTemplate(e.target.value);
    }

    const openOutlookOnClickHandler = () => {
        let updatedMailInformation = props.mailInformation;
        if(activeMessageTemplate && activeMessageTemplate.message) {
            updatedMailInformation.body = activeMessageTemplate.message;
        }else{
            updatedMailInformation.body = "";
        }
        props.mailTo(updatedMailInformation);
        props.close();
    }

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={props.close} className='message-templates-dialog'>

            <Tooltip id="message-templates-dialog-tooltip"/>

            <div className='message-templates-dialog-surrounder'>
                <h2 className="header">Textvorlage auswählen</h2>
                <TextField size="small"
                           value={activeMessageTemplate}
                           onChange={activeMessageTemplateOnChangeHandler} select className='field status-field'
                           label='Nachrichten Vorlage'>
                    <MenuItem key={0} value={null}>Keine Vorlage</MenuItem>
                    {props.messageTemplates.map(messageTemplate => (
                        <MenuItem key={messageTemplate.id} value={messageTemplate}>{messageTemplate.name}</MenuItem>
                    ))}
                </TextField>
                <div className="preview-text">
                    <h3>Vorschau:</h3>
                    <p className="message-text">{activeMessageTemplate && activeMessageTemplate.message ? activeMessageTemplate.message.replace(/(\r\n|\n|\r)/g, "\n").split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    )) : null}</p>
                </div>
                <ToolButton className="button open-button" onClick={openOutlookOnClickHandler}>Outlook öffnen</ToolButton>
            </div>
        </Dialog>
    )
}

export default MessageTemplatesDialog;