import React, {Component} from "react";
import './EmailPanel.css';

//Components
import ToolFab from "../../../../../reusable/ToolFab/ToolFab";
import EmailIcon from '@mui/icons-material/Email';
import SendEmailDialog from "./SendEmailDialog/SendEmailDialog";
import SentEmail from "./SentEmail/SentEmail";
import SentEmailDialog from "./SentEmailDialog/SentEmailDialog";
import api from "../../../../../api";

class EmailPanel extends Component {

    state = {
        activeSentEmail:null,
        searchWord:'',
        searchedSentEmails:[],

        //Dialogs
        showSendEmailDialog:false,
        showSentEmailDialog:false
    }

    showSendEmailDialog = () => {
        this.setState({showSendEmailDialog:true});
    }

    closeSendEmailDialog = () => {
        this.setState({showSendEmailDialog:false});
    }

    showSentEmailDialog = (sentEmail) => {
        this.setState({activeSentEmail:sentEmail,showSentEmailDialog:true});
    }

    closeSentEmailDialog = () => {
        this.setState({showSentEmailDialog:false});
    }

    searchOnClickHandler = () => {
        if(this.state.searchWord) {
            this.searchSentEmails(this.state.searchWord);
        }else{
            this.props.showMessage(2,"Das Suchwort muss mindestens aus einem Zeichen bestehen");
        }
    }

    searchSentEmails = (searchWord) => {
        const url = '/sent-emails/' + searchWord;
        api.get(url)
            .then(response => {
                console.log(response.data)
                this.setState({searchedSentEmails:response.data});
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    searchWordOnChangeHandler = (e) => {
        this.setState({searchWord:e.target.value});
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.searchOnClickHandler();
        }
    }

    render() {
        return (
            <div onKeyDown={this._handleKeyDown} className='email-panel'>

                <SendEmailDialog
                    open={this.state.showSendEmailDialog}
                    close={this.closeSendEmailDialog}
                    showMessage={this.props.showMessage}
                    fileStorageLocations={this.props.emailAttachments}
                    setLoading={this.props.setLoading}

                    //User
                    user={this.props.user}

                    //Email
                    sendEmail={this.props.sendEmail}
                    addEmailAttachment={this.props.addEmailAttachment}
                    emailAttachments={this.props.emailAttachments}
                    removeEmailAttachment={this.props.removeEmailAttachment}
                    clearEmailAttachments={this.props.clearEmailAttachments}

                    //Sent Emails
                    sentEmails={this.props.sentEmails}
                    addSentEmailToList={this.props.addSentEmailToList}
                    removeSentEmailFromList={this.props.removeSentEmailFromList}

                    //Company
                    company={this.props.company}

                    customers={this.props.customers}
                    suppliers={this.props.suppliers}

                    //MessageTemplates
                    messageTemplates={this.props.messageTemplates}
                />

                {false ? <div>
                    <SentEmailDialog
                        open={this.state.showSentEmailDialog}
                        close={this.closeSentEmailDialog}
                        sentEmail={this.state.activeSentEmail}
                    />

                    <h1 className='panel-header'>E-Mails</h1>

                    <div className='search-bar'>
                        <input placeholder='E-Mails durchsuchen' onChange={this.searchWordOnChangeHandler} className='search-input' type="text"/>
                        <i className="fas fa-search"/>
                    </div>

                    <div className='search-results'>
                        {this.state.searchedSentEmails.reverse().map(sentEmail => (
                            <SentEmail
                                sentEmail={sentEmail}
                                formatString={this.props.formatString}
                                formatDate={this.props.formatDate}
                                showSentEmailDialog={this.showSentEmailDialog}
                            />
                        ))}
                    </div>
                </div> : null}

                <ToolFab onClick={this.showSendEmailDialog} className='email-fab'><EmailIcon/>{this.props.emailAttachments && this.props.emailAttachments.length > 0 ? <p className='email-attachment-count'>{this.props.emailAttachments.length}</p> : null}</ToolFab>
            </div>
        )
    }
}

export default EmailPanel;