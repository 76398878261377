import React, {Component} from 'react';
import './CompanyAdministration.css';

//Components
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from "@mui/material/MenuItem";
import HelperFunctions from "../../../../../../../reusable/HelperFunctions";
import PostboxDialog from "./PostboxDialog/PostboxDialog";
import api from "../../../../../../../api";

class CompanyAdministration extends Component {

    state = {
        company:this.props.company,
        accounts:this.props.accounts,
        showPostboxDialog:false
    }

    componentDidMount() {
        this.loadCompany();
    }

    loadCompany = () => {
        let url = "/company"
        api.get(url)
            .then(response => {
                this.setState({company:response.data});
            })
            .catch(error => {

            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({company:this.props.company,accounts:this.props.accounts});
        }
    }

    companyNameOnChangeHandler = (e) => {
        let company = this.state.company;
        company.name = e.target.value;
        this.setState({company:company});
    }

    logoOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 1048576) {
            this.fileToDataUri(file)
                .then(async dataUri => {
                        let company = this.state.company;
                        company.logo = dataUri;
                        const blob = HelperFunctions.base64ToBlob(this.props.company.logo);
                        try {
                            const dimensions = await HelperFunctions.getImageDimensionsFromBlob(
                                blob
                            );
                            company.logoWidth = dimensions.width;
                            company.logoHeight = dimensions.height;
                        } catch (error) {
                            this.props.showMessage(2, "Etwas ist schiefgelaufen");
                            return;
                        }
                        this.setState({company: company});
                    }
                );
        }else{
            this.props.showMessage(2,"Das Logo darf maximal 1MB groß sein");
        }
    }

    fileToDataUri = (file) => new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            resolve(e.target.result);
        };
        fileReader.readAsDataURL(file);
    })

    deleteLogoOnClickHandler = () => {
        let company = this.state.company;
        company.logo = null;
        company.logoWidth = null;
        company.logoHeight = null;
        this.setState({company:company});
    }

    streetNameOnChangeHandler = (e) => {
        let company = this.state.company;
        company.streetName = e.target.value;
        this.setState({company:company});
    }

    streetNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.streetNr = e.target.value;
        this.setState({company:company});
    }

    addressSupplementOnChangeHandler = (e) => {
        let company = this.state.company;
        company.addressSupplement = e.target.value;
        this.setState({company:company});
    }

    postalCodeOnChangeHandler = (e) => {
        let company = this.state.company;
        company.postalCode = e.target.value;
        this.setState({company:company});
    }

    cityOnChangeHandler = (e) => {
        let company = this.state.company;
        company.city = e.target.value;
        this.setState({company:company});
    }

    countryOnChangeHandler = (e) => {
        let company = this.state.company;
        company.country = e.target.value;
        this.setState({company:company});
    }

    websiteOnChangeHandler = (e) => {
        let company = this.state.company;
        company.website = e.target.value;
        this.setState({company:company});
    }

    emailOnChangeHandler = (e) => {
        let company = this.state.company;
        company.email = e.target.value;
        this.setState({company:company});
    }

    phoneNumberOnChangeHandler = (e) => {
        let company = this.state.company;
        company.phoneNumber = e.target.value;
        this.setState({company:company});
    }

    defaultContactPersonOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultContactPerson = e.target.value;
        this.setState({company:company});
    }

    defaultContactEmailOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultContactEmail = e.target.value;
        this.setState({company:company});
    }

    defaultContactPhoneNumberOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultContactPhoneNumber = e.target.value;
        this.setState({company:company});
    }

    bicOnChangeHandler = (e) => {
        let company = this.state.company;
        company.bic = e.target.value;
        this.setState({company:company});
    }

    ibanOnChangeHandler = (e) => {
        let company = this.state.company;
        company.iban = e.target.value;
        this.setState({company:company});
    }

    taxIdOnChangeHandler = (e) => {
        let company = this.state.company;
        company.taxId = e.target.value;
        this.setState({company:company});
    }

    taxNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.taxNr = e.target.value;
        this.setState({company:company});
    }

    commercialCourtLocationOnChangeHandler = (e) => {
        let company = this.state.company;
        company.commercialCourtLocation = e.target.value;
        this.setState({company:company});
    }

    companyRegistrationNumberOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyRegistrationNumber = e.target.value;
        this.setState({company:company});
    }

    chiefExecutiveOfficerOnChangeHandler = (e) => {
        let company = this.state.company;
        company.chiefExecutiveOfficer = e.target.value;
        this.setState({company:company});
    }

    defaultVatOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultVat = e.target.value;
        this.setState({company:company});
    }

    bankAccountOnChangeHandler = (e) => {
        let company = this.state.company;
        for(let i = 0; i < this.state.accounts.length; i++) {
            if(e.target.value === this.state.accounts[i].id) {
                company.bankAccount = this.state.accounts[i];
                break;
            }
        }
        this.setState({company:company});
    }

    inputTaxAccountOnChangeHandler = (e) => {
        let company = this.state.company;
        for(let i = 0; i < this.state.accounts.length; i++) {
            if(e.target.value === this.state.accounts[i].id) {
                company.inputTaxAccount = this.state.accounts[i];
                break;
            }
        }
        this.setState({company:company});
    }

    nextCommissionNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.nextCommissionNr = e.target.value;
        this.setState({company:company});
    }

    nextCustomerNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.nextCustomerNr = e.target.value;
        this.setState({company:company});
    }

    nextSupplierNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.nextSupplierNr = e.target.value;
        this.setState({company:company});
    }

    nextDeliveryBillNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.nextDeliveryBillNr = e.target.value;
        this.setState({company:company});
    }

    nextInvoiceNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.nextInvoiceNr = e.target.value;
        this.setState({company:company});
    }

    nextOfferNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.nextOfferNr = e.target.value;
        this.setState({company:company});
    }

    nextArticleNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.nextArticleNr = e.target.value;
        this.setState({company:company});
    }

    nextOrderNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.nextOrderNr = e.target.value;
        this.setState({company:company});
    }

    defaultArticleGroupOnChangeHandler = (e) => {
        let company = this.state.company;
        for(let i = 0; i < this.props.articleGroups.length; i++) {
            if(e.target.value === this.props.articleGroups[i].id) {
                company.defaultArticleGroup = this.props.articleGroups[i];
                break;
            }
        }
        this.setState({company:company});
    }

    saveCompanyOnClickHandler = () => {
        this.props.updateCompany(this.state.company);
    }

    defaultPaymentTargetOnChangeHandler = (e) => {
        if(e.target.value <= 999) {
            let company = this.state.company;
            company.defaultPaymentTarget = e.target.value;
            this.setState({company:company});
        }
    }

    defaultOfferDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultOfferDescription = e.target.value;
        this.setState({company:company});
    }

    defaultOfferEndDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultOfferEndDescription = e.target.value;
        this.setState({company:company});
    }

    defaultOrderConfirmationDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultOrderConfirmationDescription = e.target.value;
        this.setState({company:company});
    }

    defaultOrderConfirmationEndDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultOrderConfirmationEndDescription = e.target.value;
        this.setState({company:company});
    }

    defaultDeliveryBillDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultDeliveryBillDescription = e.target.value;
        this.setState({company:company});
    }

    defaultDeliveryBillEndDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultDeliveryBillEndDescription = e.target.value;
        this.setState({company:company});
    }

    defaultInvoiceDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultInvoiceDescription = e.target.value;
        this.setState({company:company});
    }

    defaultInvoiceEndDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultInvoiceEndDescription = e.target.value;
        this.setState({company:company});
    }

    defaultCancellationInvoiceDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultCancellationInvoiceDescription = e.target.value;
        this.setState({company:company});
    }

    defaultCancellationInvoiceEndDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultCancellationInvoiceEndDescription = e.target.value;
        this.setState({company:company});
    }

    defaultOrderDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultOrderDescription = e.target.value;
        this.setState({company:company});
    }

    defaultOrderEndDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultOrderEndDescription = e.target.value;
        this.setState({company:company});
    }

    defaultRequestDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultRequestDescription = e.target.value;
        this.setState({company:company});
    }

    defaultRequestEndDescriptionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.defaultRequestEndDescription = e.target.value;
        this.setState({company:company});
    }

    showPostboxDialog = () => {
        this.setState({showPostboxDialog:true});
    }

    closePostboxDialog = () => {
        this.setState({showPostboxDialog:false});
    }

    setSystemEmail = (systemEmail) => {
        let company = this.state.company;
        company.systemEmail = systemEmail;
        this.setState({company:company});
    }

    render() {
        return (
            <div className='company-administration'>

                <PostboxDialog
                    showMessage={this.props.showMessage}
                    open={this.state.showPostboxDialog}
                    close={this.closePostboxDialog}
                    company={this.state.company}
                    setSystemEmail={this.setSystemEmail}
                    setLoading={this.props.setLoading}
                    logout={this.props.logout}
                />

                <h1>Unternehmensverwaltung</h1>
                {this.state.company ? <div className='surrounder'>
                        <h2>Allgemein</h2>
                        <TextField size="small" value={this.state.company ? this.state.company.name : ''} onChange={this.companyNameOnChangeHandler} label='Name' className='input'/>
                        <input className='input file-selector' type="file"  accept='image/*' onChange={this.logoOnChangeHandler} />
                        {this.state.company.logo ? <img className='logo-preview' src={this.state.company.logo} alt="Company Logo"/> : null}
                        {this.state.company.logo ? <ClearIcon onClick={this.deleteLogoOnClickHandler} className='clear-icon'/> : null}
                        <h2>Adresse</h2>
                        <TextField size="small" disabled={!this.state.company} value={this.state.company ? this.state.company.streetName : ''} onChange={this.streetNameOnChangeHandler} label='Straße' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.streetNr : ''} onChange={this.streetNrOnChangeHandler} label='Hausnummer' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.addressSupplement : ''} onChange={this.addressSupplementOnChangeHandler} label='Adresszusatz' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.postalCode : ''} onChange={this.postalCodeOnChangeHandler} label='Postleitzahl' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.city : ''} onChange={this.cityOnChangeHandler} label='Stadt' className='input'/>
                        <TextField size="small" value={this.state.company.country} className='input' select label='Land' onChange={this.countryOnChangeHandler}>
                            {this.props.countries}
                        </TextField>
                        <h2>Kontakt</h2>
                        <TextField size="small" value={this.state.company ? this.state.company.website : ''} onChange={this.websiteOnChangeHandler} label='Webseite' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.email : ''} onChange={this.emailOnChangeHandler} label='E-Mail' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.phoneNumber : ''} onChange={this.phoneNumberOnChangeHandler} label='Telefonnummer' className='input'/>
                        <h2>Kommunikation</h2>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultContactPerson : ''} onChange={this.defaultContactPersonOnChangeHandler} label='Name' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultContactEmail : ''} onChange={this.defaultContactEmailOnChangeHandler} label='E-Mail' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultContactPhoneNumber : ''} onChange={this.defaultContactPhoneNumberOnChangeHandler} label='Telefonnummer' className='input'/>
                    <div className="postbox-information-holder">
                        <p>{this.state.company && this.state.company.systemEmail ? "Derzeit ist das Postfach: " + this.state.company.systemEmail + " hinterlegt" : "Derzeit ist kein Systempostfach hinterlegt"}</p>
                        <ToolButton className="postbox-button" onClick={this.showPostboxDialog}>Systempostfach hinterlegen</ToolButton>
                    </div>
                        <h2>Rechnungsinformationen</h2>
                        <TextField size="small" value={this.state.company ? this.state.company.bic : ''} onChange={this.bicOnChangeHandler} label='Bankleitzahl' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.iban : ''} onChange={this.ibanOnChangeHandler} label='IBAN' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.taxId : ''} onChange={this.taxIdOnChangeHandler} label='USt-ID' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.taxNr : ''} onChange={this.taxNrOnChangeHandler} label='Steuernummer' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.commercialCourtLocation : ''} onChange={this.commercialCourtLocationOnChangeHandler} label='Handelsgericht Ort' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.companyRegistrationNumber : ''} onChange={this.companyRegistrationNumberOnChangeHandler} label='Handelsreg. Nr.' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.chiefExecutiveOfficer : ''} onChange={this.chiefExecutiveOfficerOnChangeHandler} label='Geschäftsführung' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultVat : ''} onChange={this.defaultVatOnChangeHandler} label='Standard MwSt.' className='input' type='number'/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultPaymentTarget : ''} onChange={this.defaultPaymentTargetOnChangeHandler} type="number" className='input' label='Standard Zahlungsziel in Tagen'/>
                        <h2>Bestellinformationen</h2>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultOfferDescription : ''} multiline rows={3} label='Standard Angebots-Beschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Anfang" className='textarea' onChange={this.defaultOfferDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultOfferEndDescription : ''} multiline rows={3} label='Standard Angebots-Endbeschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Ende" className='textarea' onChange={this.defaultOfferEndDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultOrderConfirmationDescription : ''} multiline rows={3} label='Standard Auftragsbestätigungs-Beschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Anfang" className='textarea' onChange={this.defaultOrderConfirmationDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultOrderConfirmationEndDescription : ''} multiline rows={3} label='Standard Auftragsbestätigungs-Endbeschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Ende" className='textarea' onChange={this.defaultOrderConfirmationEndDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultDeliveryBillDescription : ''} multiline rows={3} label='Standard Lieferschein-Beschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Anfang" className='textarea' onChange={this.defaultDeliveryBillDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultDeliveryBillEndDescription : ''} multiline rows={3} label='Standard Lieferschein-Endbeschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Ende" className='textarea' onChange={this.defaultDeliveryBillEndDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultInvoiceDescription : ''} multiline rows={3} label='Standard Rechnungs-Beschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Anfang" className='textarea' onChange={this.defaultInvoiceDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultInvoiceEndDescription : ''} multiline rows={3} label='Standard Rechnungs-Endbeschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Ende" className='textarea' onChange={this.defaultInvoiceEndDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultCancellationInvoiceDescription : ''} multiline rows={3} label='Standard Stornorechnungs-Beschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Anfang" className='textarea' onChange={this.defaultCancellationInvoiceDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultCancellationInvoiceEndDescription : ''} multiline rows={3} label='Standard Stornorechnungs-Endbeschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Ende" className='textarea' onChange={this.defaultCancellationInvoiceEndDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultOrderDescription : ''} multiline rows={3} label='Standard Bestellung-Beschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Anfang" className='textarea' onChange={this.defaultOrderDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultOrderEndDescription : ''} multiline rows={3} label='Standard Bestellungs-Endbeschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Ende" className='textarea' onChange={this.defaultOrderEndDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultRequestDescription : ''} multiline rows={3} label='Standard Anfragen-Beschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Anfang" className='textarea' onChange={this.defaultRequestDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.defaultRequestEndDescription : ''} multiline rows={3} label='Standard Anfragen-Endbeschreibung' inputProps={{maxLength:2000}} variant="outlined" placeholder="Erscheint auf Dokumenten am Ende" className='textarea' onChange={this.defaultRequestEndDescriptionOnChangeHandler}/>
                        <TextField size="small" value={this.state.company ? this.state.company.nextOfferNr : ''} onChange={this.nextOfferNrOnChangeHandler} inputProps={{ maxLength: 9 }} type='number' label='Angebotsnummer' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.nextDeliveryBillNr : ''} onChange={this.nextDeliveryBillNrOnChangeHandler} inputProps={{ maxLength: 9 }} type='number' label='Lieferscheinnummer' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.nextInvoiceNr : ''} onChange={this.nextInvoiceNrOnChangeHandler} inputProps={{ maxLength: 9 }} type='number' label='Rechnungsnummer' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.nextCommissionNr : ''} onChange={this.nextCommissionNrOnChangeHandler} inputProps={{ maxLength: 9 }} type='number' label='Kommissionsnummer' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.nextCustomerNr : ''} onChange={this.nextCustomerNrOnChangeHandler} inputProps={{ maxLength: 9 }} type='number' label='Kundennummer' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.nextSupplierNr : ''} onChange={this.nextSupplierNrOnChangeHandler} inputProps={{ maxLength: 9 }} type='number' label='Lieferantennummer' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.nextArticleNr : ''} onChange={this.nextArticleNrOnChangeHandler} inputProps={{ maxLength: 9 }} type='number' label='Artikelnummer' className='input'/>
                        <TextField size="small" value={this.state.company ? this.state.company.nextOrderNr : ''} onChange={this.nextOrderNrOnChangeHandler} inputProps={{ maxLength: 9 }} type='number' label='Bestellnummer' className='input'/>
                        <TextField size="small" value={this.state.company && this.state.company.defaultArticleGroup ? this.state.company.defaultArticleGroup.id : ''} onChange={this.defaultArticleGroupOnChangeHandler} select className='input' label='Standard Warengruppe'>
                            {this.props.articleGroups ? this.props.articleGroups.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            )) : null}
                        </TextField>
                        <h2>Buchhaltungsinformationen</h2>
                        <TextField size="small" value={this.state.company.bankAccount ? this.state.company.bankAccount.id : ''} onChange={this.bankAccountOnChangeHandler} select className='input' label='Bankkonto'>
                            {this.state.accounts ? this.state.accounts.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            )) : null}
                        </TextField>
                        <TextField size="small" value={this.state.company.inputTaxAccount ? this.state.company.inputTaxAccount.id : ''} onChange={this.inputTaxAccountOnChangeHandler} select className='input' label='Vorsteuerkonto'>
                            {this.state.accounts ? this.state.accounts.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            )) : null}
                        </TextField>
                    </div> :
                    <h2>Lädt...</h2>}
                <ToolButton onClick={this.saveCompanyOnClickHandler} main className='save-button'>Speichern</ToolButton>
            </div>
        )
    }
}

export default CompanyAdministration;