import React, {Component} from "react";
import './ConditionItem.css';

//Components
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

class ConditionItem extends Component {
    render() {
        let conditionTypes = [];
        let conditionSelection = null;
        let attributeFields = null;

        conditionTypes.push(<MenuItem key={0} value={0}>Artikel</MenuItem>);
        conditionTypes.push(<MenuItem key={1} value={1}>Kunde</MenuItem>);
        conditionTypes.push(<MenuItem key={2} value={2}>Lieferant</MenuItem>);
        conditionTypes.push(<MenuItem key={3} value={3}>Attribut</MenuItem>);

        switch (this.props.condition.type) {
            case 0:
                conditionSelection = <TextField size="small" onChange={(e) => this.props.articleNrOnChangeHandler(e,this.props.index)} className='input-field' label='Artikel Nr' />
                break;
            case 1:
                conditionSelection = <TextField size="small" onChange={(e) => this.props.customerOnChangeHandler(e,this.props.index)} className='input-field' label='Kunde' select>
                    {this.props.customers.map(customer => (
                        <MenuItem key={customer.id} value={customer}>
                            {customer.name}
                        </MenuItem>
                    ))}
                </TextField>
                break;
            case 2:
                conditionSelection = <TextField size="small" onChange={(e) => this.props.supplierOnChangeHandler(e,this.props.index)} className='input-field' label='Lieferant' select>
                    {this.props.suppliers.map(supplier => (
                        <MenuItem key={supplier.id} value={supplier}>
                            {supplier.name}
                        </MenuItem>
                    ))}
                </TextField>
                break;
            case 3:
                conditionSelection = <TextField size="small" onChange={(e) => this.props.attributeOnChangeHandler(e,this.props.index)} className='input-field' label='Attribut' select>
                    {this.props.attributes.map(attribute => (
                        <MenuItem key={attribute.id} value={attribute}>
                            {attribute.name}
                        </MenuItem>
                    ))}
                </TextField>
                break;
            default:
                conditionSelection = null;
                break;
        }

        if(this.props.condition.attributeValue.attribute) {
            switch (this.props.condition.attributeValue.attribute.type) {
                case 0: //Boolean
                    attributeFields = <FormControlLabel
                            className='switch'
                            control={
                                <Switch
                                    className='switch'
                                    checked={this.props.condition.attributeValue.booleanValue}
                                    onChange={(e) => this.props.booleanValueOnChangeHandler(e,this.props.index)}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                        />
                    break;
                case 1: //Number
                    attributeFields = <div>
                        <TextField size="small" onChange={(e) => this.props.operatorOnChangeHandler(e,this.props.index)} className='small-input-field' label='Operator' select>
                            <MenuItem key={0} value={0}>&gt;</MenuItem>
                            <MenuItem key={1} value={1}>&lt;</MenuItem>
                            <MenuItem key={2} value={2}>=</MenuItem>
                        </TextField>
                        <TextField size="small" onChange={(e) => this.props.numberValueOnChangeHandler(e,this.props.index)} className='small-input-field' type='number' label='Wert' />
                    </div>
                    break;
                case 2: //String
                    attributeFields = <TextField size="small" onChange={this.props.stringValueOnChangeHandler} className='input-field' label='Wert' />
                    break;
                default:
                    attributeFields = null;
                    break;
            }
        }

        return (
            <div className='condition-item'>
                <TextField size="small" onChange={(e) => this.props.typeOnChangeHandler(e,this.props.index)} label='Bedingungstyp' className='input-field' select>
                    {conditionTypes}
                </TextField>
                {conditionSelection}
                {attributeFields}
                <i onClick={() => this.props.removeConditionOnClickHandler(this.props.index)} className="fas fa-trash-alt"/>
            </div>
        )
    }
}

export default ConditionItem;