import React, {Component} from "react";
import './AccountingInfoDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from '@mui/icons-material/Close';

class AccountingInfoDialog extends Component {
    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='accounting-info-dialog'>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>Buchhaltung Info</DialogTitle>
                    <DialogContent>
                        <DialogContentText className='paragraph'>
                            In der Buchhaltung kann der Kontenplan bearbeitet, sowie Einträge in den Konten erstellt werden.
                            <h3>Kontenplan bearbeiten</h3>
                            Um die Kontenstruktur durch Kontengruppen und Konten zu bearbeiten, muss man zunächst den Regler "Bearbeitung aktivieren" anklicken.
                            Dann erscheinen zusätzliche Buttons zum Erstellen und Löschen von Konten und Kontengruppen.
                            Dabei ist zu beachten, dass auf einer Ebene entweder Konten oder Kontengruppen, aber niemals beides vorhanden sein kann.
                            Wichtig: Die Änderungen werden erst gespeichert wenn der Regler zurück gestellt wird!
                            <h3>Konteneinträge</h3>
                            Auf dem Buchsymbol eines Kontos werden in einem Separaten Dialog alle Einträge dieses Kontos geöffnet.
                            Durch Drücken des Runden "+" Buttons kann manuell ein neuer Eintrag erstellt werden.
                            Zusätzlich gibt es die Möglichkeit im Inventar bei Kunden- sowie Lieferantenbestellungen automatisch die dazugehörigen Einträge erstellen zu lassen.
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        )
    }
}

export default AccountingInfoDialog;