import React, {Component} from "react";
import './FileDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ToolButton from "../ToolButton/ToolButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

class FileDialog extends Component {

    state = {
        multipartFile:null,
        errormessage:''
    }

    fileOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.name.length > 127) {
            this.setState({errormessage:"Der Dateiname darf maximal 127 Zeichen lang sein"});
        }else if(file.size > 10000000){
            this.setState({errormessage:"Die Datei darf maximal 10MB groß sein"});
        }else{
            this.setState({errormessage:''});
            this.setState({multipartFile:file});
        }
    }

    uploadFileOnClickHandler = () => {
        if (this.state.multipartFile.file !== null) {
            this.props.uploadFile(this.state.multipartFile);
            this.props.close();
        } else {
            this.props.showMessage(2, 'Fehlende oder falsche Werte');
        }
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='file-dialog'>
                <DialogTitle>Datei hochladen</DialogTitle>
                <DialogContent>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <input title={this.props.type === 1 ? 'Es wird nur das Dateiformat .pdf akzeptiert' : this.props.type === 2 ? 'Es werden nur Bilder akzeptiert' : 'Es werden alle Dateiformate akzeptiert'} className='input file-selector' type="file"  accept={this.props.accept ? this.props.accept : '*'} onChange={this.fileOnChangeHandler} />
                    <p className="errormessage">{this.state.errormessage}</p>
                    <DialogActions>
                        <ToolButton main disabled={this.state.errormessage !== ''} className='button' onClick={this.uploadFileOnClickHandler}>Bestätigen</ToolButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
}

export default FileDialog;