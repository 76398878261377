import React, {Component} from "react";
import './AccountGroupItem.css';

//Components
import Paper from '@mui/material/Paper';
import AccountItem from "../AccountItem/AccountItem";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";

class AccountGroupItem extends Component {

    state = {
        activeAccountGroup:null,
        folded:true,
        showAccountGroupAccountDialog:false,
        indexArray:this.props.indexArray
    }

    toggleContent = () => {
        this.setState({folded:!this.state.folded});
    }

    formatString = (string, maxLength) => {
        let formattedString = string;
        if(string.length > maxLength) {
            formattedString = string.substring(0,maxLength) + '...';
        }
        return formattedString;
    }

    render() {

        let detailedInformation = this.state.folded ?
            null :
            <div className='detailed-information'>
                {this.props.item.accountGroups.length > 0 ? this.props.item.accountGroups.map((item,index) => (
                        <AccountGroupItem
                            key={index}
                            showMessage={this.props.showMessage}
                            index={index}
                            item={item}
                            edit={this.props.edit}
                            blankAccountGroupAccount={this.props.blankAccountGroupAccount}
                            indexArray={[...this.props.indexArray,index]}
                            showAccountGroupAccountDialog={this.props.showAccountGroupAccountDialog}
                            showDeleteAccountGroupDialog={this.props.showDeleteAccountGroupDialog}
                            showDeleteAccountDialog={this.props.showDeleteAccountDialog}
                            addEntryToList={this.props.addEntryToList}
                            setActiveAccountGroupAccount={this.props.setActiveAccountGroupAccount}
                            removeEntryFromList={this.props.removeEntryFromList}
                            moveAccountGroupUp={this.props.moveAccountGroupUp}
                            moveAccountGroupDown={this.props.moveAccountGroupDown}
                        />
                    ))
                    :
                    this.props.item.accounts.length > 0 ? this.props.item.accounts.map((item,index) => (
                            <AccountItem
                                key={index}
                                showMessage={this.props.showMessage}
                                index={index}
                                item={item}
                                edit={this.props.edit}
                                blankAccountGroupAccount={this.props.blankAccountGroupAccount}
                                indexArray={[...this.props.indexArray,index]}
                                showAccountGroupAccountDialog={this.props.showAccountGroupAccountDialog}
                                showDeleteAccountDialog={this.props.showDeleteAccountDialog}
                                addEntryToList={this.props.addEntryToList}
                                removeEntryFromList={this.props.removeEntryFromList}
                            />
                        ))
                        : <p>Kein Konto oder Kontengruppe</p>}
                {this.props.edit ? <ToolButton onClick={() => this.props.showAccountGroupAccountDialog(this.props.blankAccountGroupAccount,true,this.props.item.accountGroups.length !== 0,this.props.item.accounts.length !== 0,this.props.indexArray)} className='add-group-button'>+</ToolButton> : null}
            </div>

        return (
            <Paper elevation={3} className='account-group-item'>
                <div className='top-field'>
                    <p title={this.props.item.name} className='field'>{this.props.item.name}</p>
                    {this.state.folded ?
                        <KeyboardArrowRightIcon onClick={this.toggleContent} className='arrow-icon'/> :
                        <KeyboardArrowDownIcon onClick={this.toggleContent} className='arrow-icon' />}
                    {this.props.edit ? <div>
                            <DeleteIcon onClick={() => this.props.showDeleteAccountGroupDialog(this.props.item,this.props.indexArray)} className='icon delete-icon'/>
                        </div>
                        : null}
                </div>
                {detailedInformation}
            </Paper>
        )
    }
}

export default AccountGroupItem;