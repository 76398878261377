import React, {Component} from "react";
import './Administration.css';
import {Route} from "react-router-dom";

//Components
import UserAdministration from "./UserAdministration/UserAdministration";
import CompanyAdministration from "./CompanyAdministration/CompanyAdministration";
import AdministrationSettings from "./AdministrationSettings/AdministrationSettings";

class Administration extends Component {
    render() {
        return (
            <div className='administration'>
                <Route exact path='/*/administration'>
                    <CompanyAdministration
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        articleGroups={this.props.articleGroups}
                        setLoading={this.props.setLoading}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}

                        //Accounts
                        accounts={this.props.accounts}
                    />
                </Route>
                <Route exact path='/*/administration/administration-settings'>
                    <AdministrationSettings
                        companySettings={this.props.company.companySettings}
                        showMessage={this.props.showMessage}
                        setLoading={this.props.setLoading}
                    />
                </Route>
                <Route exact path='/*/administration/user-administration'>
                    <UserAdministration
                        showMessage={this.props.showMessage}
                        setLoading={this.props.setLoading}

                        //Users
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUser={this.props.updateUser}
                        deleteUser={this.props.deleteUser}
                        changePassword={this.props.changePassword}
                        changeAssignedLocation={this.props.changeAssignedLocation}

                        //Locations
                        locations={this.props.locations}
                    />
                </Route>
            </div>
        )
    }
}

export default Administration;