import React, {Component} from "react";
import './MasterDataInfoDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from '@mui/icons-material/Close';
import {CSVLink} from "react-csv";

class MasterDataInfoDialog extends Component {
    render() {

        const sampleData = [
            {
                nr:'4321567890',
                name:'Holzstuhl',

                purchaseNetPrice:20,
                purchaseGrossPrice:23.8,
                purchasePriceDiscountCount:10,
                purchasePriceDiscountKind:1,
                purchasePriceDiscountPercentage:10,
                purchasePriceDiscountUnitPrice:0,

                salesNetPrice:30,
                salesGrossPrice:35.7,
                salesPriceDiscountCount:10,
                salesPriceDiscountKind:1,
                salesPriceDiscountPercentage:10,
                salesPriceDiscountUnitPrice:0,

                warehouse:'Halle Nord',
                shelfSeries:'24A',
                shelf:3,
                storageSpace:2,
                count:1,
                weight:5000
            },
            {
                nr:'4321567891',
                name:'Holztisch',

                purchaseNetPrice:30,
                purchaseGrossPrice:35.7,
                purchasePriceDiscountCount:10,
                purchasePriceDiscountKind:1,
                purchasePriceDiscountPercentage:10,
                purchasePriceDiscountUnitPrice:0,

                salesNetPrice:50,
                salesGrossPrice:59.5,
                salesPriceDiscountCount:10,
                salesPriceDiscountKind:1,
                salesPriceDiscountPercentage:10,
                salesPriceDiscountUnitPrice:0,

                warehouse:'Halle Nord',
                shelfSeries:'23B',
                shelf:4,
                storageSpace:2,
                count:1,
                weight:10000
            },
            {
                nr:'4321567892',
                name:'Holzschrank',

                purchaseNetPrice:70,
                purchaseGrossPrice:83.3,
                purchasePriceDiscountCount:10,
                purchasePriceDiscountKind:1,
                purchasePriceDiscountPercentage:10,
                purchasePriceDiscountUnitPrice:0,

                salesNetPrice:100,
                salesGrossPrice:119,
                salesPriceDiscountCount:10,
                salesPriceDiscountKind:1,
                salesPriceDiscountPercentage:10,
                salesPriceDiscountUnitPrice:0,

                warehouse:'Halle SSüd',
                shelfSeries:'3B',
                shelf:1,
                storageSpace:2,
                count:1,
                weight:30000
            }
        ]

        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='master-data-info-dialog'>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>Inventar Info</DialogTitle>
                    <DialogContent>
                        <DialogContentText className='paragraph'>
                            In den Stammdaten werden alle im Tool benötigten Daten angelegt.
                            Für eine bequemeres Anlegen von bestehenden Daten kann die Import-Funktion genutzt werden.
                            Dazu auf den schwarzen Upload-Button drücken. Nun öffnet sich ein Fenster in welches die .csv-Datei mit den Daten gezogen werden muss. <CSVLink filename={'article_sample_masterdata.csv'} data={sampleData} className='sample-data-link'>Hier</CSVLink> sehen Sie wie so eine Datei für Artikel formatiert sein muss.
                            <br/><br/>
                            Über das Download-Icon können Daten aus dem System als .csv-Datei exportiert werden.
                            Es ist zu beachten, dass nur die angezeigten Daten exportiert werden.
                            <br/><br/>
                            Möchte man alle Daten einer Rubrik herunterladen, muss man die Suche ohne ein Suchwort durchführen.
                            Nun werden alle Daten der Rubrik angezeigt und können exportiert werden.
                            <h3>Artikel</h3>
                            Artikel sind der wichtigste Bestandteil des Tools.
                            Sie bilden alle Produkte und Teilprodukte eines Unternehmens ab.
                            Artikel sind durch eine Artikelnummer eindeutig identifizierbar.
                            <br/><br/>
                            Weiterhin können wichtige Attribute, wie etwa Nettoeinkaufspreis, Gewicht oder Verpackungseinheit hinterlegt werden.
                            Diese sind später wichtig für die Rechnung.
                            <h3>Artikelgruppen</h3>
                            Für eine einfachere Bedienbarkeit werden Artikel in Artikelgruppen gegliedert, was besonders für die Buchhaltung wichtig ist.
                            Anstatt jedem Artikel einzeln Konten oder Mehrwertsteuersätze zuzuweisen, kann dies zusammengefasst mit einer Artikelgruppe gemacht werden.
                            <h3>Standorte</h3>
                            Der Warehouse Manager 4.0 bietet für Unternehmen mit mehreren Standorten die Möglichkeit, diese im System anzulegen.
                            Wird ein Standort angelegt steht dieser ab dann zur Umlagerung zur Verfügung.
                            <br/><br/>
                            Jedem Benutzer wird genau ein Stanort zugewiesen, einem Standort können jedoch beliebig viele Benutzer zugewiesen werden.
                            Der zugewiesene Standort entscheidet darüber, auf welche Lagerbestände ein Nutzer Zugriff hat und von wo aus die Transaktionen gebucht werden.
                            <h3>Kunden und Lieferanten</h3>
                            Um Transaktionen und Bestellungen, Kunden sowie Lieferanten zuweisen zu können müssen diese zunächst angelegt werden.
                            Zum einen erscheinen die Kundendaten dann auf der Rechnung, zum anderen bietet das Reporting bei guter Datenpflege eine komplexe Analysemöglichkeit.
                            <h3>Attribute</h3>
                            Neben den statischen Attributen wie etwa Nettoeinkauspreis, Gewicht und Menge gibt es zusätzlich für jeden Artikel eine unbegrenzte Anzahl an dynamischen Attributen.
                            Diese können in den Stammdaten unter dem Reiter Attribute angelegt und verwaltet werden.
                            <br/><br/>
                            Wird ein Attribut als Hauptattribut markiert erscheint es auf der Rechnung und ermöglicht eine bessere identifizierung des Artikels.
                            Ein Beispiel für ein Hauptattribut wäre die Farbe oder das Volumen eines Artikels.
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        )
    }
}

export default MasterDataInfoDialog;