import React, { useState, useEffect, useRef } from 'react';
import './AutomatedCommunicationDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import { Tooltip } from 'react-tooltip'
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import api from "../../../../../../../../api";
import CloseIcon from "@mui/icons-material/Close";
import ArticleIcon from "@mui/icons-material/Article";

const AutomatedCommunicationDialog = (props) => {

    const [automatedCommunication,setAutomatedCommunication] = useState(null);

    useEffect(() => {
        setAutomatedCommunication(props.automatedCommunication);
    }, []);

    const receiverEmailOnChangeHandler = (e) => {
        const updatedAutomatedCommunication = { ...automatedCommunication, receiverEmail: e.target.value };
        setAutomatedCommunication(updatedAutomatedCommunication);
    }

    const messageOnChangeHandler = (e) => {
        const updatedAutomatedCommunication = { ...automatedCommunication, message: e.target.value };
        setAutomatedCommunication(updatedAutomatedCommunication);
    }

    const subjectOnChangeHandler = (e) => {
        const updatedAutomatedCommunication = { ...automatedCommunication, subject: e.target.value };
        setAutomatedCommunication(updatedAutomatedCommunication);
    }

    const kindOnChangeHandler = (e) => {
        const updatedAutomatedCommunication = { ...automatedCommunication, kind: e.target.value };
        if(e.target.value === "MINIMUM_STOCK_SHORTFALL") {
            updatedAutomatedCommunication.days = null;
        }
        setAutomatedCommunication(updatedAutomatedCommunication);
    }

    const removeFileStorageLocation = () => {
        const updatedAutomatedCommunication = { ...automatedCommunication, fileStorageLocation: null };
        setAutomatedCommunication(updatedAutomatedCommunication);
    }

    const saveOnClickHandler = (automatedCommunication) => {
        if(validateAutomatedCommunication(automatedCommunication)) {
            if(automatedCommunication && automatedCommunication.id) {
                updateAutomatedCommunication();
            }else if(automatedCommunication && !automatedCommunication.id) {
                createAutomatedCommunication();
            }
        }
    }

    const validateAutomatedCommunication = (automatedCommunication) => {
        if(!automatedCommunication.name) {
            props.showMessage(2,"Einer automatisierten Kommunikation muss ein Name zugewiesen sein");
            return false;
        }
        if(!automatedCommunication.kind) {
            props.showMessage(2,"Einer automatisierten Kommunikation muss eine Aktion zugewiesen sein");
            return false;
        }
        if((automatedCommunication.kind === "CUSTOMER_OFFER_REMINDER" || automatedCommunication.kind === "SUPPLIER_ORDER_CONFIRMATION_REMINDER") && (automatedCommunication.days === null || automatedCommunication.days === undefined)) {
            props.showMessage(2,"Einer automatisierten Kommunikation muss eine Tagesanzahl zugewiesen sein");
            return false;
        }
        if(!automatedCommunication.subject) {
            props.showMessage(2,"Einer automatisierten Kommunikation muss ein Betreff zugewiesen sein");
            return false;
        }
        if(!automatedCommunication.message) {
            props.showMessage(2,"Einer automatisierten Kommunikation muss eine Nachricht zugewiesen sein");
            return false;
        }
        return true;
    }

    const createAutomatedCommunication = () => {
        let url = "/automated-communications";
        api.post(url,automatedCommunication)
            .then(response => {
                props.addAutomatedCommunicationToList(response.data);
                props.showMessage(0,"Die Automatisierte Kommunikation wurde gespeichert. Sie wird jedoch erst ausgeführt, wenn Sie aktiviert wurde.");
                props.close();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const updateAutomatedCommunication = () => {
        let url = "/automated-communications";
        api.put(url,automatedCommunication)
            .then(response => {
                props.updateAutomatedCommunicationInList(response.data);
                props.showMessage(0,"Die Änderungen wurden gespeichert");
                props.close();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={props.close} className='automated-communication-dialog'>

            <Tooltip id="automated-communication-dialog-tooltip"/>

            <div className='automated-communication-dialog-surrounder'>
                <DialogTitle>Automatisierte Aktion ausführen</DialogTitle>
                <DialogContent>
                    {automatedCommunication && automatedCommunication.assignedCustomer ? <TextField
                        disabled value={automatedCommunication.assignedCustomer.name}
                        InputLabelProps={{shrink: true}}
                        className='field' label='Kunde' size='small'>
                        {props.customers ? props.customers.map(customer => (
                            <MenuItem key={customer.id} value={customer}>{customer.name}</MenuItem>
                        )) : null}
                    </TextField> : null}
                    {automatedCommunication && automatedCommunication.assignedSupplier ? <TextField
                        disabled value={automatedCommunication.assignedSupplier.name}
                        InputLabelProps={{shrink: true}}
                        className='field' label='Lieferant' size='small'>
                        {props.suppliers ? props.suppliers.map(supplier => (
                            <MenuItem key={supplier.id} value={supplier}>{supplier.name}</MenuItem>
                        )) : null}
                    </TextField> : null}
                    <TextField
                        value={automatedCommunication && automatedCommunication.receiverEmail ? automatedCommunication.receiverEmail : null}
                        onChange={receiverEmailOnChangeHandler} InputLabelProps={{shrink: true}}
                        inputProps={{maxLength: 255}}
                        className='field' label='Empfänger E-Mail' size='small'/>
                    <TextField
                        value={automatedCommunication && automatedCommunication.subject ? automatedCommunication.subject : null}
                        onChange={subjectOnChangeHandler} InputLabelProps={{shrink: true}}
                        inputProps={{maxLength: 255}}
                        className='field' label='Betreff' size='small'/>
                    <TextField
                        value={automatedCommunication && automatedCommunication.message ? automatedCommunication.message : null}
                        multiline onChange={messageOnChangeHandler} InputLabelProps={{shrink: true}}
                        inputProps={{maxLength: 1000}} maxRows={20}
                        className='field textarea' label='Nachricht' size='small'/>
                    {automatedCommunication && automatedCommunication.fileStorageLocation ? <div className="file-storage-holder">
                        <p>{automatedCommunication.fileStorageLocation.name}</p> <ArticleIcon onClick={() => props.showPDFDialog(automatedCommunication.fileStorageLocation)} className="icon display-icon"/> <CloseIcon onClick={removeFileStorageLocation} className="icon remove-icon"/>
                    </div> : null}
                </DialogContent>
                <DialogActions>
                    <ToolButton className='button'
                                onClick={() => props.showDeleteAutomatedCommunicationDialog(automatedCommunication)}>Löschen</ToolButton>
                    <ToolButton main className='button'
                                onClick={() => props.showExecuteAutomatedCommunicationDialog(automatedCommunication)}>Ausführen</ToolButton>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default AutomatedCommunicationDialog;