import React, {Component} from "react";
import './MasterData.css';
import {Route} from 'react-router-dom';

//Components
import MasterDataContent from "./MasterDataContent/MasterDataContent";

class MasterData extends Component {
    render() {
        return (
            <div className='master-data'>
                <Route exact path='/*/master-data'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        items={this.props.articles}
                        attributes={this.props.attributes}
                        addNewItem={this.props.addNewArticle}
                        addMultipleItems={this.props.addMultipleArticles}
                        updateItem={this.props.updateArticle}
                        deleteItem={this.props.deleteArticle}
                        company={this.props.company}
                        articleGroups={this.props.articleGroups}
                        type={0} //= articles
                        suppliers={this.props.suppliers}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}
                    />
                </Route>
                <Route exact path='/*/master-data/article-groups'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        items={this.props.articleGroups}
                        addNewItem={this.props.addNewArticleGroup}
                        updateItem={this.props.updateArticleGroup}
                        deleteItem={this.props.deleteArticleGroup}
                        accounts={this.props.accounts}
                        type={1} //= article groups
                    />
                </Route>
                <Route exact path='/*/master-data/locations'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        items={this.props.locations}
                        addNewItem={this.props.addNewLocation}
                        addMultipleItems={this.props.addMultipleLocations}
                        updateItem={this.props.updateLocation}
                        deleteItem={this.props.deleteLocation}
                        type={2} //= locations
                    />
                </Route>
                <Route exact path='/*/master-data/customers'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        items={this.props.customers}
                        addNewItem={this.props.addNewCustomer}
                        addMultipleItems={this.props.addMultipleCustomers}
                        updateItem={this.props.updateCustomer}
                        deleteItem={this.props.deleteCustomer}
                        company={this.props.company}
                        countries={this.props.countries}
                        accounts={this.props.accounts}
                        type={3} //= customers
                    />
                </Route>
                <Route exact path='/*/master-data/suppliers'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        items={this.props.suppliers}
                        addNewItem={this.props.addNewSupplier}
                        addMultipleItems={this.props.addMultipleSuppliers}
                        updateItem={this.props.updateSupplier}
                        deleteItem={this.props.deleteSupplier}
                        company={this.props.company}
                        countries={this.props.countries}
                        accounts={this.props.accounts}
                        type={4} //= suppliers
                    />
                </Route>
                <Route exact path='/*/master-data/attributes'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        items={this.props.attributes}
                        addNewItem={this.props.addNewAttribute}
                        addMultipleItems={this.props.addMultipleAttributes}
                        updateItem={this.props.updateAttribute}
                        deleteItem={this.props.deleteAttribute}
                        type={5} //= attributes
                    />
                </Route>
                <Route exact path='/*/master-data/message-templates'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        items={this.props.messageTemplates}
                        addNewItem={this.props.addNewMessageTemplate}
                        updateItem={this.props.updateMessageTemplate}
                        deleteItem={this.props.deleteMessageTemplate}
                        type={6} //= message templates
                    />
                </Route>
            </div>
        )
    }
}

export default MasterData;