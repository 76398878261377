import React, { useState, useEffect } from 'react';
import './MultipleMailAdressesContextMenu.css';
import HelperFunctions from "../HelperFunctions";
import {Tooltip} from "react-tooltip";
import zIndex from "@mui/material/styles/zIndex";

//Components

const MultipleMailAdressesContextMenu = (props) => {
    return (
        <ul id='multiple-mail-adresses-context-menu' className='multiple-mail-adresses-context-menu'
            style={{left: props.anchorPoint.x - 360, top: props.anchorPoint.y}}>
            {props.mailAdresses ? props.mailAdresses.map(mailAdress => (
                <li onClick={() => props.mailAdressOnClickHandler(mailAdress)}>
                    <p>{HelperFunctions.formatString(mailAdress.email + " (" + HelperFunctions.addressEnumToString(mailAdress.kind) + ")", 45)}</p></li>
            )) : null}
        </ul>
    )
}

export default MultipleMailAdressesContextMenu;