import React, {Component} from "react";
import './AccountingActions.css';

//Components
import {Route} from "react-router-dom";
import Settings from "../../CommonComponents/Actions/Settings/Settings";
import Administration from "../../CommonComponents/Actions/Administration/Administration";
import Accounting from "./Accounting/Accounting";
import AccountingDashboard from "./AccountingDashboard/AccountingDashboard";
import MasterData from "../../CommonComponents/Actions/MasterData/MasterData";

class AccountingActions extends Component {
    render() {
        return (
            <div className='accounting-actions'>
                <Route exact path='/accounting-manager'>
                    <AccountingDashboard
                        user={this.props.user}
                        logout={this.props.logout}
                        downloadFile={this.props.downloadFile}
                    />
                </Route>
                <Route path='/accounting-manager/accounting'>
                    <Accounting
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}

                        //Account Plan
                        accountPlan={this.props.accountPlan}
                        saveAccountPlan={this.props.saveAccountPlan}

                        //Accounts
                        accounts={this.props.accounts}
                    />
                </Route>

                <Route path='/accounting-manager/master-data'>
                    <MasterData
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        downloadFile={this.props.downloadFile}

                        //Company
                        company={this.props.company}

                        //Articles
                        articles={this.props.articles}
                        addNewArticle={this.props.addNewArticle}
                        addMultipleArticles={this.props.addMultipleArticles}
                        updateArticle={this.props.updateArticle}
                        deleteArticle={this.props.deleteArticle}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}

                        //Locations
                        locations={this.props.locations}
                        addNewLocation={this.props.addNewLocation}
                        addMultipleLocations={this.props.addMultipleLocations}
                        updateLocation={this.props.updateLocation}
                        deleteLocation={this.props.deleteLocation}

                        //Customers
                        customers={this.props.customers}
                        addNewCustomer={this.props.addNewCustomer}
                        addMultipleCustomers={this.props.addMultipleCustomers}
                        updateCustomer={this.props.updateCustomer}
                        deleteCustomer={this.props.deleteCustomer}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        addNewSupplier={this.props.addNewSupplier}
                        addMultipleSuppliers={this.props.addMultipleSuppliers}
                        updateSupplier={this.props.updateSupplier}
                        deleteSupplier={this.props.deleteSupplier}

                        //Attributes
                        attributes={this.props.attributes}
                        addNewAttribute={this.props.addNewAttribute}
                        addMultipleAttributes={this.props.addMultipleAttributes}
                        updateAttribute={this.props.updateAttribute}
                        deleteAttribute={this.props.deleteAttribute}

                        //Article Groups
                        articleGroups={this.props.articleGroups}
                        addNewArticleGroup={this.props.addNewArticleGroup}
                        updateArticleGroup={this.props.updateArticleGroup}
                        deleteArticleGroup={this.props.deleteArticleGroup}

                        //Accounts
                        accounts={this.props.accounts}
                    />
                </Route>

                <Route path='/accounting-manager/administration'>
                    <Administration
                        //Common
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        downloadFile={this.props.downloadFile}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}

                        //Users
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUser={this.props.updateUser}
                        deleteUser={this.props.deleteUser}
                        changePassword={this.props.changePassword}
                        changeAssignedLocation={this.props.changeAssignedLocation}

                        //Locations
                        locations={this.props.locations}

                        //Accounts
                        accounts={this.props.accounts}

                        //Article Groups
                        articleGroups={this.props.articleGroups}
                    />
                </Route>

                <Route path='/accounting-manager/settings'>
                    <Settings
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        changeOwnPassword={this.props.changeOwnPassword}
                        user={this.props.user}
                        downloadFile={this.props.downloadFile}
                    />
                </Route>
            </div>
        )
    }
}

export default AccountingActions;