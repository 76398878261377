import React, {Component} from "react";
import './DirectoryContextMenu.css';

//Components

class DirectoryContextMenu extends Component {
    render() {
        return (
            <ul className='directory-context-menu' style={{left:this.props.anchorPoint.x,top:this.props.anchorPoint.y}}>
                <li onClick={() => this.props.renameDirectoryOnClickHandler(this.props.item)}><p>Umbennenen</p></li>
                <li onClick={() => this.props.deleteDirectoryOnClickHandler(this.props.item)}><p>Löschen</p></li>
                <li onClick={this.props.createDirectoryOnClickHandler}><p>Neuen Ordner erstellen</p></li>
                <li onClick={this.props.createFileOnClickHandler}><p>Neue Datei erstellen</p></li>
            </ul>
        )
    }
}

export default DirectoryContextMenu;