import React, {Component} from "react";
import './DocumentActionsMenu.css';

//Components
import {NavLink, Route} from "react-router-dom";

class DocumentActionsMenu extends Component {
    render() {
        return (
        <div className='document-actions-menu'>
            <Route path='/document-manager/order-management'>
                <menu className='document-management-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/document-manager/order-management'><h2>Kundenbestellungen</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/document-manager/order-management/supplier-orders'><h2>Lieferantenbestellungen</h2></NavLink>
                </menu>
            </Route>
            <Route path='/document-manager/master-data'>
                <menu className='master-data-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/document-manager/master-data'><h2>Artikel</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/document-manager/master-data/article-groups'><h2>Artikelgruppen</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/document-manager/master-data/locations'><h2>Standorte</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/document-manager/master-data/customers'><h2>Kunden</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/document-manager/master-data/suppliers'><h2>Lieferanten</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/document-manager/master-data/attributes'><h2>Attribute</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/master-data/message-templates'><h2>Nachr. Vorl.</h2></NavLink>
                </menu>
            </Route>
            <Route path='/document-manager/administration'>
                <menu className='administration-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/warehouse-manager/administration'><h2>Unternehmensverwaltung</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/administration/administration-settings'><h2>Unternehmenseinstellungen</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/administration/user-administration'><h2>Benutzer</h2></NavLink>
                </menu>
            </Route>
            <Route path='/document-manager/settings'>
                <menu className='settings-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/document-manager/settings'><h2>Allgemein</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/document-manager/settings/security'><h2>Sicherheit</h2></NavLink>
                </menu>
            </Route>
            </div>
        )
    }
}

export default DocumentActionsMenu;