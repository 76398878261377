import React, { useState, useEffect, useRef } from 'react';
import './ArticleMovement.css';

//Components
import api from "../../../../../../../api";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import { Tooltip } from 'react-tooltip'
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import YesCancelDialog from "../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import TransactionItem from "../../../../../../../reusable/TransactionItem/TransactionItem";
import TransactionDialog from "../../../../../../../reusable/TransactionDialog/TransactionDialog";
import SecureYesCancelDialog from "../../../../../../../reusable/SecureYesCancelDialog/SecureYesCancelDialog";
import SearchIcon from '@mui/icons-material/Search';
import SearchTransactionDialog from "./SearchTransactionDialog/SearchTransactionDialog";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import HelperFunctions from "../../../../../../../reusable/HelperFunctions";

const ArticleMovement = (props) => {

    const filter = createFilterOptions();

    const [bookIn, setBookIn] = useState(false);
    const [article, setArticle] = useState(null);
    const [batch, setBatch] = useState(null);
    const [inventoryCount, setInventoryCount] = useState(1);
    const [toLocation, setToLocation] = useState(null);
    const [assignedCustomer, setAssignedCustomer] = useState(null);
    const [assignedSupplier, setAssignedSupplier] = useState(null);
    const [infoText,setInfoText] = useState(null);
    const [activeTransaction, setActiveTransaction] = useState(null);
    const [articleBatches, setArticleBatches] = useState(null);
    const [batchArticles, setBatchArticles] = useState(null);
    const [transactionsActive, setTransactionsActive] = useState(null);

    const [inventoryInfoDialog, setInventoryInfoDialog] = useState(false);
    const [transactionDialog, setTransactionDialog] = useState(false);
    const [deleteTransactionDialog, setDeleteTransactionDialog] = useState(false);
    const [searchTransactionDialog,setSearchTransactionDialog] = useState(false);

    const customerRef = useRef(null);
    const articleRef = useRef(null);
    const batchRef = useRef(null);
    const inventoryCountRef = useRef(null);

    useEffect(() => {
        loadTransactionsActive();
    }, []);

    const loadTransactionsActive = () => {
        props.setLoading(true);
        api.get("/locations/lock/transactions")
            .then(response => {
                setTransactionsActive(response.data);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const updateTransactionsActive = (locked) => {
        props.setLoading(true);
        api.put("/locations/lock/transactions", locked, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setTransactionsActive(response.data);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            switch (e.target.id) {
                case "inventoryCount":
                    bookTransactionOnClickHandler();
                    break;
                case "customer":
                    bookTransactionOnClickHandler();
                    break;
                case "supplier":
                    bookTransactionOnClickHandler();
                    break;
                default:
                    break;
            }
        }
    }

    const bookTransactionOnClickHandler = () => {
        let transaction = {
            batch:batch,
            article:article,
            inventoryCount:bookIn ? inventoryCount : -inventoryCount,
            toLocation:toLocation,
            assignedCustomer:assignedCustomer,
            assignedSupplier:assignedSupplier
        }
        if(validateTransaction(transaction)) {
            bookTransaction(transaction);
        }
    }

    const validateTransaction = (transaction) => {
        if(!transaction.article) {
            props.showMessage(2,"Jeder Transaktion muss ein Artikel zugewiesen werden");
            return false;
        }
        if(!transaction.inventoryCount) {
            props.showMessage(2,"Jeder Transaktion muss eine Anzahl zugewiesen werden");
            return false;
        }
        if(!transaction.article.id && !transaction.article.name) {
            props.showMessage(2,"Dem Artikel muss ein Name zugewiesen werden");
            return false;
        }
        if(!bookIn && transaction.assignedSupplier) {
            showInventoryInfoDialog("Sie haben einem Warenausgang einen Lieferanten zugewiesen. Wollen Sie dennoch fortfahren?");
            return false;
        }
        if(bookIn && transaction.assignedCustomer) {
            showInventoryInfoDialog("Sie haben einem Wareneingang einen Kunden zugewiesen. Wollen Sie dennoch fortfahren?");
            return false;
        }
        if(props.articleInformation && props.articleInformation.article && article.id && props.articleInformation.article.id === article.id && ((props.articleInformation.inventoryCount - props.articleInformation.reservationCount) >= 0 && (props.articleInformation.inventoryCount - props.articleInformation.reservationCount + transaction.inventoryCount) < 0)) {
            showInventoryInfoDialog("Sie sind dabei eine Buchung durchzuführen, die den freien Lagerbestand unterschreitet. Wollen Sie dennoch fortfahren?");
            return false;
        }
        if(props.articleInformation && props.articleInformation.article && article.id && props.articleInformation.article.id === article.id && ((props.articleInformation.inventoryCount - props.articleInformation.article.minimumStock) >= 0 && (props.articleInformation.inventoryCount - props.articleInformation.article.minimumStock + transaction.inventoryCount) < 0)) {
            showInventoryInfoDialog("Sie sind dabei eine Buchung durchzuführen, die den Mindestbestand unterschreitet. Wollen Sie dennoch fortfahren?");
            return false;
        }
        return true;
    }

    const bookTransaction = (transaction) => {
        props.setLoading(true);
        api.post("/transactions",transaction)
            .then(response => {
                console.log(response.data);
                props.displayArticleInformation(null);
                props.addTransactionToHistory(response.data.transaction);
                if(!transaction.article.id) {
                    props.addArticleToList(response.data.transaction.article);
                }
                if(transaction.batch && !transaction.batch.id) {
                    props.addBatchToList(response.data.transaction.batch);
                }
                if(transaction.assignedCustomer && !transaction.assignedCustomer.id) {
                    props.addCustomerToList(response.data.transaction.assignedCustomer);
                    setAssignedCustomer(response.data.transaction.assignedCustomer)
                }
                if(transaction.assignedSupplier && !transaction.assignedSupplier.id) {
                    props.addSupplierToList(response.data.transaction.assignedSupplier);
                    setAssignedSupplier(response.data.transaction.assignedSupplier);
                }
                props.showMessage(0,"Transaktion erfolgreich verbucht!");
                clearFields();
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    };

    const deleteTransaction = (transaction) => {
        api.delete("/transactions/" + transaction.id)
            .then(response => {
                setActiveTransaction(null)
                props.removeTransactionFromHistory(transaction);
                props.showMessage(0,"Transaktion erfolgreich gelöscht!");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.setLoading(false);
                }
                props.setLoading(false);
            });
    }

    const loadArticlesByBatch = (batch) => {
        api.get("/articles/search/batch/" + batch.id)
            .then(response => {
                if(response.data) {
                    setBatchArticles(response.data);
                }
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.setLoading(false);
                }
                props.setLoading(false);
            });
    }

    const loadBatchesByArticle = (article) => {
        api.get("/batches/search/article/" + article.id)
            .then(response => {
                setArticleBatches(response.data);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.setLoading(false);
                }
                props.setLoading(false);
            });
    }

    const clearFields = () => {
        setBatch(null);
        setArticle(null);
        setInventoryCount(1);
        props.getArticleInformation(null);
        props.displayBatchInformation(null);
        if(customerRef.current) {
            customerRef.current.focus();
        }
    }

    const batchOnChange = (batch) => {
        let updatedBatch = batch ? { ...batch } : null;
        if(updatedBatch && updatedBatch.id) {
            loadArticlesByBatch(updatedBatch);
            inventoryCountRef.current.focus();
        }
        setBatch(updatedBatch);
        props.displayBatchInformation(updatedBatch);
    }

    const batchNrOnChangeHandler = (e) => {
        let updatedBatch = { ...batch };
        updatedBatch.id = null;
        updatedBatch.nr = e.target.value;
        setBatch(updatedBatch);
    }

    const articleOnChange = (article) => {
        let updatedArticle = { ...article };
        if(updatedArticle) {
            if(!updatedArticle.articleGroup && props.company && props.company.defaultArticleGroup) {
                updatedArticle.articleGroup = props.company.defaultArticleGroup;
            }
        }
        if(updatedArticle && updatedArticle.id) {
            loadBatchesByArticle(updatedArticle);
            batchRef.current.focus();
        }
        if(!updatedArticle) {
            setBatch(null);
            setArticleBatches(null);
        }
        setArticle(updatedArticle);
        props.getArticleInformation(updatedArticle);
    }

    const articleNrOnChangeHandler = (e) => {
        let updatedArticle = { ...article };
        updatedArticle.id = null;
        updatedArticle.nr = e.target.value;
        setArticle(updatedArticle);
        props.getArticleInformation(updatedArticle);
    }

    const articleNameOnChangeHandler = (e) => {
        const updatedArticle = {
            ...article,
            name:e.target.value
        }
        setArticle(updatedArticle);
    }

    const customerOnChangeHandler = (value,customer) => {
        setAssignedCustomer(customer);
        if(customer && customer.id) {
            articleRef.current.focus();
        }
    }

    const customerNameOnChangeHandler = (e) => {
        let updatedCustomer = { ...assignedCustomer };
        updatedCustomer.name = e.target.value;
        if(updatedCustomer) {
            setAssignedSupplier(null);
            setToLocation(null);
            if(e.target.value.includes(";")) {
                let customerAsString = e.target.value.split(";");
                switch (customerAsString.length) {
                    case 1:
                        updatedCustomer = {
                            nr:customerAsString[0]
                        };
                        break;
                    case 2:
                        updatedCustomer = {
                            nr:customerAsString[0],
                            name:customerAsString[1]
                        };
                        break;
                    case 3:
                        updatedCustomer = {
                            nr:customerAsString[0],
                            name:customerAsString[1] + " " + customerAsString[2]
                        };
                        break;
                }
                for(let i = 0; i < props.customers.length; i++) {
                    if(props.customers[i].nr === updatedCustomer.nr) {
                        updatedCustomer = props.customers[i];
                    }
                }
                articleRef.current.focus();
            }
            if(updatedCustomer.id) {
                articleRef.current.focus();
            }
        }
        setAssignedCustomer(updatedCustomer);
    }

    const customerNrOnChangeHandler = (e) => {
        const updatedAssignedCustomer = {
            ...assignedCustomer,
            nr:e.target.value
        }
        setAssignedCustomer(updatedAssignedCustomer);
    };

    const supplierOnChangeHandler = (value) => {
        if(value) {
            setAssignedCustomer(null);
            setToLocation(null);
        }
        setAssignedSupplier(value);
    };

    const supplierNrOnChangeHandler = (e) => {
        const updatedAssignedSupplier = {
            ...assignedSupplier,
            nr:e.target.value
        }
        setAssignedSupplier(updatedAssignedSupplier);
    };

    const expirationDateOnChangeHandler = (e) => {
        const updatedBatch = {
            ...batch,
            expirationDate:e.target.value
        }
        setBatch(updatedBatch);
    };

    const udiOnChangeHandler = (e) => {
        let updatedBatch = {
            ...batch,
            udi:e.target.value
        }

        if(updatedBatch) {
            let information = HelperFunctions.getInformationByUDI(e.target.value,props.articles,props.batches);
            console.log(information)
            if(information) {
                setArticle(information.article);
                updatedBatch = information.batch;
                inventoryCountRef.current.focus();
            }
        }
        setBatch(updatedBatch);
    };

    const inventoryCountOnChangeHandler = (e) => {
        if(e.target.value === null || e.target.value >= 0) {
            setInventoryCount(e.target.value);
        }
    };

    const toLocationOnChangeHandler = (value) => {
        if(value) {
            setAssignedCustomer(null);
            setAssignedSupplier(null);
        }
        setToLocation(value);
    };

    const toggleBook = () => {
        if(!bookIn) {
            setToLocation(null);
        }
        setBookIn(!bookIn);
    };

    let bookText;
    if(bookIn) {
        bookText = <p className='SwitchText' style={{color:"lightgreen"}}><b>Zugang</b></p>
    }else{
        bookText = <p className='SwitchText' style={{color:"lightcoral"}}><b>Abgang</b></p>
    }

    const generateLowMinimumStockList = () => {
        props.setLoading(true);
        api.get("/inventories/low", { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'niedrige_bestaende.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const showInventoryInfoDialog = (infoText) => {
        setInfoText(infoText);
        setInventoryInfoDialog(true);
    }

    const closeInventoryInfoDialog = () => {
        setInfoText(null);
        setInventoryInfoDialog(false);
    }

    const showTransactionDialog = (transaction) => {
        setActiveTransaction(transaction);
        setTransactionDialog(true);
    }

    const closeTransactionDialog = () => {
        setTransactionDialog(false);
        setActiveTransaction(null);
    }

    const showDeleteTransactionDialog = (transaction) => {
        setActiveTransaction(transaction);
        setDeleteTransactionDialog(true);
    }

    const closeDeleteTransactionDialog = () => {
        setDeleteTransactionDialog(false);
        setActiveTransaction(null);
    }

    const showSearchTransactionDialog = () => {
        setSearchTransactionDialog(true);
    }

    const closeSearchTransactionDialog = () => {
        setSearchTransactionDialog(false);
    }

    return (
        <div className='article-movement'>
            <Tooltip id="article-movement-tooltip"/>

            <YesCancelDialog
                open={inventoryInfoDialog}
                close={closeInventoryInfoDialog}
                header='Warnung'
                text={infoText}
                onClick={() => bookTransaction({
                    batch:batch,
                    article: article,
                    inventoryCount: bookIn ? inventoryCount : -inventoryCount,
                    toLocation: toLocation,
                    assignedCustomer: assignedCustomer,
                    assignedSupplier: assignedSupplier
                })}
            />

            {activeTransaction ? <TransactionDialog
                open={transactionDialog}
                close={closeTransactionDialog}
                transaction={activeTransaction}
            /> : null}

            <SecureYesCancelDialog
                open={deleteTransactionDialog}
                close={closeDeleteTransactionDialog}
                header='Löschen'
                text='Wollen Sie die Transaktion wirklich löschen?'
                onClick={() => deleteTransaction(activeTransaction)}
                showMessage={props.showMessage}
            />

            <SearchTransactionDialog
                open={searchTransactionDialog}
                close={closeSearchTransactionDialog}
                showMessage={props.showMessage}
                logout={props.logout}
                setLoading={props.setLoading}
                articles={props.articles}
                customers={props.customers}
                suppliers={props.suppliers}
                getArticleInformation={props.getArticleInformation}
                showTransactionDialog={showTransactionDialog}
                showDeleteTransactionDialog={showDeleteTransactionDialog}
                removeTransactionFromHistory={props.removeTransactionFromHistory}

                // Batches
                batches={props.batches}
                addBatchToList={props.addBatchToList}
                updateBatchInList={props.updateBatchInList}
                removeBatchFromList={props.removeBatchFromList}
            />

            <div className="transaction-history">
                {props.transactions ? props.transactions.map((transaction, index) => (
                    <TransactionItem
                        key={transaction.id}
                        index={index}
                        transaction={transaction}
                        showTransactionDialog={showTransactionDialog}
                        showDeleteTransactionDialog={showDeleteTransactionDialog}
                    />
                )) : null}
            </div>

            <SearchIcon onClick={showSearchTransactionDialog} className="icon search-icon" data-tooltip-id="article-movement-tooltip"
                        data-tooltip-place="top" data-tooltip-content="Transaktionssuche"/>

            <WarningAmberIcon className="icon warning-icon" onClick={generateLowMinimumStockList}
                              data-tooltip-id="article-movement-tooltip" data-tooltip-place="top"
                              data-tooltip-content="Unterschrittene Mindestbestände exportieren (Excel)"/>

            {props.user && props.user.role && props.user.role === "ADMIN" ? <FormControlLabel
                className='locked-transactions-fcl'
                control={
                    <Switch
                        checked={transactionsActive}
                        onChange={() => updateTransactionsActive(!transactionsActive)}
                        color="primary"
                    />
                }
                label={transactionsActive ? "Transaktionen aktiv" : "Transaktionen gesperrt"}
            /> : null}

            <h1>Inventar</h1>
            <div className='inventory-actions'>
                <div className="category-holder">
                    <div>
                        <Autocomplete
                            disabled={assignedSupplier || toLocation}
                            size="small"
                            value={assignedCustomer ? assignedCustomer.name : ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    customerOnChangeHandler(1,{
                                        name: newValue,
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    customerOnChangeHandler(2,{
                                        name: newValue.inputValue,
                                    });
                                } else {
                                    customerOnChangeHandler(3,newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const {inputValue} = params;
                                if (inputValue.length === 0) {
                                    return [];
                                }
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.name);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        name: `Erstellen "${inputValue}"`,
                                    });
                                }

                                return filtered.slice(0, 20);
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="customers"
                            className='field'
                            options={props.customers}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                            }}

                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            freeSolo
                            renderInput={(params) => (
                                <TextField size="small" {...params}
                                           inputProps={{...params.inputProps, maxLength: 45}}
                                           InputLabelProps={{shrink: true}}
                                           inputRef={customerRef}
                                           onChange={customerNameOnChangeHandler}
                                           id="customer"
                                           label="Kundenname"/>
                            )}
                        />
                        {assignedCustomer && !assignedCustomer.id ? <TextField size="small" className='field'
                                                                               label="Kundennr"
                                                                               value={assignedCustomer && assignedCustomer.nr ? assignedCustomer.nr : ''}
                                                                               inputProps={{maxLength: 45}}
                                                                               data-tooltip-id="article-movement-tooltip"
                                                                               data-tooltip-place="top"
                                                                               data-tooltip-content="Wird automatisch vergeben, wenn leer gelassen"
                                                                               type="number"
                                                                               InputLabelProps={{shrink: true}}
                                                                               onChange={customerNrOnChangeHandler}/> : null}
                    </div>
                </div>
                {props.company && props.company.abbreviation && props.company.abbreviation !== "BERGE" && props.company.abbreviation !== "LEENG" ? <div className="category-holder">
                    <div>
                        <Autocomplete
                            disabled={assignedCustomer || toLocation !== null}
                            size="small"
                            value={assignedSupplier ? assignedSupplier.name : ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    supplierOnChangeHandler({
                                        name: newValue,
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    supplierOnChangeHandler({
                                        name: newValue.inputValue,
                                    });
                                } else {
                                    supplierOnChangeHandler(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const {inputValue} = params;
                                if (inputValue.length === 0) {
                                    return [];
                                }
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.name);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        name: `Erstellen "${inputValue}"`,
                                    });
                                }

                                return filtered.slice(0, 20);
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="suppliers"
                            className='field'
                            options={props.suppliers}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            freeSolo
                            renderInput={(params) => (
                                <TextField id="supplier" size="small" {...params}
                                           inputProps={{...params.inputProps, maxLength: 45}}
                                           InputLabelProps={{shrink: true}}
                                           onKeyDown={_handleKeyDown}
                                           label="Lieferantenname"/>
                            )}
                        />
                        {assignedSupplier && !assignedSupplier.id ? <TextField size="small" className='field'
                                                                               label="Lieferantennummer"
                                                                               value={assignedSupplier && assignedSupplier.nr ? assignedSupplier.nr : ''}
                                                                               inputProps={{maxLength: 45}}
                                                                               data-tooltip-id="article-movement-tooltip"
                                                                               data-tooltip-place="top"
                                                                               data-tooltip-content="Wird automatisch vergeben, wenn leer gelassen"
                                                                               type="number"
                                                                               InputLabelProps={{shrink: true}}
                                                                               onChange={supplierNrOnChangeHandler}/> : null}
                    </div>
                </div> : null}
                {props.company && props.company.abbreviation && props.company.abbreviation !== "BERGE" && props.company.abbreviation !== "LEENG" ? <div className="category-holder">
                    <Autocomplete
                        disabled={bookIn || assignedSupplier || assignedCustomer}
                        size="small"
                        value={toLocation ? toLocation.name : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                toLocationOnChangeHandler({
                                    name: newValue,
                                });
                            } else {
                                toLocationOnChangeHandler(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const {inputValue} = params;
                            if (inputValue.length === 0) {
                                return [];
                            }
                            // Suggest the creation of a new value

                            return filtered.slice(0, 20);
                        }}
                        selectOnFocus
                        clearOnBlur
                        id="customers"
                        className='field'
                        options={props.locations}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}

                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 45}}
                                       InputLabelProps={{shrink: true}}
                                       label="nach Standort"/>
                        )}
                    />
                </div> : null}
                <div className="category-holder">
                    <Autocomplete
                        size="small"
                        value={article && article.nr ? article.nr : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                articleOnChange({
                                    nr: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                articleOnChange({
                                    nr: newValue.inputValue
                                }, true);
                            } else {
                                articleOnChange(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const {inputValue} = params;
                            if (inputValue.length === 0) {
                                return [];
                            }
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    nr: `${inputValue}`,
                                    name: "Erstellen: "
                                });
                            }
                            return filtered.slice(0, 20);
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        className='field'
                        options={batchArticles !== null && batchArticles.length > 0 ? batchArticles : props.articles}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.id + option.nr + option.name + option.pcn + option.ean + option.refNr;
                        }}
                        renderOption={(props, option) => <li
                            key={option.name} {...props}>{option.name + " (" + option.nr + ")"}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 255}}
                                       InputLabelProps={{shrink: true}}
                                       inputRef={articleRef}
                                       onChange={articleNrOnChangeHandler}
                                       label="Artikelnummer" value={article && article.name ? article.name : ''}/>
                        )}
                    />
                    <TextField size="small" className='field' disabled={!(article && article.nr && !article.id)}
                               label="Artikelname"
                               value={article && article.name ? article.name : ''}
                               inputProps={{maxLength: 255}}
                               InputLabelProps={{shrink: true}}
                               onChange={articleNameOnChangeHandler}/>
                </div>
                <div className="category-holder">
                    <Autocomplete
                        id="batchNr"
                        size="small"
                        value={batch && batch.nr ? batch.nr : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                batchOnChange({
                                    nr: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                batchOnChange({
                                    nr: newValue.inputValue
                                }, true);
                            } else {
                                batchOnChange(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const {inputValue} = params;
                            if (inputValue.length === 0) {
                                return [];
                            }
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.nr);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    nr: `Erstellen "${inputValue}"`,
                                });
                            }
                            return filtered.slice(0, 20);
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        className='field'
                        options={articleBatches !== null && articleBatches.length > 0 ? articleBatches : props.batches}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.nr;
                        }}
                        renderOption={(props, option) => <li
                            key={option.nr} {...props}>{option.nr}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 255}}
                                       InputLabelProps={{shrink: true}}
                                       inputRef={batchRef}
                                       onChange={batchNrOnChangeHandler}
                                       label="Chargennummmer" value={batch && batch.nr ? batch.nr : ''}/>
                        )}
                    />
                    <TextField inputProps={{maxLength: 255}} disabled={batch && batch.id} className='field'
                               size="small" onChange={udiOnChangeHandler} label="UDI"
                               InputLabelProps={{shrink: true}}
                               value={batch && batch.udi ? batch.udi : ''}/>
                </div>
                <div className="category-holder">
                    <TextField inputProps={{maxLength: 45}} disabled={batch && batch.id} className='field'
                               type='date'
                               size="small" onChange={expirationDateOnChangeHandler} label="Mindesthaltbarkeitsdatum"
                               InputLabelProps={{shrink: true}}
                               value={batch && batch.expirationDate ? batch.expirationDate : ''}/>
                </div>
                <div className="category-holder">
                    <TextField id="inventoryCount" size="small" label='Anzahl' onChange={inventoryCountOnChangeHandler}
                               onKeyDown={_handleKeyDown}
                               InputLabelProps={{shrink: true}}
                               inputRef={inventoryCountRef}
                               value={inventoryCount}
                               className='field small-field' type="number"/>
                    <FormControlLabel
                        className='switch'
                        control={
                            <Switch
                                checked={bookIn}
                                onChange={toggleBook}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={bookText}/>
                </div>
            </div>

            <ToolButton disabled={!transactionsActive} main onClick={bookTransactionOnClickHandler}
                        className='book-button'>Verbuchen</ToolButton>
        </div>
    )
}

export default ArticleMovement;