import React, {Component} from "react";
import './WarehouseDashboard.css';

//Components

class WarehouseDashboard extends Component {
    render() {
        return (
            <div className='warehouse-dashboard'>
                <h1>Willkommen {this.props.user !== null ? this.props.user.username : 'Lädt...'}!</h1>
                <h2>Standort {this.props.user !== null ? this.props.user.assignedLocation.name : 'Lädt...'}</h2>
            </div>
        )
    }
}

export default WarehouseDashboard;