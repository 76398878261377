import React, {Component} from 'react';
import './LoadingBackdrop.css';

//Components
import CircularProgress from '@mui/material/CircularProgress';

class LoadingBackdrop extends Component {

    render() {
        return (
            <div className="loading-backdrop">
                <CircularProgress className='load-icon'/>
            </div>
        )
    }
}

export default LoadingBackdrop;