import React, {Component} from "react";
import './AccountingActionsMenu.css';

//Components
import {NavLink, Route} from "react-router-dom";

class AccountingActionsMenu extends Component {
    render() {
        return (
        <div className='accounting-actions-menu'>
            <Route path='/accounting-manager/master-data'>
                <menu className='master-data-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/accounting-manager/master-data'><h2>Artikel</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/master-data/article-groups'><h2>Artikelgruppen</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/master-data/locations'><h2>Standorte</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/master-data/customers'><h2>Kunden</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/master-data/suppliers'><h2>Lieferanten</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/master-data/attributes'><h2>Attribute</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/master-data/message-templates'><h2>Nachr. Vorl.</h2></NavLink>
                </menu>
            </Route>
            <Route path='/accounting-manager/administration'>
                <menu className='administration-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/warehouse-manager/administration'><h2>Unternehmensverwaltung</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/administration/administration-settings'><h2>Unternehmenseinstellungen</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/administration/user-administration'><h2>Benutzer</h2></NavLink>
                </menu>
            </Route>
            </div>
        )
    }
}

export default AccountingActionsMenu;