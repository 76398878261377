import React, {Component} from 'react';
import './PositionOverviewItem.css';

//Components
import Paper from '@mui/material/Paper';
import helperFunctions from "../HelperFunctions";
import ArticleIcon from "@mui/icons-material/Article";
import { Tooltip } from 'react-tooltip'

class PositionOverviewItem extends Component {
    render() {

        let statusStyle;
        let style;

        if((this.props.kind === 0 || this.props.kind === 1) && this.props.position.state) {
            switch (this.props.position.state) {
                case "OFFER_CREATION":
                    statusStyle = {
                        background:"lightgray"
                    }
                    break;
                case "NOT_YET_APPROVED":
                    statusStyle = {
                        background:"lightcoral"
                    }
                    break;
                case "WORK_IN_PROGRESS":
                    statusStyle = {
                        background:"orange"
                    }
                    break;
                case "COMPLETED":
                    statusStyle = {
                        background:"lightgreen"
                    }
                    break;

                case "REQUESTED":
                    statusStyle = {
                        background:"lightgray"
                    }
                    break;
                case "CONFIRMED":
                    statusStyle = {
                        background:"lightcoral"
                    }
                    break;
                case "REJECTED":
                    statusStyle = {
                        background:"black"
                    }
                    break;
                case "GOODS_RECEIVED":
                    statusStyle = {
                        background:"lightgreen"
                    }
                    break;
                default:
                    break;
            }
        }

        if(this.props.position.locked) {
            style = {
                background:"lightgray"
            }
        }

        return (
            <Paper style={style} elevation={3} className='position-overview-item'>
                <Tooltip id="position-overview-item-tooltip"/>
                {this.props.kind === 0 || this.props.kind === 1 ?
                    <div style={statusStyle} className="status"/> : null}
                <ul className="information-holder">
                    <li><p
                        className="info-text">{this.props.position.commissionNr ? helperFunctions.formatString(this.props.position.commissionNr,18) : "-"}
                    </p></li>
                    <li><p
                        className="info-text">{this.props.position.orderNr ? helperFunctions.formatString(this.props.position.orderNr,18) : "-"}
                    </p></li>
                    {this.props.kind === 0 ? <li><p
                        className="info-text">{this.props.position.assignedCustomer && this.props.position.assignedCustomer.name ? helperFunctions.formatString(this.props.position.assignedCustomer.name,18) : "-"}
                    </p></li> : null}
                    {this.props.kind === 1 ? <li><p
                        className="info-text">{this.props.position.assignedSupplier && this.props.position.assignedSupplier.name ? helperFunctions.formatString(this.props.position.assignedSupplier.name,18) : "-"}
                    </p></li> : null}
                    <li><p
                        className="info-text">{this.props.position.article && this.props.position.article.name ? helperFunctions.formatString(this.props.position.article.name,18) : "-"}
                    </p></li>
                    <li><p
                        className="info-text">{this.props.position.orderDate ? helperFunctions.formatDate(this.props.position.orderDate) : "-"}
                    </p></li>
                    <li><p
                        className="info-text">{this.props.position.deliveryDate ? helperFunctions.formatDate(this.props.position.deliveryDate) : "-"}
                    </p></li>
                    <li><p
                        className="info-text">{this.props.position.note ? helperFunctions.formatString(this.props.position.note,18) : "-"}
                    </p></li>
                </ul>
                <ArticleIcon data-tooltip-id="position-overview-item-tooltip" data-tooltip-place="top" data-tooltip-content="Anzeigen" onClick={() => this.props.showPositionDialog(this.props.position)} className='icon position-dialog-icon'/>
            </Paper>
        )
    }
}

export default PositionOverviewItem;