import React, {Component} from 'react';
import './FileItem.css';

//Components
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Paper from '@mui/material/Paper';
import { Tooltip } from 'react-tooltip'
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import HelperFunctions from "../HelperFunctions";
import OutlookLogo from "../../images/outlook_logo.png";
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from "@mui/icons-material/Download";

class FileItem extends Component {
    render() {

        let disabledStyle;

        if(this.props.locked) {
            disabledStyle = {
                color:"gray"
            }
        }

        let infoText = "";

        if(this.props.fileStorageLocation && this.props.fileStorageLocation.name) {
            infoText = infoText + "Name: " + this.props.fileStorageLocation.name;
        }

        if(this.props.fileStorageLocation && this.props.fileStorageLocation.documentDate) {
            if(infoText !== "") {
                infoText = infoText + "<br/>";
            }
            infoText = infoText + "Dokumentendatum: " + HelperFunctions.formatDate(this.props.fileStorageLocation.documentDate);
        }

        if(this.props.fileStorageLocation && this.props.fileStorageLocation.uploadDateTime) {
            if(infoText !== "") {
                infoText = infoText + "<br/>";
            }
            infoText = infoText + "Hochladezeitpunkt: " + HelperFunctions.formatDateTime(this.props.fileStorageLocation.uploadDateTime);
        }

        return (
            <Paper onClick={(e) => e.stopPropagation()} elevation={3} className='file-item'>
                <Tooltip id="file-item-tooltip"/>
                <DeleteIcon style={disabledStyle} data-tooltip-id="file-item-tooltip" data-tooltip-place="top" data-tooltip-content="Löschen" onClick={!this.props.locked ? this.props.deleteFile : null} className='icon delete-icon'/>
                <AttachEmailIcon data-tooltip-id="file-item-tooltip" data-tooltip-place="top" data-tooltip-content="Zum Anhang hinzufügen" onClick={this.props.sendFileAsEmail} className='icon attach-icon'/>
                <img src={OutlookLogo} className="icon outlook-icon" data-tooltip-id="file-item-tooltip" data-tooltip-place="top" onClick={this.props.openInOutlookOnClickHandler} data-tooltip-content="In Outlook öffnen" alt="Outlook-Logo"/>
                <DownloadIcon data-tooltip-id="file-item-tooltip" data-tooltip-place="top" data-tooltip-content="Herunterladen" onClick={() => this.props.downloadFile(this.props.fileStorageLocation)} className='icon display-icon'/>
                <PictureAsPdfIcon data-tooltip-id="file-item-tooltip" data-tooltip-place="top" data-tooltip-content="Anzeigen" onClick={this.props.displayFile} className='icon display-icon'/>
                <p className='file-name'>{HelperFunctions.formatString(this.props.fileStorageLocation.name,70)} {infoText !== "" ? <InfoIcon data-tooltip-id="file-item-tooltip" data-tooltip-place="top" data-tooltip-html={infoText} className="icon info-icon"/> : null}</p>
            </Paper>
        )
    }
}

export default FileItem;