import React, { useState, useEffect, useRef } from 'react';
import './StockTakingInventoryDialog.css';

//Components
import { Tooltip } from 'react-tooltip'
import Dialog from "@mui/material/Dialog";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import CheckIcon from '@mui/icons-material/Check';
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import api from "../../../../../../../../api";
import YesCancelDialog from "../../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import BatchItemSmall from "../../../../../../../../reusable/BatchItemSmall/BatchItemSmall";
import BatchDialog from "../../Batches/BatchDialog/BatchDialog";
import HelperFunctions from "../../../../../../../../reusable/HelperFunctions";

const StockTakingInventoryDialog = (props) => {

    const filter = createFilterOptions();

    const [stockTakingInventory,setStockTakingInventory] = useState(null);
    const [deleteStockTakingInventoryDialog,setDeleteStockTakingInventoryDialog] = useState(false);
    const [activeBatch,setActiveBatch] = useState(null);
    const [batchDialog,setBatchDialog] = useState(false);
    const [stockTakingInventoryExists, setStockTakingInventoryExists] = useState(false);

    useEffect(() => {
        if(props.stockTakingInventory && props.stockTakingInventory.article && props.stockTakingInventory.article.id) {
            loadBatchesByStockTakingInventory(props.stockTakingInventory);
        }else{
            setStockTakingInventory(props.stockTakingInventory);
        }
    }, []);

    const batchOnChange = (batch) => {
        let updatedStockTakingInventory = { ...stockTakingInventory };
        updatedStockTakingInventory.batch = batch;
        // Barcode Scanner Funktion: Wenn Charge mit dieser Nummer gefunden, automatisch einfügen
        if(updatedStockTakingInventory && updatedStockTakingInventory.batch && updatedStockTakingInventory.batch.nr && !updatedStockTakingInventory.batch.id) {
            for(let i = 0; i < props.batches.length; i++) {
                if(updatedStockTakingInventory.batch.nr !== null && props.batches[i].nr === batch.nr) {
                    updatedStockTakingInventory.batch = props.batches[i];
                    break;
                }
            }
        }
        if(updatedStockTakingInventory.batch && updatedStockTakingInventory.batch.id && updatedStockTakingInventory.batch.article) {
            updatedStockTakingInventory.article = updatedStockTakingInventory.batch.article;
        }
        setStockTakingInventory(updatedStockTakingInventory);
        if(updatedStockTakingInventory.batch) {
            props.getArticleInformation(updatedStockTakingInventory.batch.article);
        }else{
            props.getArticleInformation(null);
        }
        props.displayBatchInformation(updatedStockTakingInventory.batch);
    }

    const batchBatchInventoryToggleEdit = (batchIndex,batchInventoryIndex) => {
        let updatedStockTakingInventory = { ...stockTakingInventory };
        updatedStockTakingInventory.batches[batchIndex].batchInventories[batchInventoryIndex].edit = !updatedStockTakingInventory.batches[batchIndex].batchInventories[batchInventoryIndex].edit;
        setStockTakingInventory(updatedStockTakingInventory);
    }

    const inventoryCountFreeOnChangeHandler = (e,batchIndex,batchInventoryIndex) => {
        let updatedStockTakingInventory = { ...stockTakingInventory };
        updatedStockTakingInventory.batches[batchIndex].batchInventories[batchInventoryIndex].inventoryCountFree = e.target.value;
        setStockTakingInventory(updatedStockTakingInventory);
    }

    const articleOnChange = (article) => {
        let updatedStockTakingInventory = { ...stockTakingInventory };
        updatedStockTakingInventory.article = article;
        setStockTakingInventory(updatedStockTakingInventory);
        if(updatedStockTakingInventory && updatedStockTakingInventory.article && updatedStockTakingInventory.article.id) {
            loadBatchesByStockTakingInventory(updatedStockTakingInventory);
            checkIfStockTakingInventoryExistsByArticle(updatedStockTakingInventory.article);
        }
    }

    const articleNrOnChangeHandler = (e) => {
        let updatedStockTakingInventory = { ...stockTakingInventory };
        if(updatedStockTakingInventory.article) {
            updatedStockTakingInventory.article.nr = e.target.value;
        }else{
            updatedStockTakingInventory.article = {
                nr: e.target.value
            };
        }

        let information = HelperFunctions.getInformationByUDI(e.target.value,props.articles,props.batches);
        if(information) {
            if(updatedStockTakingInventory) {
                updatedStockTakingInventory.article = information.article;
                let batch = information.batch;
                batch.batchInventories = [
                    {
                        inventory: {
                            location: props.user.assignedLocation
                        }
                    }
                ]
                updatedStockTakingInventory.batches = [batch];
            }else{
                let batch = information.batch;
                batch.batchInventories = [
                    {
                        inventory: {
                            location: props.user.assignedLocation
                        }
                    }
                ]
                updatedStockTakingInventory = {
                    article: information.article,
                    batches: [batch]
                };
            }
        }
        setStockTakingInventory(updatedStockTakingInventory);
    }

    const loadBatchesByStockTakingInventory = (stockTakingInventory) => {
        if(stockTakingInventory && stockTakingInventory.article && stockTakingInventory.article.id) {
            api.get("/batches/search/article/" + stockTakingInventory.article.id)
                .then(response => {
                    const updatedStockTakingInventory = { ...stockTakingInventory };
                    updatedStockTakingInventory.batches = response.data;
                    setStockTakingInventory(updatedStockTakingInventory);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response && error.response.data) {
                        props.showMessage(2,error.response.data);
                    }else{
                        props.showMessage(2, "Etwas ist schiefgelaufen");
                    }
                });
        }else{
            setStockTakingInventory(stockTakingInventory);
        }
    }

    const articleNameOnChangeHandler = (e) => {
        let updatedStockTakingInventory = { ...stockTakingInventory };
        updatedStockTakingInventory.article = {
            name:e.target.value
        }
        setStockTakingInventory(updatedStockTakingInventory);
    }

    const inventoryCountOnChangeHandler = (e) => {
        let updatedStockTakingInventory = { ...stockTakingInventory };
        updatedStockTakingInventory.inventoryCount = e.target.value;
        setStockTakingInventory(updatedStockTakingInventory);
    }

    const bookStockTakingInventoryOnClickHandler = (stockTakingInventory) => {
        if(validateStockTakingInventory(stockTakingInventory)) {
            createOrUpdateBookStockTakingInventory(stockTakingInventory);
        }
    }

    const validateStockTakingInventory = (stockTakingInventory) => {
        if(!stockTakingInventory) {
            props.showMessage(2,"Es ist keine Bestandsbuchung gesetzt");
            return false;
        }
        if(!stockTakingInventory.article) {
            props.showMessage(2,"Jeder Bestandsbuchung muss ein Artikel zugewiesen sein");
            return false;
        }
        if(!stockTakingInventory.article.id && !stockTakingInventory.article.name) {
            props.showMessage(2,"Dem Artikel muss ein Name zugewiesen sein");
            return false;
        }
        if(!stockTakingInventory.inventoryCount || stockTakingInventory.inventoryCount < 0) {
            props.showMessage(2,"Jeder Bestandsbuchung muss ein Bestand größer gleich 0 zugewiesen sein");
            return false;
        }
        if(stockTakingInventory.batches) {
            let sum = 0;
            for(let i = 0; i < stockTakingInventory.batches.length; i++) {
                if(!stockTakingInventory.batches[i].nr) {
                    props.showMessage(2,"Jeder Charge muss eine Nummer zugewiesen werden");
                    return false;
                }
                if(!stockTakingInventory.batches[i].inventoryCountTotal === null) {
                    props.showMessage(2,"Jeder Charge muss eine Gesamtanzahl zugewiesen werden");
                    return false;
                }
                for(let j = 0; j < stockTakingInventory.batches[i].batchInventories.length; j++) {
                    if(stockTakingInventory.batches[i].batchInventories[j].inventoryCountFree === null) {
                        props.showMessage(2,"Jeder Charge muss eine vorhandene Anzahl zugewiesen werden");
                        return false;
                    }
                    if(stockTakingInventory.batches[i].batchInventories[j].inventoryCountFree < 0 || stockTakingInventory.batches[i].inventoryCountFree > stockTakingInventory.batches[i].inventoryCountTotal) {
                        props.showMessage(2,"Die vorhandene Anzahl muss größer gleich 0 und kleiner gleich die Gesamtanzahl sein");
                        return false;
                    }
                }
            }
        }
        return true;
    }

    const checkIfStockTakingInventoryExistsByArticle = (article) => {
        api.get("/stock-taking/exists/" + article.id)
            .then(response => {
                console.log(response.data)
                setStockTakingInventoryExists(response.data);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                }else{
                    props.showMessage(2, "Etwas ist schiefgelaufen");
                }
            });
    }

    const createOrUpdateBookStockTakingInventory = (stockTakingInventory) => {
        api.put("/stock-taking/inventory",stockTakingInventory)
            .then(response => {
                if(stockTakingInventory && stockTakingInventory.id) {
                    props.updateStockTakingInventoryInList(response.data);
                }else{
                    props.addStockTakingInventoryToList(response.data);
                }
                clearFields();
                props.close();
                props.showMessage(0,"Die Bestandsbuchung wurde gespeichert");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                }else{
                    props.showMessage(2, "Etwas ist schiefgelaufen");
                }
            });
    }

    const deleteStockTakingInventory = (stockTakingInventory) => {
        api.delete("/stock-taking/inventory/" + stockTakingInventory.id)
            .then(response => {
                props.removeStockTakingInventoryFromList(stockTakingInventory);
                clearFields();
                props.close();
                props.showMessage(0,"Die Bestandsbuchung wurde gelöscht");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                }else{
                    props.showMessage(2, "Etwas ist schiefgelaufen");
                }
            });
    }

    const clearFields = () => {
        setStockTakingInventory({
            article:null,
            inventoryCount:1
        });
        props.setActiveStockTakingInventory(null);
    }

    // Batches

    const addBatchToList = (batch) => {
        const updatedStockTakingInventory = { ...stockTakingInventory };
        if(!updatedStockTakingInventory.batches) {
            updatedStockTakingInventory.batches = [];
        }
        updatedStockTakingInventory.batches.push(batch);
        setStockTakingInventory(updatedStockTakingInventory);
        props.addBatchToList(batch);
    }

    const updateBatchInList = (batch) => {
        const updatedStockTakingInventory = { ...stockTakingInventory };
        for(let i = 0; i < updatedStockTakingInventory.batches.length; i++) {
            if(updatedStockTakingInventory.batches[i].id === batch.id) {
                updatedStockTakingInventory.batches[i] = batch;
                props.updateBatchInList(batch);
                break;
            }
        }
        setStockTakingInventory(updatedStockTakingInventory);
    }

    const removeBatchFromList = (batch) => {
        const updatedStockTakingInventory = { ...stockTakingInventory };
        for(let i = 0; i < updatedStockTakingInventory.batches.length; i++) {
            if (updatedStockTakingInventory.batches[i].id === batch.id) {
                updatedStockTakingInventory.batches.splice(i,1)
                setStockTakingInventory(updatedStockTakingInventory);
                props.removeBatchFromList(batch);
                return;
            }
        }
    }

    const showDeleteStockTakingInventoryDialog = () => {
        setDeleteStockTakingInventoryDialog(true);
    }

    const closeDeleteStockTakingInventoryDialog = () => {
        setDeleteStockTakingInventoryDialog(false);
    }

    const showBatchDialog = (batch) => {
        setActiveBatch(batch);
        setBatchDialog(true);
    }

    const closeBatchDialog = () => {
        setBatchDialog(false);
        setActiveBatch(null);
    }

    const blankBatch = {
        inventoryCountTotal: 1,
        inventoryCountFree: 1,
        batchInventories: [
            {
                location: props.user.assignedLocation,
                inventoryCountFree: 0
            }
        ]
    }

    return (
        <Dialog scroll='body' maxWidth={"md"} open={props.open} onClose={props.close} className='stock-taking-inventory-dialog'>
            <Tooltip id="stock-taking-inventory-dialog-tooltip"/>
            {activeBatch && batchDialog ? <BatchDialog
                open={batchDialog}
                close={closeBatchDialog}
                showMessage={props.showMessage}
                logout={props.logout}
                setLoading={props.setLoading}
                batch={activeBatch}
                articles={props.articles}
                getArticleInformation={props.getArticleInformation}
                addBatchToList={addBatchToList}
                updateBatchInList={updateBatchInList}
                removeBatchFromList={removeBatchFromList}
                articleGiven={true}
                user={props.user}
            /> : null}

            <YesCancelDialog
                open={deleteStockTakingInventoryDialog}
                close={closeDeleteStockTakingInventoryDialog}
                header='Bestandsbuchung löschen'
                text='Wollen Sie die Bestandsbuchung wirklich löschen? Damit wird lediglich keine Buchung vorgenommen. Vorhandene Bestände bleiben bestehen.'
                onClick={() => deleteStockTakingInventory(stockTakingInventory)}
            />

            <DialogContent>
                <DialogTitle>Bestandsbuchung</DialogTitle>
                <Autocomplete
                    id="articleNr"
                    size="small"
                    disabled={stockTakingInventory && stockTakingInventory.id}
                    value={stockTakingInventory && stockTakingInventory.article && stockTakingInventory.article.nr ? stockTakingInventory.article.nr : ''}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            articleOnChange({
                                nr: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            articleOnChange({
                                nr: newValue.inputValue
                            }, true);
                        } else {
                            articleOnChange(newValue);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const {inputValue} = params;
                        if (inputValue.length === 0) {
                            return [];
                        }
                        return filter(options, params).slice(0, 10);
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    className='field'
                    options={props.articles}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.nr;
                    }}
                    renderOption={(props, option) => <li
                        key={option.name} {...props}>{option.nr ? option.name + " (" + option.nr + ")" : option.name}</li>}
                    freeSolo
                    renderInput={(params) => (
                        <TextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 45}}
                                   InputLabelProps={{shrink: true}}
                                   label="Artikelnummer"
                                   onChange={articleNrOnChangeHandler}
                                   value={stockTakingInventory && stockTakingInventory.article && stockTakingInventory.article.name ? stockTakingInventory.article.name : ''}/>
                    )}
                />
                {stockTakingInventoryExists ?
                    <p className="info-text">Es existiert bereits eine Inventur-Buchung mit diesem Aritkel.<br/>Eine erneute Buchung überschreibt die vorhandene Buchung.</p>
                    : null}
                <TextField size="small" className='field'
                           disabled={!(stockTakingInventory && stockTakingInventory.article && stockTakingInventory.article.nr && !stockTakingInventory.article.id)}
                           label="Artikelname"
                           value={stockTakingInventory && stockTakingInventory.article && stockTakingInventory.article.name ? stockTakingInventory.article.name : ''}
                           inputProps={{maxLength: 45}}
                           InputLabelProps={{shrink: true}}
                           onChange={articleNameOnChangeHandler}/>
                <TextField inputProps={{maxLength: 45}}
                           disabled={stockTakingInventory && stockTakingInventory.article === null}
                           className='field' type="number"
                           size="small" onChange={inventoryCountOnChangeHandler} label="Bestand"
                           InputLabelProps={{shrink: true}}
                           value={stockTakingInventory && stockTakingInventory.inventoryCount ? stockTakingInventory.inventoryCount : ''}/>
                {stockTakingInventory && stockTakingInventory.article && stockTakingInventory.article.id ? <div className="batches-holder-content">
                    <p className="batches-holder-title">Zugewiesene Chargen (mit vorhandenem Bestand)</p>
                    <Paper onClick={() => showBatchDialog(blankBatch)} elevation={3} className='add-paper-button'>
                        <AddIcon className="icon add-icon"/>
                    </Paper>
                    <div className="batches-holder">
                        {stockTakingInventory && stockTakingInventory.batches ? stockTakingInventory.batches.map((batch, index) => (
                            <BatchItemSmall
                                key={batch.id}
                                index={index}
                                batch={batch}
                                toggleEdit={batchBatchInventoryToggleEdit}
                                inventoryCountFreeOnChangeHandler={inventoryCountFreeOnChangeHandler}
                            />
                        )) : null}
                    </div>
                </div> : null}
            </DialogContent>
            <DialogActions>
                {stockTakingInventory && stockTakingInventory.id ?
                    <ToolButton onClick={showDeleteStockTakingInventoryDialog}
                                className='save-button'>Löschen</ToolButton> : null}
                <ToolButton main onClick={() => bookStockTakingInventoryOnClickHandler(stockTakingInventory)}
                            className='save-button'>Verbuchen</ToolButton>
            </DialogActions>
        </Dialog>
    )
}

export default StockTakingInventoryDialog;