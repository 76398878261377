import React, {Component} from "react";
import './OrderManagement.css';

//Components
import {Route} from "react-router-dom";
import Orders from "./Orders/Orders";

class OrderManagement extends Component {
    render() {
        return (
            <div className='order-management'>
                <Route exact path='/*/order-management/'>
                    <Orders
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        items={this.props.customerOrders}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}
                        removeItemFromList={this.props.removeCustomerOrderFromList}
                        addItemToList={this.props.addCustomerOrderToList}
                        user={this.props.user}
                        countries={this.props.countries}
                        kind={0}
                        formatDate={this.props.formatDate}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}
                        messageTemplates={this.props.messageTemplates}

                        generateDocument={this.props.generateDocument}
                        bookInventoryTransactions={this.props.bookCustomerOrderInventoryTransactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}
                        round={this.props.round}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}
                        attributes={this.props.attributes}
                        articles={this.props.articles}
                        company={this.props.company}
                        articleGroups={this.props.articleGroups}
                        addArticleToList={this.props.addArticleToList}
                        customers={this.props.customers}
                        addCustomerToList={this.props.addCustomerToList}
                        suppliers={this.props.suppliers}
                        addSupplierToList={this.props.addSupplierToList}

                        //Email
                        sendEmail={this.props.sendEmail}
                        addEmailAttachment={this.props.addEmailAttachment}
                        emailAttachments={this.props.emailAttachments}
                        removeEmailAttachment={this.props.removeEmailAttachment}

                        //Filters
                        filters={this.props.filters}
                        addFilterToList={this.props.addFilterToList}
                        removeFilterFromList={this.props.removeFilterFromList}
                        updateDefaultCustomerOrderFilter={this.props.updateDefaultCustomerOrderFilter}
                        updateDefaultSupplierOrderFilter={this.props.updateDefaultSupplierOrderFilter}
                    />
                </Route>
                <Route exact path='/*/order-management/supplier-orders'>
                    <Orders
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        items={this.props.supplierOrders}
                        removeItemFromList={this.props.removeSupplierOrderFromList}
                        addItemToList={this.props.addSupplierOrderToList}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}
                        user={this.props.user}
                        countries={this.props.countries}
                        kind={1}
                        attributes={this.props.attributes}
                        bookInventoryTransactions={this.props.bookSupplierOrderInventoryTransactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}
                        round={this.props.round}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}
                        articles={this.props.articles}
                        company={this.props.company}
                        articleGroups={this.props.articleGroups}
                        addArticleToList={this.props.addArticleToList}
                        customers={this.props.customers}
                        addCustomerToList={this.props.addCustomerToList}
                        suppliers={this.props.suppliers}
                        addSupplierToList={this.props.addSupplierToList}
                        updateDefaultCustomerOrderFilter={this.props.updateDefaultCustomerOrderFilter}
                        updateDefaultSupplierOrderFilter={this.props.updateDefaultSupplierOrderFilter}
                        formatDate={this.props.formatDate}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}
                        messageTemplates={this.props.messageTemplates}

                        //Email
                        sendEmail={this.props.sendEmail}
                        addEmailAttachment={this.props.addEmailAttachment}
                        emailAttachments={this.props.emailAttachments}
                        removeEmailAttachment={this.props.removeEmailAttachment}

                        //Filters
                        filters={this.props.filters}
                        addFilterToList={this.props.addFilterToList}
                        removeFilterFromList={this.props.removeFilterFromList}
                    />
                </Route>
            </div>
        );
    }
}

export default OrderManagement;