import React, { useState, useEffect, useRef } from 'react';
import './TransactionDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import { Tooltip } from 'react-tooltip'
import HelperFunctions from "../HelperFunctions";

const TransactionDialog = (props) => {

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={props.close} className='transaction-dialog'>
            <Tooltip id="transaction-dialog-tooltip"/>
            <div className="surrounder">
                <h1>Transaktionsdetails</h1>
                <p>Chargennummer: {props.transaction.batch && props.transaction.batch.nr ? props.transaction.batch.nr : "-"}</p>
                <p>Mindesthaltbarkeitsdatum: {props.transaction.batch && props.transaction.batch.expirationDate ? HelperFunctions.formatDate(props.transaction.batch.expirationDate) : "-"}</p>
                <p>Artikelnummer: {props.transaction.article.nr ? props.transaction.article.nr : "-"}</p>
                <p>Artikelname: {props.transaction.article.name ? props.transaction.article.name : "-"}</p>
                <p>Anzahl: {props.transaction.inventoryCount ? props.transaction.inventoryCount : "-"}</p>
                <p>Zeitpunkt: {props.transaction.dateTime ? HelperFunctions.formatDateTime(props.transaction.dateTime) : "-"}</p>
                <p>Benutzer: {props.transaction.user ? props.transaction.user.username : "-"}</p>
                <p>Standort
                    (von): {props.transaction.fromLocation && props.transaction.fromLocation.name ? props.transaction.fromLocation.name : "-"}</p>
                <p>Standort
                    (nach): {props.transaction.toLocation && props.transaction.toLocation.name ? props.transaction.toLocation.name : "-"}</p>
                <p>Kunde: {props.transaction.assignedCustomer && props.transaction.assignedCustomer.name ? props.transaction.assignedCustomer.name : "-"}</p>
                <p>Lieferant: {props.transaction.assignedSupplier && props.transaction.assignedSupplier.name ? props.transaction.assignedSupplier.name : "-"}</p>
            </div>
        </Dialog>
    )
}

export default TransactionDialog;