import React, {Component} from "react";
import './ToolTextPair.css';

//Components

class ToolTextPair extends Component {
    render() {
        return (
            <div className={['tool-text-pair', this.props.className].join(' ')}>
                <p className='text'>{this.props.text}</p>
                <p className='value' style={this.props.style}>{this.props.value}</p>
            </div>
        )
    }
}

export default ToolTextPair;