import React, { useState, useEffect } from 'react';
import './ListItem.css';

// Components
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import helperFunctions from "../HelperFunctions";
import HelperFunctions from "../HelperFunctions";

const ListItem = (props) => {
    const [locked, setLocked] = useState(props.item.locked && props.item.locked !== props.user.username);

    useEffect(() => {
        setLocked(props.item.locked && props.item.locked !== props.user.username);
    }, [props.item.locked, props.user.username]);

    const _handleDoubleClick = () => {
        if(props.showItemDialog) {
            props.showItemDialog();
        }
    }

    /* kind 0 = Customer Order, 1 = SupplierOrder, 2 = Factory Certificate, 3 = Batch 4 = PartsList, 5 = Reservation */

    let statusStyle;
    let style;
    let disabledStyle;

    if ((props.kind === 0 || props.kind === 1) && props.item.orderState) {
        switch (props.item.orderState) {
            case "OFFER_CREATION":
                statusStyle = {
                    background: "lightgray"
                };
                break;
            case "NOT_YET_APPROVED":
                statusStyle = {
                    background: "lightcoral"
                };
                break;
            case "WORK_IN_PROGRESS":
                statusStyle = {
                    background: "orange"
                };
                break;
            case "COMPLETED":
                statusStyle = {
                    background: "lightgreen"
                };
                break;
            case "OFFER_REQUESTED":
                statusStyle = {
                    background: "lightgray"
                };
                break;
            case "REQUESTED":
                statusStyle = {
                    background: "lightgray"
                };
                break;
            case "CONFIRMED":
                statusStyle = {
                    background: "lightcoral"
                };
                break;
            case "REJECTED":
                statusStyle = {
                    background: "black"
                };
                break;
            case "GOODS_RECEIVED":
                statusStyle = {
                    background: "lightgreen"
                };
                break;
            default:
                break;
        }
    }

    if (locked) {
        style = {
            background: "lightgray"
        };
        disabledStyle = {
            color: "gray"
        };
    }

    return (
        <Paper onDoubleClick={_handleDoubleClick} key={props.key} style={style} elevation={3} className='list-item'>
            {(props.kind === 0 || props.kind === 1) && <div style={statusStyle} className="order-status" />}
            {!props.connectedOrder && (
                <DeleteIcon
                    style={locked ? disabledStyle : null}
                    onClick={!locked ? props.deleteItem : null}
                    className='icon delete-icon'
                />
            )}
            {!props.connectedOrder && (
                <ArticleIcon
                    onClick={props.showItemDialog}
                    className='icon edit-icon'
                />
            )}
            {props.connectedOrder && (props.alreadyAdded ? (
                <RemoveIcon
                    onClick={props.removeConnectedOrder}
                    className='icon remove-connected-icon'
                />
            ) : (
                <AddIcon
                    onClick={props.addConnectedOrder}
                    className='icon add-connected-icon'
                />
            ))}
            {props.item.documentPositions && (
                <div className="document-positions-div">
                    {props.item.documentPositions.slice(0, 3).map((documentPosition, index) => (
                        <p className="small-info" key={index}>
                            {index + 1}. {documentPosition.article && documentPosition.article.name ? HelperFunctions.formatString(documentPosition.article.name,45) : "-"} ({documentPosition.inventoryCount})
                        </p>
                    ))}
                    {props.item.documentPositions.length > 3 ? "..." : null}
                </div>
            )}
            <p className='item-note'>{props.item.note ? helperFunctions.formatString(props.item.note, 20) : "-"}</p>
            <p className='item-nr'>
                {(props.kind === 0 || props.kind === 1 || props.kind === 3) && props.item.orderNr ? "Bestellnummer: " + props.item.orderNr + (props.item.commissionNr ? " (Komm. Nr. " + props.item.commissionNr + ")" : '') : props.kind === 2 ? props.item.batchNr : ''}
                {props.item.fileStorageLocation && (
                    <>
                        <PictureAsPdfIcon
                            onClick={props.displayFile}
                            className='icon pdf-icon'
                        />
                        <CloseIcon
                            onClick={props.showRemoveFileDialog}
                            className='icon remove-icon'
                        />
                    </>
                )}
            </p>
            <h3 className='item-name'>
                {props.kind === 0 && props.item?.assignedCustomer?.name}
                {props.kind === 1 && props.item?.assignedSupplier?.name}
                {props.kind === 2 && props.item?.article?.name}
                {props.kind === 3 && props.item?.documentPosition?.article?.name && `${props.item.documentPosition.article.name} (${props.item.documentPosition.article.nr})`}
                {props.kind === 4 && props.item?.name}
                {props.kind === 5 && props.item?.name}
            </h3>
            {locked && <p className="locked-info">{props.item.locked} hat gerade diese Bestellung offen</p>}
            <h3 className='item-name small-info'>
                {(props.kind === 0 || props.kind === 1) && props.item?.invoiceDate && `Rechnungsdatum: ${props.formatDate(props.item.invoiceDate)} `}
                {(props.kind === 0 || props.kind === 1) && props.item?.deliveryDate && `Lieferdatum: ${props.formatDate(props.item.deliveryDate)} `}
            </h3>
        </Paper>
    );
};

export default ListItem;