import React, {Component} from "react";
import './NavigationItems.css';

//Components
import {NavLink} from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TopicIcon from '@mui/icons-material/Topic';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Tooltip } from 'react-tooltip'

class NavigationItems extends Component {
    render() {
        return (
            <menu className='navigation-items'>
                <NavLink className='nav-item' activeClassName='active' exact to='/cost-manager'><h2><DashboardIcon className='icon'/> Übersicht</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/cost-manager/file-system'><h2><TopicIcon className='icon'/> Ordnerstruktur</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/cost-manager/order-management'><h2><AssignmentIcon className='icon'/> Bestellwesen</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/cost-manager/master-data'><h2><StorageIcon className='icon'/> Stammdaten</h2></NavLink>
                {this.props.user ? this.props.user.masterUser ? <NavLink className='nav-item' activeClassName='active' to='/cost-manager/administration'><h2><SupervisorAccountIcon className='icon'/> Administration</h2></NavLink> : null : null}
                <NavLink className='nav-item' activeClassName='active' to='/cost-manager/settings'><h2><SettingsIcon className='icon'/> Einstellungen</h2></NavLink>
            </menu>
        )
    }
}

export default NavigationItems;