import React, {Component} from 'react';
import './InventoryContent.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import ToolFab from "../../../../../../../reusable/ToolFab/ToolFab";
import AddIcon from '@mui/icons-material/Add';
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import TextField from "@mui/material/TextField";
import YesCancelDialog from "../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import ListItem from "../../../../../../../reusable/ListItem/ListItem";
import api from "../../../../../../../api";
import PartsListDialog from "./PartsListDialog/PartsListDialog";
import ReservationDialog from "./ReservationDialog/ReservationDialog";
import { Tooltip } from 'react-tooltip'

class InventoryContent extends Component {

    state = {
        activeItem:null,
        shownItems: [],
        searchWord:'',
        searchType:'name',
        selectedItem:null,
        action:null,
        isNewItem:true,
        formattedItems:[],
        isSaved:true,

        //Dialogs
        showItemDialog:false,
        showDeleteItemDialog:false,
        showImportDataDialog:false,
        showInfoDialog:false,
        showFileDialog:false,
        showSendMailDialog:false
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props !== prevProps) {
            this.searchOnClick();
        }
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchType:e.target.value});
    }

    nameOnChange = (e) => {
        let item = this.state.activeItem;
        item.name = e.target.value;
        this.setState({activeItem:item});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownItems = [];
            switch (this.props.kind) {
                case 4:
                    this.props.partsLists.forEach(element => {
                        if(element[this.state.searchType].toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownItems.push(element);
                        }
                    });
                    this.setState({shownItems:shownItems});
                    break;
                case 5:
                    this.props.reservations.forEach(element => {
                        if(element[this.state.searchType].toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownItems.push(element);
                        }
                    });
                    this.setState({shownItems:shownItems});
                    break;
            }
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    setIsSaved = (isSaved) => {
        this.setState({isSaved:isSaved});
    }

    addNewItem = (item) => {
        let url;
        let message;
        switch (this.props.kind) {
            case 3:
                url = '/batches';
                message = 'Charge erfolgreich gespeichert';
                break;
            case 4:
                url = '/parts-lists';
                message = 'Stückliste erfolgreich gespeichert';
                break;
            case 5:
                url = '/reservations';
                message = 'Reservierung erfolgreich gespeichert';
                break;
        }
        api.post(url,item)
            .then(response => {
                this.props.addItemToList(response.data);
                this.props.showMessage(0,message);
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    updateItem = (item,multipartFile) => {
        let url;
        let message;
        switch (this.props.kind) {
            case 3:
                url = '/batches';
                message = 'Charge erfolgreich gespeichert';
                break;
            case 4:
                url = '/parts-lists';
                message = 'Stückliste erfolgreich gespeichert';
                break;
            case 5:
                url = '/reservations';
                message = 'Reservierung erfolgreich gespeichert';
                break;
        }
        api.put(url,item)
            .then(response => {
                this.props.showMessage(0,message);
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    deleteItem = (item) => {
        let url;
        let message;
        switch (this.props.kind) {
            case 3:
                url = '/batches';
                message = 'Charge erfolgreich gelöscht';
                break;
            case 4:
                url = '/parts-lists';
                message = 'Stückliste erfolgreich gelöscht';
                break;
            case 5:
                url = '/reservations';
                message = 'Reservierung erfolgreich gelöscht';
                break;
        }
        api.delete(url + "/" + item.id)
            .then(response => {
                this.props.showMessage(0,message);
                this.props.removeItemFromList(item);
                this.closeItemDialog();
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error);
            });
    }

    addNewFileToItem = (item,multipartFile) => {
        let url;
        let message;
        switch (this.props.kind) {
            case 3:
                url = '/factory-certificates';
                message = 'Charge erfolgreich gespeichert'
                break;
        }
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.post(url + "/" + item.factoryCertificate.id,formData)
            .then(response => {
                item.factoryCertificate.fileStorageLocation = response.data;
                this.props.addItemToList(item);
                this.setState({activeItem:item,isSaved:true});
                this.props.showMessage(0,message);
                this.closeItemDialog();
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    displayFile = (fileStorageLocation) => {
        this.props.displayFile(fileStorageLocation);
        this.closeFileDialog();
    }

    removeFile = (item) => {
        let url;
        switch (this.props.kind) {
            case 3:
                url = '/batches';
                break;
            case 4:
                url = '/parts-lists';
                break;
            case 5:
                url = '/reservations';
                break;
        }
        api.put(url + "/remove",item)
            .then(response => {
                let activeItem = this.state.activeItem;
                activeItem.fileStorageLocation = response.data;
                this.setState({activeItem:activeItem});
                this.props.showMessage(0,'Datei erfolgreich entfernt');
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    componentOnChange = (article, index) => {
        let item = this.state.activeItem;
        item.components[index].article = article;
        this.setState({activeItem:item});
    }

    resultOnChange = (article, index) => {
        let item = this.state.activeItem;
        item.results[index].article = article;
        this.setState({activeItem:item});
    }

    componentInventoryCountOnChange = (e, index) => {
        let item = this.state.activeItem;
        item.components[index].inventoryCount = e.target.value;
        this.setState({activeItem:item});
    }

    resultInventoryCountOnChange = (e, index) => {
        let item = this.state.activeItem;
        item.results[index].inventoryCount = e.target.value;
        this.setState({activeItem:item});
    }

    removeComponent = (index) => {
        let item = this.state.activeItem;
        item.components.splice(index,1);
        for(let i = 0; i < item.components.length; i++) {
            item.components[i].index = i;
        }
        this.setState({activeItem:item});
    };

    removeResult = (index) => {
        let item = this.state.activeItem;
        item.results.splice(index,1);
        for(let i = 0; i < item.results.length; i++) {
            item.results[i].index = i;
        }
        this.setState({activeItem:item});
    }

    reservationArticleOnChange = (article, index) => {
        let item = this.state.activeItem;
        item.reservationPositions[index].article = article;
        this.setState({activeItem:item});
    }

    reservationInventoryCountOnChange = (e, index) => {
        let item = this.state.activeItem;
        item.reservationPositions[index].inventoryCount = e.target.value;
        this.setState({activeItem:item});
    }

    addReservationPosition = () => {

    }

    removeReservationPosition = (index) => {
        let item = this.state.activeItem;
        item.reservationPositions.splice(index,1);
        for(let i = 0; i < item.reservationPositions.length; i++) {
            item.reservationPositions[i].index = i;
        }
        this.setState({activeItem:item});
    }

    nameOnChangeHandler = (e) => {
        let item = this.state.activeItem;
        item.name = e.target.value;
        this.setState({activeItem:item});
    }

    descriptionOnChangeHandler = (e) => {
        let item = this.state.activeItem;
        item.description = e.target.value;
        this.setState({activeItem:item});
    }

    addReservationPosition = () => {
        let item = this.state.activeItem;
        item.reservationPositions.push({
            article:{
                nr:'',
                name:''
            },
            inventoryCount:''
        });
        this.setState({activeItem:item});
    }

    addMultipleEntries = (accountEntryList,customerOrder,supplierOrder) => {
        let data = {
            createEntries:accountEntryList
        }
        const url = '/accounts';
        api.put(url,data)
            .then(response => {
                if(customerOrder) {
                    this.updateItem(customerOrder,true);
                }else{
                    this.updateItem(supplierOrder,true);
                }
                this.props.updateMultipleAccountsOnList(response.data);
                this.props.showMessage(0,'Transaktionen (Buchhaltung) erfolgreich verbucht');
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    savePartsListOnClickHandler = () => {
        let checked = true;
        if(this.state.activeItem.components.length > 0
            && this.state.activeItem.results.length > 0
            && this.state.activeItem.name !== '') {
            for(let i = 0; i < this.state.activeItem.components.length; i++) {
                if(this.state.activeItem.components[i].article.nr === ''
                    || this.state.activeItem.components.inventoryCount === ''
                ) {
                    checked = false;
                }
            }
            for(let i = 0; i < this.state.activeItem.results.length; i++) {
                if(this.state.activeItem.results[i].article.nr === ''
                    || this.state.activeItem.results.inventoryCount === ''
                ) {
                    checked = false;
                }
            }
        }else{
            checked = false;
        }
        if(checked) {
            this.state.activeItem && this.state.activeItem.id ? this.updatePartsList(this.state.activeItem) : this.addNewPartsList(this.state.activeItem);
        }else{
            this.props.showMessage(2,'Bitte füllen Sie alle Felder aus');
        }
    }

    //Database actions

    //Database actions: parts list
    addNewPartsList = (partsList) => {
        const url = '/parts-lists';
        api.post(url,partsList)
            .then(response => {
                this.props.addItemToList(response.data);
                this.setState({activeItem:response.data});
                this.props.showMessage(0,'Stückliste erfolgreich angelegt');
                this.setIsSaved(true);
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    updatePartsList = (partsList) => {
        const url = '/parts-lists';
        api.put(url,partsList)
            .then(response => {
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert");
                this.setIsSaved(true);
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    deletePartsList = (partsList) => {
        api.delete("/parts-lists/" + partsList.id)
            .then(response => {
                this.props.showMessage(0,"Stückliste erfolgreich gelöscht");
                this.props.removeItemFromList(partsList);
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error);
            });
    }

    bookPartsListTransactions = (partsList) => {
        const url = '/parts-lists/book-transactions';
        api.post(url,partsList)
            .then(response => {
                response.data.forEach(element => {
                    this.props.addTransactionToHistory(element);
                })
                this.props.showMessage(0,'Stückliste erfolgreich verarbeitet');
                this.props.displayArticleInformation(null);
                this.setIsSaved(true);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Database actions: reservation

    saveReservationOnClickHandler = () => {
        let checked = true;
        if(this.state.activeItem.reservationPositions.length > 0 && this.state.activeItem.name !== '') {
            for(let i = 0; i < this.state.activeItem.reservationPositions.length; i++) {
                if(this.state.activeItem.reservationPositions[i].article.nr === ''
                    || this.state.activeItem.reservationPositions.inventoryCount === ''
                ) {
                    checked = false;
                }
            }
        }else{
            checked = false;
        }
        if(checked) {
            this.state.activeItem.id ? this.updateReservation(this.state.activeItem) : this.addReservation(this.state.activeItem);
        }else{
            this.props.showMessage(2,'Bitte füllen Sie alle Felder aus');
        }
    }
    addReservation = (reservation) => {
        const url = '/reservations';
        api.post(url,reservation)
            .then(response => {
                this.props.addReservationToList(response.data);
                this.props.showMessage(0,'Reservierung erfolgreich angelegt');
                this.setIsSaved(true);
                this.closeItemDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateReservation = (reservation) => {
        const url = '/reservations';
        api.put(url,reservation)
            .then(response => {
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert");
                this.closeItemDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteReservation = (reservation) => {
        api.delete("/reservations/" + reservation.id)
            .then(response => {
                this.props.showMessage(0,"Reservierung erfolgreich gelöscht");
                this.props.removeReservationFromList(reservation);
            })
            .catch(error => {
                console.log(error)
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    bookReservation = (reservation) => {
        const url = "/reservations/" + reservation.id;
        api.post(url,reservation)
            .then(response => {
                response.data.forEach(element => {
                    this.props.addTransactionToHistory(element);
                })
                this.props.removeReservationFromList(reservation);
                this.props.showMessage(0,'Reservierung erfolgreich verbucht');
                this.props.displayArticleInformation(null);
                this.closeReservationDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions
    showItemDialog = (item) => {
        this.setState({activeItem:item,showItemDialog:true});
    }

    closeItemDialog = () => {
        this.setState({showItemDialog:false});
    }

    showDeleteItemDialog = (item) => {
        this.setState({activeItem:item,showDeleteItemDialog:true});
    }

    closeDeleteItemDialog = () => {
        this.setState({showDeleteItemDialog:false});
    }

    showImportDataDialog = () => {
        this.setState({showImportDataDialog:true});
    }

    closeImportDataDialog = () => {
        this.setState({showImportDataDialog:false});
    }

    showInfoDialog = () => {
        this.setState({showMasterDataInfoDialog:true});
    }

    closeInfoDialog = () => {
        this.setState({showInfoDialog:false});
    }

    showFileDialog = (item) => {
        this.setState({activeItem:item,showFileDialog:true});
    }

    closeFileDialog = () => {
        this.setState({showFileDialog:false});
    }

    showRemoveFileDialog = (item) => {
        this.setState({activeItem:item,showRemoveFileDialog:true});
    }

    closeRemoveFileDialog = () => {
        this.setState({showRemoveFileDialog:false});
    }

    showSendMailDialog = (item) => {
        this.setState({activeItem:item,showSendMailDialog:true});
    }

    closeSendMailDialog = () => {
        this.setState({showSendMailDialog:false});
    }

    render() {

        let blankItem;

        switch (this.props.kind) {
            case 3:
                blankItem = {
                    nr: '',
                    deliveryDate: '',
                    documentPosition:
                        {
                            article: {
                                nr: '',
                                attributeValues: []
                            },
                            inventoryCount: ''
                        }
                }
                break;
            case 4:
                blankItem = {
                    name:'',
                    components:[
                        {
                            article:{
                                nr:'',
                                name:''
                            },
                            inventoryCount:''
                        }
                    ],
                    results:[
                        {
                            article:{
                                nr:'',
                                name:''
                            },
                            inventoryCount:''
                        }
                    ]
                }
                break;
            case 5:
                blankItem = {
                    name:'',
                    description:'',
                    reservationPositions:[
                        {
                            article:{
                                nr:'',
                                name:''
                            },
                            inventoryCount:''
                        }
                    ]
                }
                break;
        }

        return (
            <div className='inventory-content'>
                <Tooltip id="inventory-content-tooltip"/>

                {this.props.kind === 4 ? <PartsListDialog
                    open={this.state.showItemDialog}
                    close={this.closeItemDialog}
                    partsList={this.state.activeItem}
                    articles={this.props.articles}
                    setIsSaved={this.setIsSaved}
                    componentOnChange={this.componentOnChange}
                    componentInventoryCountOnChange={this.componentInventoryCountOnChange}
                    removeComponent={this.removeComponent}
                    resultOnChange={this.resultOnChange}
                    resultInventoryCountOnChange={this.resultInventoryCountOnChange}
                    removeResult={this.removeResult}
                    nameOnChange={this.nameOnChange}
                    savePartsListOnClickHandler={this.savePartsListOnClickHandler}
                /> : null}

                {this.props.kind === 5 ? <ReservationDialog
                    open={this.state.showItemDialog}
                    showMessage={this.props.showMessage}
                    close={this.closeItemDialog}
                    reservation={this.state.activeItem}
                    articles={this.props.articles}
                    setIsSaved={this.setIsSaved}
                    reservationArticleOnChange={this.reservationArticleOnChange}
                    reservationInventoryCountOnChange={this.reservationInventoryCountOnChange}
                    removeReservationPosition={this.removeReservationPosition}
                    saveReservation={this.addReservation}
                    updateReservation={this.updateReservation}
                    nameOnChangeHandler={this.nameOnChangeHandler}
                    descriptionOnChangeHandler={this.descriptionOnChangeHandler}
                    addReservationPosition={this.addReservationPosition}
                    saveReservationOnClickHandler={this.saveReservationOnClickHandler}
                /> : null}

                <YesCancelDialog
                    open={this.state.showDeleteItemDialog}
                    close={this.closeDeleteItemDialog}
                    header='Löschen'
                    text='Wollen Sie das Werkszeugnis wirklich löschen?'
                    onClick={() => this.deleteItem(this.state.activeItem)}
                />

                <YesCancelDialog
                    open={this.state.showRemoveFileDialog}
                    close={this.closeRemoveFileDialog}
                    header='Datei entfernen'
                    text='Wollen Sie wirklich die Datei entfernen?'
                    onClick={() => this.removeFile(this.state.activeItem)}
                />

                <div className='header-bar'>
                    {this.props.kind === 3 ? <h1>Chargen</h1> : null}
                    {this.props.kind === 4 ? <h1>Stücklisten</h1> : null}
                    {this.props.kind === 5 ? <h1>Reservierungen</h1> : null}
                    <TextField size="small" text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort'/>
                    {this.props.kind === 3 ?
                        <TextField size="small" value={this.state.searchType} onChange={this.searchTypeOnChange} select className='search-select' label='Suche'>
                            <MenuItem key={0} value={'nr'}>Chargennummer</MenuItem>
                            <MenuItem key={1} value={'assignedSupplier.nr'}>Lieferantennummer</MenuItem>
                            <MenuItem key={2} value={'orderNr'}>Bestellnummer</MenuItem>
                            <MenuItem key={3} value={'nr'}>Werkszeugnisnummer</MenuItem>
                            <MenuItem key={4} value={'material'}>Werkstoff</MenuItem>
                        </TextField> : null}
                    {this.props.kind === 4 ?
                        <TextField size="small" value={this.state.searchType} onChange={this.searchTypeOnChange} select className='search-select' label='Suche'>
                            <MenuItem key={0} value={'name'}>Name</MenuItem>
                        </TextField> : null}
                    {this.props.kind === 5 ?
                        <TextField size="small" value={this.state.searchType} onChange={this.searchTypeOnChange} select className='search-select' label='Suche'>
                            <MenuItem key={0} value={'name'}>Name</MenuItem>
                        </TextField> : null}
                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                    {/* <CSVLink filename={'wm_masterdata_' + Date.now() + '.csv'} data={this.state.formattedItems}><GetAppIcon data-tooltip-id="inventory-content-tooltip" data-tooltip-place="top" data-tooltip-content='Ausgewählte Daten als .csv-Datei herunterladen' onClick={this.formatData} className='icon download-icon'/></CSVLink>*/}
                </div>
                <div className='item-list'>
                    {this.state.shownItems.map((item,index) => (
                        <ListItem
                            key={index}
                            item={item}
                            showItemDialog={() => this.showItemDialog(item)}
                            showFileDialog={() => this.showFileDialog(item)}
                            deleteItem={() => this.showDeleteItemDialog(item)}
                            displayFile={() => this.displayFile(item.fileStorageLocation)}
                            showRemoveFileDialog={() => this.showRemoveFileDialog(item)}
                            showSendMailDialog={() => this.showSendMailDialog(item)}
                            kind={this.props.kind}
                        />
                    ))}
                </div>
                <ToolFab className='add-fab' onClick={() => this.showItemDialog(blankItem)} aria-label="add">
                    <AddIcon/>
                </ToolFab>
            </div>
        )
    }
}

export default InventoryContent;