import React, {Component} from "react";
import './NavigationItems.css';

//Components
import {NavLink} from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import StorageIcon from '@mui/icons-material/Storage';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import EmailIcon from '@mui/icons-material/Email';

class NavigationItems extends Component {
    render() {
        return (
            <menu className='navigation-items'>
                <NavLink className='nav-item' activeClassName='active' exact to='/warehouse-manager'><h2><DashboardIcon className='icon'/> Übersicht</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/order-management'><h2><AssignmentIcon className='icon'/> Bestellwesen</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/inventory'><h2><WarehouseIcon className='icon'/> Inventar</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/communication'><h2><EmailIcon className='icon'/> Kommunikation</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/reporting'><h2><AssessmentIcon className='icon'/> Reporting</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/master-data'><h2><StorageIcon className='icon'/> Stammdaten</h2></NavLink>
                {this.props.user && this.props.user.role && this.props.user.role === "ADMIN" ? <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/administration'><h2><SupervisorAccountIcon className='icon'/> Administration</h2></NavLink> : null}
                <NavLink className='nav-item' activeClassName='active' to='/warehouse-manager/settings'><h2><SettingsIcon className='icon'/> Einstellungen</h2></NavLink>
            </menu>
        )
    }
}

export default NavigationItems;