import React, { useState, useEffect } from 'react';
import './OrderItem.css';

// Components
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import helperFunctions from "../../../../../../../../reusable/HelperFunctions";
import HelperFunctions from "../../../../../../../../reusable/HelperFunctions";
import EditIcon from "@mui/icons-material/Edit";

const OrderItem = (props) => {
    const [locked, setLocked] = useState(props.item.locked && props.item.locked !== props.user.username);
    const [edit,setEdit] = useState(false);

    useEffect(() => {
        setLocked(props.item.locked && props.item.locked !== props.user.username);
    }, [props.item.locked, props.user.username]);

    const toggleEdit = () => {
        if(edit) {
            props.updateOrder(props.item);
        }
        setEdit(!edit);
    }

    /* kind 0 = Customer Order, 1 = SupplierOrder, 2 = Factory Certificate, 3 = Batch 4 = PartsList, 5 = Reservation */

    let statusStyle;
    let style;
    let disabledStyle;

    if ((props.kind === 0 || props.kind === 1) && props.item.orderState) {
        switch (props.item.orderState) {
            case "OFFER_CREATION":
                statusStyle = {
                    background: "lightgray"
                };
                break;
            case "NOT_YET_APPROVED":
                statusStyle = {
                    background: "lightcoral"
                };
                break;
            case "WORK_IN_PROGRESS":
                statusStyle = {
                    background: "orange"
                };
                break;
            case "COMPLETED":
                statusStyle = {
                    background: "lightgreen"
                };
                break;
            case "REQUESTED":
                statusStyle = {
                    background: "lightgray"
                };
                break;
            case "CONFIRMED":
                statusStyle = {
                    background: "lightcoral"
                };
                break;
            case "REJECTED":
                statusStyle = {
                    background: "black"
                };
                break;
            case "GOODS_RECEIVED":
                statusStyle = {
                    background: "lightgreen"
                };
                break;
            default:
                break;
        }
    }

    if (locked) {
        style = {
            background: "lightgray"
        };
        disabledStyle = {
            color: "gray"
        };
    }

    return (
        <Paper key={props.key} style={style} elevation={3} className='order-item'>
            <div style={statusStyle} className="order-status"/>
            {!props.connectedOrder && (
                <DeleteIcon
                    style={locked ? disabledStyle : null}
                    onClick={!locked ? props.deleteItem : null}
                    className='icon delete-icon'
                />
            )}
            {!props.connectedOrder && (
                <ArticleIcon
                    onClick={props.showItemDialog}
                    className='icon item-icon'
                />
            )}
            {!props.connectedOrder && (
                <EditIcon
                    onClick={toggleEdit}
                    className='icon edit-icon'
                />
            )}
            {props.connectedOrder && (props.alreadyAdded ? (
                <RemoveIcon
                    onClick={props.removeConnectedOrder}
                    className='icon remove-connected-icon'
                />
            ) : (
                <AddIcon
                    onClick={props.addConnectedOrder}
                    className='icon add-connected-icon'
                />
            ))}
            {edit ? <ul className="information-holder">
                    <li><input onChange={(e) => props.commissionNrOnChangeHandler(e, props.index)} type="text"
                               value={props.item.commissionNr}/></li>
                    <li><input onChange={(e) => props.orderNrOnChangeHandler(e, props.index)} type="text"
                               value={props.item.orderNr}/></li>
                    {props.kind === 0 ?
                        <li>{props.item && props.item.assignedCustomer && props.item.assignedCustomer.name ? HelperFunctions.formatString(props.item.assignedCustomer.name, 12) : "-"}</li> : null}
                    {props.kind === 1 ?
                        <li>{props.item && props.item.assignedSupplier && props.item.assignedSupplier.name ? HelperFunctions.formatString(props.item.assignedSupplier.name, 12) : "-"}</li> : null}
                    <li><input onChange={(e) => props.itemOnChangeHandler(e, props.index)} type="text"
                               value={props.item.item}/></li>
                    <li><input onChange={(e) => props.orderDateOnChangeHandler(e, props.index)} type="date"
                               value={props.item.orderDate}/></li>
                    <li><input onChange={(e) => props.deliveryDateOnChangeHandler(e, props.index)} type="date"
                               value={props.item.deliveryDate}/></li>
                    <li><input onChange={(e) => props.noteOnChangeHandler(e, props.index)} type="text"
                               value={props.item.note}/></li>
                </ul>
                :
                <ul className="information-holder">
                    <li>{props.item && props.item.commissionNr ? HelperFunctions.formatString(props.item.commissionNr, 12) : "-"}</li>
                <li>{props.item && props.item.orderNr ? HelperFunctions.formatString(props.item.orderNr, 12) : "-"}</li>
                {props.kind === 0 ?
                    <li>{props.item && props.item.assignedCustomer && props.item.assignedCustomer.name ? HelperFunctions.formatString(props.item.assignedCustomer.name, 12) : "-"}</li> : null}
                {props.kind === 1 ?
                    <li>{props.item && props.item.assignedSupplier && props.item.assignedSupplier.name ? HelperFunctions.formatString(props.item.assignedSupplier.name, 12) : "-"}</li> : null}
                <li>{props.item && props.item.item ? HelperFunctions.formatString(props.item.item, 12) : "-"}</li>
                <li>{props.item && props.item.orderDate ? HelperFunctions.formatDate(props.item.orderDate) : "-"}</li>
                <li>{props.item && props.item.deliveryDate ? HelperFunctions.formatDate(props.item.deliveryDate) : "-"}</li>
                <li>{props.item && props.item.note ? HelperFunctions.formatString(props.item.note, 12) : "-"}</li>
            </ul>}
            {locked && <p className="locked-info">{props.item.locked} hat gerade diese Bestellung offen</p>}
        </Paper>
    );
};

export default OrderItem;