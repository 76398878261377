import React, {Component} from 'react';
import './HistoryEntry.css';

//Components
import HelperFunctions from "../../HelperFunctions";

class HistoryEntry extends Component {
    render() {
        return (
            <div className='history-entry'>
                <p className="date-time">{HelperFunctions.formatDateTime(this.props.historyEntry.dateTime)}</p>
                <p className="information-text">{this.props.historyEntry.text}</p>
            </div>
        )
    }
}

export default HistoryEntry;