import React, {Component} from "react";
import './PostboxDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import api from "../../../../../../../../api";

class PostboxDialog extends Component {

    state = {
        postboxInformation:{}
    }

    emailOnChangeHandler = (e) => {
        let postboxInformation = this.state.postboxInformation;
        postboxInformation.email = e.target.value;
        this.setState({postboxInformation: postboxInformation});
    }

    passwordOnChangeHandler = (e) => {
        let postboxInformation = this.state.postboxInformation;
        postboxInformation.password = e.target.value;
        this.setState({postboxInformation: postboxInformation});
    }

    smtpServerOnChangeHandler = (e) => {
        let postboxInformation = this.state.postboxInformation;
        postboxInformation.smtpServer = e.target.value;
        this.setState({postboxInformation: postboxInformation});
    }

    smtpPortOnChangeHandler = (e) => {
        let postboxInformation = this.state.postboxInformation;
        postboxInformation.smtpPort = e.target.value;
        this.setState({postboxInformation: postboxInformation});
    }

    imapServerOnChangeHandler = (e) => {
        let postboxInformation = this.state.postboxInformation;
        postboxInformation.imapServer = e.target.value;
        this.setState({postboxInformation: postboxInformation});
    }

    imapPortOnChangeHandler = (e) => {
        let postboxInformation = this.state.postboxInformation;
        postboxInformation.imapPort = e.target.value;
        this.setState({postboxInformation: postboxInformation});
    }

    savePostboxInformationOnClickHandler = () => {
        if(this.validatePostboxInformation(this.state.postboxInformation)) {
            this.savePostboxInformation(this.state.postboxInformation);
        }
    }

    testPostboxInformationOnClickHandler = () => {
        if(this.validatePostboxInformation(this.state.postboxInformation)) {
            this.testPostboxInformation(this.state.postboxInformation);
        }
    }

    validatePostboxInformation = (postboxInformation) => {
        if(postboxInformation && postboxInformation.password && postboxInformation.smtpServer && postboxInformation.smtpPort && postboxInformation.imapServer && postboxInformation.imapPort) {
            return true;
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
            return false;
        }
    }

    testPostboxInformation = (postboxInformation) => {
        this.props.setLoading(true);
        api.post("/send-email/test",postboxInformation)
            .then(response => {
                this.props.setSystemEmail(postboxInformation.email);
                this.props.showMessage(0,"Es wurde eine Test E-Mail von und an die eingegeben E-Mail versandt. Sollte keine E-Mail ankommen, scheint etwas nicht geklappt zu haben.");
                this.props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    testPostboxInformationTest = (postboxInformation) => {
        console.log(postboxInformation);
        api.post("/send-email/test-debug")
            .then(response => {

            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    savePostboxInformation = (postboxInformation) => {
        this.props.setLoading(true);
        api.put("/company/postbox",postboxInformation)
            .then(response => {
                this.props.setSystemEmail(postboxInformation.email);
                this.props.showMessage(0,"Das Systempostfach wurde erfolgreich hinterlegt");
                this.props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    render() {
        return (
            <Dialog scroll='body' maxWidth={"xl"} open={this.props.open} onClose={this.props.close} className='postbox-dialog'>
                <div className='surrounder'>
                    <h2>Systempostfach hinterlegen</h2>
                    <TextField size="small" onChange={this.emailOnChangeHandler} value={this.state.postboxInformation.email} label='E-Mail Adresse' className='field' InputLabelProps={{shrink: true}}/>
                    <TextField size="small" onChange={this.passwordOnChangeHandler} value={this.state.postboxInformation.password} type='password' label='Password' className='field' InputLabelProps={{shrink: true}}/>
                    <TextField size="small" onChange={this.smtpServerOnChangeHandler} value={this.state.postboxInformation.smtpServer} label='Smtp Server' className='field' InputLabelProps={{shrink: true}}/>
                    <TextField size="small" onChange={this.smtpPortOnChangeHandler} value={this.state.postboxInformation.smtpPort} label='Smtp Port' className='field' InputLabelProps={{shrink: true}}/>
                    <TextField size="small" onChange={this.imapServerOnChangeHandler} value={this.state.postboxInformation.imapServer} label='Imap Server' className='field' InputLabelProps={{shrink: true}}/>
                    <TextField size="small" onChange={this.imapPortOnChangeHandler} value={this.state.postboxInformation.imapPort} label='Imap Port' className='field' InputLabelProps={{shrink: true}}/>
                    <ToolButton className="test-button" onClick={this.testPostboxInformationOnClickHandler}>Test E-Mail versenden</ToolButton>
                    <ToolButton className="save-button" onClick={this.savePostboxInformationOnClickHandler}>Speichern</ToolButton>
                </div>
            </Dialog>
        )
    }
}

export default PostboxDialog;