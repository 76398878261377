import React, {Component} from "react";
import './CreateDirectoryDialog.css';

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from '@mui/icons-material/Close';
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import InputField from "../../../../../../../reusable/InputField/InputField";

class CreateDirectoryDialog extends Component {

    state = {
        directory:{}
    }

    nameOnChangeHandler = (e) => {
        let directory = this.state.directory;
        directory.name = e.target.value;
        this.setState({directory:directory});
    }

    addDirectoryOnClickHandler = () => {
        if(this.state.directory.name) {
            this.props.addDirectory(this.state.directory,this.props.parent);
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.onClose} className='create-directory-dialog'>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle>Ordner erstellen</DialogTitle>
                <DialogContent>
                    <InputField onChange={this.nameOnChangeHandler}/>
                    <DialogActions>
                        <ToolButton onClick={this.addDirectoryOnClickHandler} className='button'>Speichern</ToolButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
}

export default CreateDirectoryDialog;