import React, {Component} from "react";
import './FileContextMenu.css';

//Components

class FileContextMenu extends Component {
    render() {
        return (
            <ul className='file-context-menu' style={{left:this.props.anchorPoint.x,top:this.props.anchorPoint.y}}>
                <li onClick={() => this.props.addEmailAttachment(this.props.item,false)}><p>Als E-Mail versenden</p></li>
                <li onClick={() => this.props.renameFileOnClickHandler(this.props.item)}><p>Umbennenen</p></li>
                <li onClick={() => this.props.deleteFileOnClickHandler(this.props.item)}><p>Löschen</p></li>
                <li onClick={this.props.createDirectoryOnClickHandler}><p>Neuen Ordner erstellen</p></li>
                <li onClick={this.props.createFileOnClickHandler}><p>Neue Datei erstellen</p></li>
            </ul>
        )
    }
}

export default FileContextMenu;