import React, {Component} from "react";
import './ReportingInfoDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from '@mui/icons-material/Close';

class ReportingInfoDialog extends Component {
    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='reporting-info-dialog'>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>Reporting Info</DialogTitle>
                    <DialogContent>
                        <DialogContentText className='paragraph'>
                            Im Reporting kann man sich die getätigten Transaktion anzeigen lassen.
                            Dabei muss zunächst der Zeitraum ausgewählt werden.
                            <br/><br/>
                            Weiterhin können bestimmte Bedingungen ausgewählt werden.
                            Der Komplexität einer Analyse sind somit kaum Grenzen gesetzt.
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        )
    }
}

export default ReportingInfoDialog;