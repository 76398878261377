import React, {Component} from 'react';
import './Footer.css';

//Components

class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <p><a href="https://www.panthera-software.com" target="_blank" rel="noopener noreferrer">2024 Panthera Software Solutions GmbH</a> | <a href="https://www.leet-programming.com/impressum.html" target="_blank" rel="noopener noreferrer">Impressum</a> | <a href="https://www.leet-programming.com/privacy_policy.html" target="_blank" rel="noopener noreferrer">Datenschutz</a> | <a href="https://www.leet-programming.com/agb.html" target="_blank" rel="noopener noreferrer">AGB</a></p>
            </div>
        )
    }

}

export default Footer;
