import React, {Component} from "react";
import './ConditionItem.css';

//Components
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import Paper from "@mui/material/Paper";

class ConditionItem extends Component {
    render() {


        let config = {
            limit:10
        }
        const filter = createFilterOptions(config);

        let conditionSelection = null;

        switch (this.props.condition.filterKind) {
            case "STATUS":
                if(this.props.kind === 0) {
                    conditionSelection = <TextField size="small" value={this.props.condition ? this.props.condition.orderState : null} onChange={(e) => this.props.conditionOrderStateOnChangeHandler(e,this.props.index)} select className='field status-field' label='Status'>
                        <MenuItem key={0} value="NO_STATE">Kein Status</MenuItem>
                        <MenuItem key={1} value="OFFER_CREATION">Angebotserstellung</MenuItem>
                        <MenuItem key={2} value="NOT_YET_APPROVED">Noch nicht freigegeben</MenuItem>
                        <MenuItem key={3} value="WORK_IN_PROGRESS">In Bearbeitung</MenuItem>
                        <MenuItem key={4} value="COMPLETED">Abgeschlossen</MenuItem>
                    </TextField>;
                }
                if(this.props.kind === 1) {
                    conditionSelection = <TextField size="small" value={this.props.condition ? this.props.condition.orderState : null} onChange={(e) => this.props.conditionOrderStateOnChangeHandler(e,this.props.index)} select className='field status-field' label='Status'>
                        <MenuItem key={0} value="NO_STATE">Kein Status</MenuItem>
                        <MenuItem key={1} value="REQUESTED">Angefragt</MenuItem>
                        <MenuItem key={2} value="CONFIRMED">Bestätigt</MenuItem>
                        <MenuItem key={3} value="REJECTED">Abgelehnt</MenuItem>
                        <MenuItem key={4} value="GOODS_RECEIVED">Ware erhalten</MenuItem>
                    </TextField>;
                }
                break;
            case "ARTICLE":
                conditionSelection = <Autocomplete
                    onChange={(event, article) => this.props.conditionArticleOnChangeHandler(article,this.props.index)}
                    size="small"
                    value={this.props.condition.article}
                    filterOptions={(options, params) => {
                        return filter(options, params);
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    className='field'
                    options={this.props.articles}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Regular option
                        return option.nr ? option.name + " (" + option.nr + ")" : option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.nr ? option.name + " (" + option.nr + ")" : option.name}</li>}
                    renderInput={(params) => (
                        <TextField size="small" {...params} label="Artikel"/>
                    )}
                />
                break;
            case "CUSTOMER":
                conditionSelection = <Autocomplete
                    onChange={(event, customer) => this.props.conditionCustomerOnChangeHandler(customer,this.props.index)}
                    size="small"
                    value={this.props.condition.customer}
                    filterOptions={(options, params) => {
                        return filter(options, params);
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    className='field'
                    options={this.props.customers}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Regular option
                        return option.nr ? option.name + " (" + option.nr + ")" : option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.nr ? option.name + " (" + option.nr + ")" : option.name}</li>}
                    freeSolo
                    renderInput={(params) => (
                        <TextField size="small" {...params} label="Kunde"/>
                    )}
                />
                break;
            case "SUPPLIER":
                conditionSelection = <Autocomplete
                    onChange={(event, supplier) => this.props.conditionSupplierOnChangeHandler(supplier,this.props.index)}
                    size="small"
                    value={this.props.condition.supplier}
                    filterOptions={(options, params) => {
                        return filter(options, params);
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    className='field'
                    options={this.props.suppliers}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Regular option
                        return option.nr ? option.name + " (" + option.nr + ")" : option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.nr ? option.name + " (" + option.nr + ")" : option.name}</li>}
                    freeSolo
                    renderInput={(params) => (
                        <TextField size="small" {...params} label="Lieferant"/>
                    )}
                />
                break;
            case "COMMISSION_NR":
                conditionSelection = <div>
                    <TextField value={this.props.condition.operator} size="small" onChange={(e) => this.props.conditionCommissionNrOperatorOnChangeHandler(e,this.props.index)} label='Operator' className='field tiny' select>
                        <MenuItem key={0} value={0}>&lt;</MenuItem>
                        <MenuItem key={1} value={1}>&gt;</MenuItem>
                    </TextField>
                    <TextField value={this.props.condition.commissionNr} size="small" onChange={(e) => this.props.conditionCommissionNrOnChangeHandler(e,this.props.index)} label='Kommissionsnummer' className='field'/>
                </div>
                break;
            default:
                break;
        }

        return (
            <Paper elevation={3} className='condition-item'>
                <TextField size="small" value={this.props.condition.filterKind} onChange={(e) => this.props.conditionFilterKindOnChangeHandler(e,this.props.index)} label='Bedingungstyp' className='field' select>
                    <MenuItem key={0} value="STATUS">Status</MenuItem>
                    <MenuItem key={1} value="ARTICLE">Artikel</MenuItem>
                    <MenuItem key={2} value="CUSTOMER">Kunde</MenuItem>
                    <MenuItem key={3} value="SUPPLIER">Lieferant</MenuItem>
                    <MenuItem key={4} value="COMMISSION_NR">Kommissionsnummer</MenuItem>
                </TextField>
                {conditionSelection}
                <i onClick={() => this.props.removeConditionOnClickHandler(this.props.index)} className="fas fa-trash-alt"/>
            </Paper>
        )
    }
}

export default ConditionItem;