import React, {Component} from "react";
import './Directory.css';

//Components
import FolderIcon from '@mui/icons-material/Folder';
import { Tooltip } from 'react-tooltip'

class Directory extends Component {
    render() {
        return (
            <div onContextMenu={(e) => this.props.onContextMenu(e,this.props.item)} className='directory'>
                <Tooltip id="directory-tooltip"/>
                <FolderIcon onClick={(e) => this.props.handleClick(e,this.props.item)} className='icon directory-icon'/>
                <p data-tooltip-id="directory-tooltip" data-tooltip-place="top" data-tooltip-content={this.props.item.name.length > 12 ? this.props.item.name : null} className='directory-name'>{this.props.formatString(this.props.item.name,12)}</p>
            </div>
        )
    }
}

export default Directory;