import React, {Component} from "react";
import './ReservationDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import MenuItem from "@mui/material/MenuItem";
import DocumentPosition from "../../../../../../../../reusable/DocumentPosition/DocumentPosition";
import api from "../../../../../../../../api";
import CloseIcon from "@mui/icons-material/Close";

class ReservationDialog extends Component {

    clearFields = () => {
        let reservation = {
            name:null,
            description:null,
            assignedCustomer:null,
            reservationPositions:[]
        }
        this.setState({reservation:reservation});
    }

    render() {
        return (
            <Dialog scroll='body' maxWidth={"xl"} open={this.props.open} onClose={this.props.close} className='reservation-dialog'>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>Reservierung</DialogTitle>
                    <TextField size="small" value={this.props.reservation ? this.props.reservation.name : ''} label='Name' className='field' onChange={this.props.nameOnChangeHandler}></TextField>
                    <TextField size="small" value={this.props.reservation ? this.props.reservation.description : ''} label='Kurzbeschreibung' helperText='(optional)' className='field' onChange={this.props.descriptionOnChangeHandler}></TextField>
                    {this.props.reservation ? this.props.reservation.reservationPositions.map((reservationPosition,index) => (
                        <DocumentPosition
                            key={index}
                            index={index}
                            type={5}
                            documentPosition={reservationPosition}
                            articleOnChange={this.props.reservationArticleOnChange}
                            inventoryCountOnChange={this.props.reservationInventoryCountOnChange}
                            removeOrderPosition={this.props.removeReservationPosition}
                            articles={this.props.articles}
                        />
                    )) : null}
                    <ToolButton className='field' onClick={this.props.addReservationPosition}>+</ToolButton>
                    <ToolButton main className='button' onClick={this.props.saveReservationOnClickHandler}>Speichern</ToolButton>
                </div>
            </Dialog>
        )
    }
}

export default ReservationDialog;