import React, {Component} from "react";
import './DrawArea.css';

//Components

class DrawArea extends Component {

    constructor(props) {
        super(props);
        this.svgRef = React.createRef();
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
    }

    handleMouseUp= (event) => {
        if(this.props.drawingActive) {
            event.preventDefault();
            let attachments = this.props.attachments;
            let drawing = {
                path:this.props.path,
                scale:1,
                stroke:this.props.color,
                strokeWidth:this.props.size
            }
            attachments[this.props.currentPage-1].drawings.push(drawing);
            this.props.setAttachments(attachments);
            this.props.setPath("");
            this.props.setIsDrawing(false);
        }
    }

    handleMouseDown = (event) => {
        if(this.props.drawingActive) {
            event.preventDefault();
            this.props.setIsDrawing(true);

            let scrollTop = document.getElementById("test-id").getElementsByClassName("MuiDialog-scrollBody")[0].scrollTop;

            console.log(scrollTop)

            const x = event.clientX - this.props.page.current.offsetParent.offsetLeft;
            const y = event.clientY - this.props.page.current.offsetParent.offsetTop + scrollTop;
            this.props.setMinX(Math.min(this.props.minX,x));
            this.props.setMaxX(Math.min(this.props.maxX,x));
            this.props.setMinY(Math.min(this.props.minY,y));
            this.props.setMaxY(Math.min(this.props.maxY,y));
            this.props.setPath(this.props.path + `M${x},${y}`);
        }
    }

    handleMouseMove = (event) => {
        if(this.props.drawingActive) {
            event.preventDefault();
            if(!this.props.isDrawing) {
                return;
            }

            let scrollTop = document.getElementById("test-id").getElementsByClassName("MuiDialog-scrollBody")[0].scrollTop;

            console.log(scrollTop)

            const x = event.clientX - this.props.page.current.offsetParent.offsetLeft;
            const y = event.clientY - this.props.page.current.offsetParent.offsetTop + scrollTop;
            this.props.setMinX(Math.min(this.props.minX,x));
            this.props.setMaxX(Math.min(this.props.maxX,x));
            this.props.setMinY(Math.min(this.props.minY,y));
            this.props.setMaxY(Math.min(this.props.maxY,y));
            this.props.setPath(this.props.path + `L${x},${y}`);
        }
    }

    render() {
        return (
            <div
                className="draw-area"
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
            >
                <svg
                    width={this.props.viewport && this.props.viewport.viewBox ? this.props.viewport.viewBox[2] : 0}
                    height={this.props.viewport && this.props.viewport.viewBox ? this.props.viewport.viewBox[3] : 0}
                    ref={this.svgRef}
                    className='draw-svg'
                >
                    {this.props.attachments && this.props.attachments[this.props.currentPage-1] && this.props.attachments[this.props.currentPage-1].drawings &&
                        this.props.attachments[this.props.currentPage-1].drawings.map(drawing => (
                        <path
                            d={drawing.path}
                            className="path"
                            style={{stroke:drawing.stroke,strokeWidth:drawing.strokeWidth}}
                        />
                    ))}
                    <path
                        d={this.props.path}
                        className="path"
                        style={{stroke:this.props.color,strokeWidth:this.props.size}}
                    />
                </svg>
            </div>
        )
    }
}

export default DrawArea;