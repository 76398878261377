import React, {Component} from "react";
import './AddEntryDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from "@mui/icons-material/Close";

class AccountDialog extends Component {

    state = {
        entry:this.props.entry
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({entry:this.props.entry});
        }
    }

    nrOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.nr = e.target.value;
        this.setState({entry:entry});
    }

    invoiceNrOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.invoiceNr = e.target.value;
        this.setState({entry:entry});
    }

    invoiceDateOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.invoiceDate = e.target.value;
        this.setState({entry:entry});
    }

    valueOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.value = e.target.value;
        this.setState({entry:entry});
    }

    dateOnChangeHandler = (e) => {
        let entry = this.state.entry;
        entry.date = e.target.value;
        this.setState({entry:entry});
    }

    addEntryOnClickHandler = () => {
        if(this.state.entry.nr !== '' && this.state.entry.value !== '' && this.state.entry.date !== '') {
            this.props.addEntry(this.props.account,this.state.entry);
        }else{
            this.props.showMessage(2,'Fehlende oder falscher Werte');
        }
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='add-entry-dialog'>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle className='dialog-title'>Neue Buchung</DialogTitle>
                    <div className='add-entry'>
                        <TextField className='field' value={this.state.entry ? this.state.entry.nr : ''} label='Belegnummer' onChange={this.nrOnChangeHandler}/>
                        <TextField className='field' value={this.state.entry ? this.state.entry.invoiceNr : ''} label='Rechnungsnummer' helperText='optional' onChange={this.invoiceNrOnChangeHandler}/>
                        <TextField className='field' value={this.state.entry ? this.state.entry.invoiceDate : ''} type='date' label='Rechnungsdatum' helperText='optional' onChange={this.invoiceDateOnChangeHandler}/>
                        <TextField className='field' value={this.state.entry ? this.state.entry.value : ''} label='Betrag' type='number' onChange={this.valueOnChangeHandler}/>
                        <TextField className='field' value={this.state.entry ? this.state.entry.date : ''} label='Datum' type='date' onChange={this.dateOnChangeHandler}/>
                        <ToolButton onClick={this.addEntryOnClickHandler} className='button'>Hinzufügen</ToolButton>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default AccountDialog;