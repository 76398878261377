import React, {Component} from "react";
import './AttributeItem.css';
import Paper from "@mui/material/Paper";

//Components
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

class AttributeItem extends Component {
    render() {
        let valueInput = null;
        if(this.props.attributeValue.attribute ? this.props.attributeValue.attribute.type === 0 : '') {
            valueInput =<Switch
                className='switch'
                checked={this.props.attributeValue.booleanValue}
                onChange={(e) => this.props.booleanValueOnChangeHandler(e,this.props.index)}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        }else if(this.props.attributeValue.attribute ? this.props.attributeValue.attribute.type === 1 : '') {
            valueInput = <TextField
                className='attribute-field'
                label='Zahl'
                value={this.props.attributeValue.numberValue === null ? '' : this.props.attributeValue.numberValue}
                type='number'
                onChange={(e) => this.props.numberValueOnChangeHandler(e,this.props.index)}
            />
        }else if(this.props.attributeValue.attribute ? this.props.attributeValue.attribute.type === 2 : '') {
            valueInput = <TextField
                className='attribute-field'
                label='Text'
                value={this.props.attributeValue.stringValue === null ? '' : this.props.attributeValue.stringValue}
                onChange={(e) => this.props.stringValueOnChangeHandler(e,this.props.index)}
            />
        }else{

        }

        return (
            <Paper elevation={3} className='attribute-item'>
                <CloseIcon onClick={() => this.props.removeAttributeOnClickHandler(this.props.index)} className="close-icon"/>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.attributeValue.mainAttribute}
                            onChange={(e) => this.props.mainAttributeOnChangeHandler(e,this.props.index)}
                            name="main"
                            color="primary"
                        />
                    }
                    className='main-checkbox'
                    title='Das Hauptattribut erscheint auf der Rechnung'
                    label="Hauptattribut"
                />
                <TextField className='attribute-field' label='Attribut' value={this.props.attributeValue.attribute ? this.props.attributeValue.attribute.id : ''} select onChange={(e) => this.props.articleAttributeOnChangeHandler(e,this.props.index)} defaultValue={this.props.attributeValue.type}>
                    {this.props.attributes.map(attribute => (
                        <MenuItem key={attribute.id} value={attribute.id}>
                            {attribute.name}
                        </MenuItem>
                    ))}
                </TextField>
                {valueInput}
            </Paper>
        )
    }
}

export default AttributeItem;